import { environment } from "src/environments/environment";
import { CommonHeader } from "./common-header";
import { HttpClient } from "@angular/common/http";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { TblUserWiseTransaction } from "../models/userwisetransactions";

@Injectable({
    providedIn: "root",
})
export class CommonService {
    private serviceRoot = `${environment.serviceRoot}`;
    commonHeader: CommonHeader = new CommonHeader()
    private options = this.commonHeader.options;
    constructor(
        private http: HttpClient,
        private ehandler: ExceptionHandler,
        private localStorage:LocalStorageService
    ) { }

    getUserWiseTransactionByUserIDAndDate(id:number,fromDate:string,toDate:string): Observable<TblUserWiseTransaction[]> {
         this.localStorage.set("method","Read")
        this.localStorage.set("operation","User Wise Transaction")
        const endPoint = `${this.serviceRoot}/getUserWiseTransactionByUserIDAndDate/${id}/${fromDate}/${toDate}`;
        return this.http.get<TblUserWiseTransaction[]>(endPoint, this.options)
            .pipe(catchError(this.ehandler.errorHandler));
    }
}