import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLeadMedium } from "../models/leadMedium";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";

@Injectable({
  providedIn: "root",
})
export class LeadMediumService {
  deleteLeadMediumById(refNo: string): Observable<any> {
    this.localStorage.set("method","Delete")
    this.localStorage.set("operation","Delete All Lead Medium By Id")
    const endPoint = `${this.serviceRoot}/deleteLeadMediumById/${refNo}`;
    return this.http.get<any>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllLeadMedium(): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Mediums")
    const endPoint = `${this.serviceRoot}/getAllLeadMedium`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

   getLeadMediumById(id:number): Observable<TblLeadMedium> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Medium By Id")
    const endPoint = `${this.serviceRoot}/getAllLeadMediumById/${id}`;
    return this.http.get<TblLeadMedium>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateLeadMedium(data:TblLeadMedium):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Medium")
    const endPoint = `${this.serviceRoot}/saveLeadMedium`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

}
