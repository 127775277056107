
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';

import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { TblLeadChannel } from 'src/app/shared/models/lead-channel';
import { LeadChannelService } from 'src/app/shared/services/LeadChannelService';

@Component({
  selector: 'app-lead-channel',
  templateUrl: './lead-channel.component.html',
  styleUrls: ['./lead-channel.component.css']
})
export class LeadChannelComponent implements OnInit {

  sidePanelMode:boolean=false;
  isEditing:boolean;
  model: TblLeadChannel;
  members: Observable<TblLeadChannel[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate:any
  constructor(private _leadChannelService : LeadChannelService,
    private modalService: BsModalService,
    private spinnerService: AppComponent ,private notifyService : NotificationService) {
  }

  ngOnInit(): void {
    this.model=new TblLeadChannel();
    this.getAllData();
    this.startDate=new Date()
  }
  ngAfterViewInit() {
    //this.dt1.datetimeFormat = {"yyyy-mm-dd"};
  }
  getAllData(){
    //this.spinnerService.spinnerChannel(true);
    this.members= this._leadChannelService.getAllLeadChannel();
    //this.spinnerService.spinnerChannel(false);
  }
  sidePanel(id,isEditing){
    this.sidePanelMode=true
    this.edit=isEditing
    if(isEditing)
    {
      this.boxTitle="Edit Lead Channel";
      
       this._leadChannelService.getLeadChannelById(id)
      .subscribe(
        resp=>{
          this.model=resp;
        },
        (error: HttpErrorResponse) => {
          this.editDataAvbl=false;
        });
      }
    else{
      this.boxTitle="Add New Lead Channel";
      this.model=new TblLeadChannel();
    }
  }


  //Editing Data
  validateForm():boolean{
    if(!this.model.channelName){
      this.notifyService.showError("Email ID can not be empty!", "Error");
      return false;
    }
    else {
      return true;
    }
  }
  deleteClick(id:any)
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Delete',  module: 'Lead Channel', refNo:id};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
  }
  create_edit(){

    if(!this.validateForm()){
      return;
    }
    if(this.edit){
        this._leadChannelService.saveAndUpdateLeadChannel(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else{
      this._leadChannelService.saveAndUpdateLeadChannel(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      },(err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked()
  {
    this.sidePanelMode=false
    this.getAllData();
  }
}

