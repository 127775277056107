import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';

import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { DatePipe } from '@angular/common';
import { TblLeadStatus } from 'src/app/shared/models/leadstatus';
import { LeadStatusService } from 'src/app/shared/services/leadStatus.services';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-status',
  templateUrl: './lead-status.component.html',
  styleUrls: ['./lead-status.component.css']
})
export class LeadStatusComponent implements OnInit {
  sidePanelMode:boolean=false;
  isEditing:boolean;
  model: TblLeadStatus;
  members: Observable<TblLeadStatus[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate:any
  constructor(private _leadStatusService : LeadStatusService,
    private modalService: BsModalService,
    private spinnerService: AppComponent ,private notifyService : NotificationService) {
  }

  ngOnInit(): void {
    this.model=new TblLeadStatus();
    this.getAllData();
    this.startDate=new Date()
  }
  ngAfterViewInit() {
    //this.dt1.datetimeFormat = {"yyyy-mm-dd"};
  }
  getAllData(){
    //this.spinnerService.spinnerStatus(true);
    this.members= this._leadStatusService.getAllLeadStatus();
    //this.spinnerService.spinnerStatus(false);
  }
  sidePanel(id,isEditing){
    this.sidePanelMode=true
    this.edit=isEditing
    if(isEditing)
    {
      this.boxTitle="Edit Lead Status";
      
       this._leadStatusService.getLeadStatusById(id)
      .subscribe(
        resp=>{
          this.model=resp;
        },
        (error: HttpErrorResponse) => {
          this.editDataAvbl=false;
        });
      }
    else{
      this.boxTitle="Add New Lead Status";
      this.model=new TblLeadStatus();
    }
  }


  //Editing Data
  validateForm():boolean{
    if(!this.model.statusName){
      this.notifyService.showError("Email ID can not be empty!", "Error");
      return false;
    }
    else {
      return true;
    }
  }
  deleteClick(id:any)
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Delete',  module: 'Lead Status', refNo:id};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
  }
  create_edit(){

    if(!this.validateForm()){
      return;
    }
    if(this.edit){
        this._leadStatusService.saveAndUpdateLeadStatus(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else{
      this._leadStatusService.saveAndUpdateLeadStatus(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      },(err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked()
  {
    this.sidePanelMode=false
    this.getAllData();
  }
}
