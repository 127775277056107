import { Inject, Injectable, InjectionToken } from '@angular/core';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage
});
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(@Inject(BROWSER_STORAGE) public storage: Storage) {}

  public set(key: string, data: string): void {
    this.storage.setItem(key, data);
  }

  public get(key: string): string {
    return this.storage.getItem(key);
  }
  remove(remItem)
  {
    this.storage.removeItem(remItem);
    
    
  }
  removeAll()
  {
    this.storage.clear();
  }
}