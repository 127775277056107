import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/services/common.services';
import { LocalStorageService } from '../utility/localStorageService';
import { WebSocketService } from '../WebSocketService';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.css']
})
export class RestoreComponent implements OnInit {
  selectedFile: File | null = null;
  userType: string;
  response = ""
  restoreForm: FormGroup;

  constructor(private commonService: CommonService,
    private localStorage: LocalStorageService,
    private webSocketService: WebSocketService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private nofifyService:NotificationService
  ) { }

  ngOnInit(): void {
    this.restoreForm = this.fb.group({
      passWord: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.userType = this.localStorage.get("userType")
    this.loadNotifyService()
  }



  onFileSelected(event: Event): void {
    if (this.restoreForm.valid) {
      const input = event.target as HTMLInputElement;
      let pwd=this.restoreForm.get('passWord')?.value
      if (input.files && input.files.length > 0) {
        this.selectedFile = input.files[0];
        this.commonService.restoreFromBackup(this.selectedFile,pwd).subscribe(resp => {

        });
      }
    }
    else
    {
        this.nofifyService.showError("Please Enter Valid Password First","Invalid")
    }
  }
  loadNotifyService() {
    let stompClient = this.webSocketService.connect();
    let id = "r9876598765"
    stompClient.onConnect = (frame) => {
      console.log("Connected: " + frame);
      stompClient.subscribe(`/topic/${id}/notification`, (message) => {
        console.log(message.body)
        this.response = this.response + this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm:ss') + "  " + message.body + "\n";
        document.getElementById('resp').textContent = this.response;
      });
    };

    stompClient.onStompError = (frame) => {
      console.error("STOMP Error:", frame);
    };
    stompClient.activate();
  }

}
export class RestoreForm {
  password: string;
}