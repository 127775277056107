import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UseeActivityStatus } from '../shared/models/useractivitystatus';
import { UserActivityService } from '../shared/services/UserActivityService';
import { LocalStorageService } from '../utility/localStorageService';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ForgotPasswordDialogComponent } from '../shared/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { NotificationService } from '../notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserLogin } from '../shared/models/user-login';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userLogin:UserLogin;
  submitted: boolean = false
  loginForm!: FormGroup;
  showUserIdError: boolean = false;
  showPasswordError: boolean = false
  useractivitystatus: UseeActivityStatus;
  isRememberChecked: boolean = false

  modalRef: BsModalRef;


  constructor(private fb: FormBuilder, private router: Router,
    private userActivityService: UserActivityService,
    private localStorage: LocalStorageService,
    private modalService: BsModalService,
    private notifyService: NotificationService,
  ) { }

  ngOnInit(): void {

    this.userActivityService.getCountries()
    .subscribe(
      resp => {
        console.log(resp)
      });


    this.loginForm = this.fb.group({
      remember: [false, Validators.nullValidator],
      userName: ['', [Validators.required, Validators.email]],
      passWord: ['', [Validators.required, Validators.pattern(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$'
      )]]
    });
    if (this.localStorage.get("email") != "NA" && this.localStorage.get("password") != "NA") {
      this.loginForm.get('userName').setValue(this.localStorage.get("email"));
      this.loginForm.get('passWord').setValue(this.localStorage.get("password"));
      this.loginForm.get('remember').setValue(true)
      if (this.localStorage.get("logout") == "false") {
        this.localStorage.set("logout", "false")
        this.onSubmit();
      }

    }
    
  }

  get loginData() {
    return this.loginForm.controls;
  }
  onChange(event: Event): void {
    this.isRememberChecked = event.target['checked'];
  }
  onSubmit() {
 
    this.userLogin=new UserLogin()
    this.submitted = true;

    if (this.loginForm.valid) {

      this.userLogin.userName= this.loginForm.get('userName')?.value;
      this.userLogin.password = this.loginForm.get('passWord')?.value;

      this.userActivityService.getUserAllowedOrNotByEmailIdAndPassword(this.userLogin)
        .subscribe(
          resp => {
            if(resp==null)
            {
              this.notifyService.showError("Wrong User Name/Password","Error")
              return;
            }
            this.useractivitystatus = resp
           
            this.localStorage.set("userName", this.useractivitystatus.userName);
            this.localStorage.set("refNo", this.useractivitystatus.refNo);
            this.localStorage.set("userType", this.useractivitystatus.userType);
            this.localStorage.set("showMeUpcomingEventsOf", this.useractivitystatus.showMeUpcomingEventsOf.toString());
            this.localStorage.set("showMeLeadsFromDate", this.useractivitystatus.showMeLeadsFromDate);
            this.localStorage.set("sendWhatsAppFrom", this.useractivitystatus.sendWhatsAppFrom);
            this.localStorage.set("sendSMSFrom", this.useractivitystatus.sendSMSFrom);
            this.localStorage.set("sendEMailFrom", this.useractivitystatus.sendEMailFrom);
            this.localStorage.set("accessSource", this.useractivitystatus.accessSource);
            
            if (this.loginForm.get('remember').value) {
              this.localStorage.set("email", this.userLogin.userName);
              this.localStorage.set("password", this.userLogin.password);
            }
            else {
              this.localStorage.set("email", "NA");
              this.localStorage.set("password", "NA");
            }
            
            if (this.useractivitystatus.userType == "User")
              this.router.navigateByUrl("leads")
            else
              this.router.navigateByUrl("/dashboard")
            
            this.clear();

          },
          (error: HttpErrorResponse) => {
            
          });
    }
    else {
      this.notifyService.showError("Please Enter User Name and Valid Password", "Error")
    }
  }

  clear() {
    this.loginForm.patchValue({
      userName: '',
      passWord: ''
    });
  }
  openModal() {
    const initialState = {
      data: "Test Data"
    };
    this.modalRef = this.modalService.show(ForgotPasswordDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });
  }

}