import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLeadChannel } from "../models/lead-channel";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { Observable } from "rxjs/internal/Observable";
import { TblLeadDistribution } from "../models/lead-distribution";
@Injectable({
  providedIn: "root",
})
export class LeadDistributionService {
    
  
    private serviceRoot = `${environment.serviceRoot}`;
    commonHeader:CommonHeader=new CommonHeader() 
    private options=this.commonHeader.options;
    constructor(
      private http: HttpClient,
      private ehandler:ExceptionHandler,
      private localStorage:LocalStorageService
    ) { }

    getAllLeadDistribution(): Observable<TblLeadDistribution[]> {
      this.localStorage.set("method","Read")
      this.localStorage.set("operation","Get All Lead Distribution")
      const endPoint = `${this.serviceRoot}/getAllLeadDistribution`;
      return this.http.get<TblLeadDistribution[]>(endPoint, this.options)
      .pipe(catchError(this.ehandler.errorHandler));
    }
}  