import { Injectable } from "@angular/core";
import * as SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  private stompClient: Client;
  socketApi: string
  public connect(): Client {
    if (!this.stompClient) {
      if (window.location.href.includes("localhost"))
        this.socketApi = "http://localhost:8000/app/socket"
      else
        this.socketApi = "app/socket"

      let socket = new SockJS(this.socketApi);
      this.stompClient = new Client({

        webSocketFactory: () => socket,
        debug: (msg) => console.log(msg),
        reconnectDelay: 5000,
      });
    }
    return this.stompClient;
  }
}
