<div class="container-fluid" id="container-wrapper" style="background-color: white;">

    <table style="width: 100%;border: 0;right: 0px;border-bottom: 2px solid grey;">
        <tr>
            <td style="width: calc(100%-50px)">
                <h1 class="h3 mb-0 text-gray-800">Leads Upload</h1>
            </td>
            <td style="float: right;padding-top: 5px;">
                <button (click)="exportLeadFormat()" class="block">Export Lead Format</button>
            </td>
        </tr>
    </table>

    <div style="margin: auto;
width: 310px;
top: 140px;
border-radius: 5px;
border: 1px solid rgb(31, 32, 31);
padding: 20px;
text-align: center; margin-top: 10px;margin-bottom: 10px;">

        <div class="row">
            <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file" accept=".xlsx">
            <button type="button" (click)="Upload()">Upload</button>
        </div>

        <hr>
        <div class="row">
            <div class="col">
                <button type="button" class="btn btn-danger btn-block" (click)="AssignAssociate()">Assign Associate</button>
            </div>
            <div class="col">
                <button type="button" class="btn btn-danger btn-block" (click)="Update()">Update</button>
            </div>
        </div>
    </div>


    <table class="table table-bordered ">
        <thead style="background-color: rgb(10, 10, 80);color: rgb(243, 245, 247);font-size: smaller;">
            <tr>
                <th style="width: 35px;">SN</th>
                <th style="width: 80px;">Date</th>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Alternate No</th>
                <th>E-Mail</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Channel Name</th>
                <th>Source</th>
                <th>Campaign Name</th>
                <th>Medium</th>
                <th>Assigned To</th>
            </tr>
        </thead>
        <tbody style="max-height: 300px!important;overflow-y: scroll;overflow-x: hidden;">
            <tr *ngFor="let item of tblLeads;let counter=index;">
                <td style="width: 35px;">{{counter+1}}</td>
                <td style="width: 80px;">{{item.leadDate}}</td>
                <td>{{item.studentName}}</td>
                <td>{{item.mobileNo}}</td>
                <td>{{item.alternateNo}}</td>
                <td>{{item.emailId}}</td>
                <td>{{item.address}}</td>
                <td>{{item.cityName}}</td>
                <td>{{item.stateName}}</td>
                <td>{{item.channelName}}</td>
                <td>{{item.source}}</td>
                <td>{{item.leadCampaignName}}</td>
                <td>{{item.leadMedium}}</td>
                <td>{{item.assignToCounsellor}}</td>
            </tr>
        </tbody>
    </table>
</div>