import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeadSourcesComponent } from './masters/lead-sources/lead-sources.component';
import { TeamsIndexComponent } from './masters/teams-index/teams-index.component';
import { UsersComponent } from './masters/users/users.component';
import { LeadStatusComponent } from './masters/lead-status/lead-status.component';
import { LeadSubStatusComponent } from './masters/lead-sub-status/lead-sub-status.component';
import { LeadMediumComponent } from './masters/lead-medium/lead-medium.component';
import { CommunicationSetupComponent } from './masters/communication-setup/communication-setup.component';
import { LeadImportComponent } from './masters/lead-import/lead-import.component';
import { CoursesComponent } from './masters/courses/courses.component';
import { LeadCampaignComponent } from './masters/lead-campign/lead-campaign.component';
import { LeadsComponent } from './leads/leads.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TestComponent } from './masters/test/test.component';
import { LeadUploadComponent } from './masters/lead-upload/lead-upload.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UseractivityComponent } from './useractivity/useractivity.component';
import { RawListComponent } from './raw-list/raw-list.component';
import { WhatsappTemplateComponent } from './communication-templates/whatsapp-template/whatsapp-template.component';
import { SmsTemplateComponent } from './communication-templates/sms-template/sms-template.component';
import { EmailTemplateComponent } from './communication-templates/email-template/email-template.component';
import { LeadDistributionComponent } from './masters/lead-distribution/lead-distribution.component';
import { LeadChannelComponent } from './masters/lead-channel/lead-channel.component';
import { SourceWiseLeadStatusComponent } from './source-wise-lead-status/source-wise-lead-status.component';
import { LeadConditionsComponent } from './lead-conditions/lead-conditions.component';
import { MyUploadsComponent } from './my-uploads/my-uploads.component';
import { UpcomingFollowUpsComponent } from './upcoming-follow-ups/upcoming-follow-ups.component';
import { FailedFollowUpsComponent } from './failed-follow-ups/failed-follow-ups.component';
import { UserWiseActivityComponent } from './reports/user-wise-activity/user-wise-activity.component';
import { CounsellorLeadStatusComponent } from './reports/counsellor-lead-status/counsellor-lead-status.component';
import { StatusWiseAverageTimeComponent } from './reports/status-wise-average-time/status-wise-average-time.component';
import { RestoreComponent } from './restore/restore.component';
import { BackupComponent } from './backup/backup.component';
import { BulkReassignLeadsComponent } from './bulk-reassign-leads/bulk-reassign-leads.component';

const routes: Routes = [
  { path: 'lead-sub-status', component: LeadSubStatusComponent },

  { path: 'lead-channel', component: LeadChannelComponent },
  { path: 'lead-status', component: LeadStatusComponent },
  { path: 'lead-source', component: LeadSourcesComponent },
  { path: 'users', component: UsersComponent },
  { path: 'lead-conditions', component: LeadConditionsComponent },

  { path: 'lead-campign', component: LeadCampaignComponent },
  { path: 'lead-medium', component: LeadMediumComponent },
  { path: 'communication-setup', component: CommunicationSetupComponent },
  { path: 'lead-distribution', component: LeadDistributionComponent },
  { path: 'email-template', component: EmailTemplateComponent },
  { path: 'whatsapp-template', component: WhatsappTemplateComponent },
  { path: 'sms-template', component: SmsTemplateComponent },
  { path: 'courses', component: CoursesComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'leads', component: LeadsComponent },
  { path: 'upcoming-follow-ups', component: UpcomingFollowUpsComponent },
  { path: 'raw-list', component: RawListComponent },
  { path: 'failed-follow-ups', component: FailedFollowUpsComponent },
  { path: 'bulk-reassign-leads', component: BulkReassignLeadsComponent },

  { path: 'lead-upload', component: LeadUploadComponent },
  { path: 'user-dashboard', component: UserDashboardComponent },
  { path: 'user-activity', component: UseractivityComponent },

  { path: 'my-uploads', component: MyUploadsComponent },
  { path: 'source-wise-lead-status', component: SourceWiseLeadStatusComponent },

  { path: 'status-wise-average-time', component: StatusWiseAverageTimeComponent },
  { path: 'counsellor-lead-status', component: CounsellorLeadStatusComponent },
  { path: 'user-wise-activity', component: UserWiseActivityComponent },

  { path: 'backup', component: BackupComponent },
  { path: 'restore', component: RestoreComponent },

  { path: '', redirectTo: 'login', pathMatch: 'full' }
  ,
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
