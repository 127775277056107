<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Send EMail</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-sm-6">
                <mat-form-field>
                    <input matInput type="text" readonly class="field-full-width" name="toName" [(ngModel)]="sendEMail.toName" placeholder="Name">
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <input matInput type="text" readonly class="field-full-width" name="eMail" [(ngModel)]="sendEMail.eMail" placeholder="Number">
                </mat-form-field>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-select class="field-full-width" placeholder="Template" [(ngModel)]="sendEMail.templateId" (selectionChange)="onTemplateSelect($event.value)">
                        <mat-option *ngFor="let stat of tblEMailTemplate" [value]="stat.templateId">{{stat.templateName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <input matInput type="text" class="field-full-width" name="subject" [(ngModel)]="sendEMail.subject" placeholder="Subject">
                </mat-form-field>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <textarea matInput type="text" class="field-full-width" name="message" [(ngModel)]="sendEMail.message" placeholder="Message" Rows="3">OK</textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="form-group row" *ngIf="sendEMail.fileAllowed=='Yes'">
            <div class="col-sm-12">
                <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
                <input hidden (change)="onFileSelected($event)" #fileInput type="file">
                <span class="file-name">{{selectedFile?.name}}</span>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="sendEMailMessage()">Send EMail</button>
    </div>
</div>