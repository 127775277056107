<div class="card mb-4">
  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">Add Notes</h6>
  </div>
  <div class="card-body">
    <form>
      <div class="form-group row">
        <div class="col-sm-12">
          <input type="text" class="form-control" id="inputName">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <button type="button" class="btn btn-primary">Save</button> &nbsp;&nbsp;
          <button type="button" class="btn btn-danger">Close</button>
        </div>
      </div>
    </form>
  </div>
</div>

