import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblCommunicationSetup } from "../models/communicationsetup";
import { ApiResponse } from "../models/api-response";
import { FCMCommunication } from "../models/fcm-communication";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { SSEActionCall } from "../models/sse-action";
import { TblSMSTemplate } from "../models/sms-template";
import { TblWhatsAppTemplate } from "../models/whatsapp-template";
import { TblEMailTemplate } from "../models/EMail-template";
@Injectable({
  providedIn: "root",
})
export class TemplateService {
  
  
  
  
 
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  

  getSMSTemplate() : Observable<TblSMSTemplate[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get SMS Template")
    const endPoint = `${this.serviceRoot}/getSMSTemplate`;
    return this.http.get<TblSMSTemplate[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getWhatsAppTemplate(): Observable<TblWhatsAppTemplate[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get WhatsApp Template")
    const endPoint = `${this.serviceRoot}/getWhatsAppTemplate`;
    return this.http.get<TblWhatsAppTemplate[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getEMailTemplate() : Observable<TblEMailTemplate[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get EMail Template")
    const endPoint = `${this.serviceRoot}/getEMailTemplate`;
    return this.http.get<TblEMailTemplate[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  saveAndUpdateEMailTemplate(data: TblEMailTemplate) {
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save EMail Template")
    const endPoint = `${this.serviceRoot}/saveAndUpdateEMailTemplate`;
    return this.http.post<any>(endPoint, data,this.options);
  }

  saveAndUpdateSMSTemplate(data: TblSMSTemplate) {
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save SMS Template")
    const endPoint = `${this.serviceRoot}/saveAndUpdateSMSTemplate`;
    return this.http.post<any>(endPoint, data,this.options);
  }
  getEMailTemplateById(id: any) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get EMail Template")
    const endPoint = `${this.serviceRoot}/getEMailTemplateById/${id}`;
    return this.http.get<TblEMailTemplate>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getSMSTemplateById(id: any) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get SMS Template")
    const endPoint = `${this.serviceRoot}/getSMSTemplateById/${id}`;
    return this.http.get<TblSMSTemplate>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }


  saveAndUpdateWhatsAppTemplate(data: TblWhatsAppTemplate) {
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save WhatsApp Template")
    const endPoint = `${this.serviceRoot}/saveAndUpdateWhatsAppTemplate`;
    return this.http.post<any>(endPoint, data,this.options);
  }
  getWhatsAppTemplateById(id: any) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get WhatsApp Template")
    const endPoint = `${this.serviceRoot}/getWhatsAppTemplateById/${id}`;
    return this.http.get<TblWhatsAppTemplate>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

}
