import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../utility/localStorageService';
import * as moment from 'moment';
import { LeadFilter } from '../shared/models/lead-filter';
import { LeadService } from '../shared/services/lead.services';
import { TblLead } from '../shared/models/leads';
import { LeadStatusService } from '../shared/services/leadStatus.services';
import { TblLeadStatus } from '../shared/models/leadstatus';

@Component({
  selector: 'app-bulk-reassign-leads',
  templateUrl: './bulk-reassign-leads.component.html',
  styleUrls: ['./bulk-reassign-leads.component.css']
})
export class BulkReassignLeadsComponent implements OnInit {
  userType: string;
  leadFilter: LeadFilter
  fromDate: string
  leads: TblLead[];
  leadsFullData: TblLead[];
  statusWiseLeads: { name: string; value: number; }[];
  listStatus: string[]
  listSubStatus: string[]
  listState: string[]
  listCity: string[]
  listCounsellor: string[]
  listSource: string[];
  androidOs: boolean = false
  filterClicked: boolean = false
  leadStatus: TblLeadStatus[];
  isRememberChecked: any;
  constructor(
    private localStorage: LocalStorageService,
    private _leadservices: LeadService,
    private leadStatusService: LeadStatusService,
  ) { }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    if (this.localStorage.get("osType") == "Android") {
      this.androidOs = true
    }
    else {
      this.androidOs = false
    }
    this.leadStatusService.getAllLeadStatus().subscribe(resp => {
      this.leadStatus = resp;
      //this.counsellors = this.counsellors.filter((fl) => fl.userType==="Counsellor");
    });
    this.fromDate = this.localStorage.get("showMeLeadsFromDate")

    this.leadFilter = new LeadFilter()
    this.leadFilter.fromDate = (moment(new Date(this.fromDate))).format('YYYY-MM-DD')
    this.leadFilter.toDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadFilter.state = "All"
    this.leadFilter.city = "All"
    this.leadFilter.status = "All"
    this.leadFilter.subStatus = "All"
    this.leadFilter.assignedTo = "All"
    this.leadFilter.leadSource = "All"

    this.leadFilter.userRefNo = this.localStorage.get("refNo")
    this.getAllData();
  }
  getAllData() {
    let refNo: number = 0;
    if (this.localStorage.get("userType") != "Admin")
      refNo = Number(this.localStorage.get("refNo"));
    else
      refNo = 0
    this._leadservices.getAllLeadsByuserId(refNo, this.fromDate).subscribe(resp => {
      if (resp.length > 0) {
        this.leads = resp
        let cnt = 0;
        console.log(this.leads)

        this.leadsFullData = resp
        this.listStatus = []
        this.listSubStatus = []
        this.listCity = []
        this.listState = []
        this.listSource = []
        this.listCounsellor = []

        this.leads.forEach(e => {
          let found = false
          for (let i = 0; i < this.listStatus.length; i++) {
            if (e.status == this.listStatus[i]) {
              found = true
            }

          }
          if (found == false) {
            this.listStatus.push(e.status)
          }
          found = false
          for (let i = 0; i < this.listSubStatus.length; i++) {
            if (e.subStatus == this.listSubStatus[i])
              found = true
          }

          if (found == false) {
            this.listSubStatus.push(e.subStatus)
          }
          found = false
          for (let i = 0; i < this.listState.length; i++) {
            if (e.stateName == this.listState[i])
              found = true
          }
          if (found == false) {
            this.listState.push(e.stateName)
          }
          found = false
          for (let i = 0; i < this.listCity.length; i++) {
            if (e.cityName == this.listCity[i])
              found = true
          }
          if (found == false) {
            this.listCity.push(e.cityName)
          }
          found = false
          for (let i = 0; i < this.listSource.length; i++) {
            if (e.source == this.listSource[i])
              found = true
          }
          if (found == false) {
            this.listSource.push(e.source)
          }
          found == false
          for (let i = 0; i < this.listCounsellor.length; i++) {
            if (e.assignToCounsellor == this.listCounsellor[i])
              found = true
          }
          if (found == false) {
            this.listCounsellor.push(e.assignToCounsellor)
          }
        })
      }

    });




  }

  checkBoxClicked() {
    var inputElems = document.getElementsByTagName("input"),
      count = 0;
    for (var i = 0; i < inputElems.length; i++) {
      if (inputElems[i].type === "checkbox" && inputElems[i].checked === true) {
        count++;
      }
    }
    alert(count);
  }
  filterLeads() {
    this.leads = this.leadsFullData;
    this.filterClicked = true
    console.log(this.leads)
    if (this.leadFilter.state != 'All') {
      this.leads = this.leads.filter((ld) => ld.stateName.includes(this.leadFilter.state));
    }
    if (this.leadFilter.city != 'All') {
      this.leads = this.leads.filter((ld) => ld.cityName.includes(this.leadFilter.city));
    }
    if (this.leadFilter.status != 'All') {
      this.leads = this.leads.filter((ld) => ld.status.includes(this.leadFilter.status));
    }
    if (this.leadFilter.subStatus != 'All') {
      this.leads = this.leads.filter((ld) => ld.subStatus.includes(this.leadFilter.subStatus));
    }
    if (this.leadFilter.assignedTo != 'All') {
      this.leads = this.leads.filter((ld: TblLead) => ld.assignToCounsellor === this.leadFilter.assignedTo);
    }
    if (this.leadFilter.leadSource != 'All') {
      this.leads = this.leads.filter(ld => ld.source.includes(this.leadFilter.leadSource));
    }
    let dataFilt: TblLead[] = []
    this.leads.forEach(e => {
      if (e.leadDate.split("-")[2] + e.leadDate.split("-")[1] + e.leadDate.split("-")[0] >= this.leadFilter.fromDate.split("-")[0] + this.leadFilter.fromDate.split("-")[1] + this.leadFilter.fromDate.split("-")[2] &&
        e.leadDate.split("-")[2] + e.leadDate.split("-")[1] + e.leadDate.split("-")[0] <= this.leadFilter.toDate.split("-")[0] + this.leadFilter.toDate.split("-")[1] + this.leadFilter.toDate.split("-")[2]) {
        dataFilt.push(e)
      }
    });
    this.leads = dataFilt

    this.statusWiseLeads = [{ name: 'All', value: 0 }]
    let cnt = 0;
    this.leads.forEach(e => {
      let notFound: Boolean = true
      cnt++;
      this.statusWiseLeads.forEach(f => {
        if (e.status == f.name) {
          f.value++;
          notFound = false
        }
      })
      if (notFound) {
        this.statusWiseLeads.push({ name: e.status, value: 1 })
      }
    })
    this.statusWiseLeads.forEach(f => {
      if (f.name == "All")
        f.value = cnt;
    });

    alert(this.leads.length)

  }
}
