import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from 'src/app/notification.service';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { TblLead } from '../../models/leads';
import { TblCourses } from '../../models/courses';
import { Observable } from 'rxjs';
import { TblLeadStatus } from '../../models/leadstatus';
import { TblLeadSubStatus } from '../../models/leadsubstatus';
import { TblLeadSources } from '../../models/leadsources';
import { TblLeadMedium } from '../../models/leadMedium';
import { TblLeadCampaign } from '../../models/leadcampaign';
import { TblUser } from '../../models/user';
import { CoursesService } from '../../services/courses.service';
import { UserServices } from '../../services/userservices';
import { LeadStatusService } from '../../services/leadStatus.services';
import { LeadSubStatusService } from '../../services/leadSubStatusService';
import { LeadSourceService } from '../../services/leadsource.services';
import { LeadMediumService } from '../../services/leadMedium.service';
import { LeadCampaignService } from '../../services/leadcampaign.services';
import { AppComponent } from 'src/app/app.component';
import { LeadService } from '../../services/lead.services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-leads-dialog',
  templateUrl: './leads-dialog.component.html',
  styleUrls: ['./leads-dialog.component.css']
})
export class LeadsDialogComponent implements OnInit {
  model: TblLead;
  refNo:any
  leadStatus:Observable<TblLeadStatus[]>;
  leadSubStatus:Observable<TblLeadSubStatus[]>;
  leadSourcs:Observable<TblLeadSources[]>;
  leadMedium:Observable<TblLeadMedium[]>;
  counsellors:Observable<TblUser[]>;
  leadCampaign:Observable<TblLeadCampaign[]>;
  courses:Observable<TblCourses[]>;
  constructor(
    private localStorage:LocalStorageService,
    private notifyService : NotificationService,
    public modalRef: BsModalRef,
    private coursesService:CoursesService,
    private counsellorService:UserServices,
    private leadStatusService:LeadStatusService,
    private leadSubStatusService:LeadSubStatusService,
    private leadSourceService:LeadSourceService,
    private leadMediumService:LeadMediumService,
    private leadCampaignService:LeadCampaignService,
    private _leadservices : LeadService,
  ) { }
 
  ngOnInit(): void {
    this.model=new TblLead();
    this.model.assignToCounsellor=this.localStorage.get("userName")
    this.model.courseName="PGDM"
    
    this.courses=this.coursesService.getAllCourses();
   this.counsellors=this.counsellorService.getAllUsers();
   this.leadStatus=this.leadStatusService.getAllLeadStatus()
   this.leadSubStatus=this.leadSubStatusService.getAllLeadSubStatus()
   this.leadSourcs=this.leadSourceService.getAllLeadSource();
   this.leadMedium=this.leadMediumService.getAllLeadMedium();
   this.leadCampaign=this.leadCampaignService.getAllLeadCampaign()
   setTimeout(() => {
    this._leadservices.getLeadsById(this.refNo)
    .subscribe(
      resp=>{
        this.model=resp;
      },
      (error: HttpErrorResponse) => {
       
      });
  }, 500);
  }
  saveAndUpdateLead()
  {
    this._leadservices.saveAndUpdateLeads(this.model).subscribe(resp=>{
      if(resp.refNo>0){
        this.notifyService.showSuccess("Saved Successfully!", "Success");
      }
      else{
        this.notifyService.showError(resp.message, "Error")
      }
    });
  }
}
