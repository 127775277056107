import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLead } from "../models/leads";
import { LeadFilter } from "../models/lead-filter";
import { LeadNotes } from "../models/notes";
import { TblAssignLeadTo } from "../models/assignleadto";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { RawLeadsFromThirdParty } from "./raw-data-from-third-party";

@Injectable({
  providedIn: "root",
})
export class LeadService {
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { 

    
  }

  getAllLeadsByuserId(userId:number,fromDate:string): Observable<TblLead[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By User Id")
    const endPoint = `${this.serviceRoot}/getAllLeadsByuserId/${userId}/${fromDate}`;
    return this.http.get<TblLead[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

  getLeadsById(id:number): Observable<TblLead> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By Id")
    const endPoint = `${this.serviceRoot}/getLeadsById/${id}`;
    return this.http.get<TblLead>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateLeads(data:TblLead):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Leads")
    const endPoint = `${this.serviceRoot}/saveAndUpdateLeads`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  saveAllLeads(data:TblLead[]):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update All Leads")
    const endPoint = `${this.serviceRoot}/saveAllLeads`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getLeadsByStatus(id:string,status:string,fromDate:string,toDate:string,noOfRecPerPage:number): Observable<TblLead[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By Status")
    const endPoint = `${this.serviceRoot}/getLeadsByStatus/${id}/${status}/${fromDate}/${toDate}/${noOfRecPerPage}`;
    return this.http.get<TblLead[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getAllFilteredLeads(fromDate:string,toDate:string,status:string[],subStatus:string[],state:string[],city:string[],refNo:number): Observable<TblLead[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By Filter Applied")
    const endPoint = `${this.serviceRoot}/getAllFilteredLeads/${fromDate}/${toDate}/${status}/${subStatus}/${state}/${city}/${refNo}`;
    return this.http.get<TblLead[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getPhoneNoExistOrNot(phoneNo: string) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Phone No Exists Or Not")
    const endPoint = `${this.serviceRoot}/getPhoneNoExistOrNot/${phoneNo}`;
    return this.http.get<any>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  saveLeadNotes(leadNotes: LeadNotes) :Observable<LeadNotes>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Notes")
    const endPoint = `${this.serviceRoot}/saveLeadNotes`;
    console.log("Data="+JSON.stringify(leadNotes))
    return this.http.post<LeadNotes>(endPoint, leadNotes,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  assignLeadToOtherAndUpdate(assignLeadTo: TblAssignLeadTo):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Assign Lead To Other")
    const endPoint = `${this.serviceRoot}/assignLeadToOtherAndUpdate`;
    console.log("Data="+JSON.stringify(assignLeadTo))
    return this.http.post<any>(endPoint, assignLeadTo,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getRawLeadsByBatch(accessSource:string) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Raw Leads")
    const endPoint = `${this.serviceRoot}/getRawLeadsByBatch/${accessSource}`;
    return this.http.get<RawLeadsFromThirdParty[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
}
