import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-counsellor-lead-status',
  templateUrl: './counsellor-lead-status.component.html',
  styleUrls: ['./counsellor-lead-status.component.css']
})
export class CounsellorLeadStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
