<div class="container-fluid" id="container-wrapper" style="background-color: white;padding: 5px;" *ngIf="userType=='Admin' || userType=='Counsellor'">

    <table style="width: 100%;border-bottom: 2px solid grey;">
        <tr>
            <td style="font-size: 20px;">
                All Leads
            </td>
            <td style="width: 100px;">
                <li class="nav-item dropdown no-arrow mx-1">
                    <a (click)="openFilterForm()" style="cursor: pointer;top: 10px;">
                        <i class="fa fa-filter fa-lg text-danger pull-right" aria-hidden="true" style="margin-top: 5px;"></i>
                    </a>
                    <a (click)="newLead(0,false)" style="cursor: pointer;padding-right: 10px;background-color: rgb(230, 239, 247);font-size: 18px;">
                        <i class="fa fa-plus fa-sm" style="color: green;"></i>&nbsp;Add
                    </a>
                </li>
            </td>
            <td style="width: 30px;">
                <button mat-icon-button matTooltip="Search" [matMenuTriggerFor]="search">
                    <mat-icon [ngStyle]="{'color':'#27682'}">search</mat-icon>
                </button>
                <mat-menu #search [overlapTrigger]="false" yPosition="below" style=" padding-top: 0px !important; padding-bottom: 0px !important; line-height:35px; height:35px;">

                    <form (click)="stopPropagation($event)" style="min-height: 40px;">
                        <input id='myInput' (keyup)='searchTable()' type='text' style="width:200px;height: 20px;">
                    </form>
                    <p style="padding-left: 10px;">Enter Any Text To Serach</p>
                </mat-menu>
            </td>

            <td style="width: 30px;" *ngIf="userType=='Admin'">
                <mat-icon (click)="downloadData()">cloud_download</mat-icon>
            </td>

        </tr>
    </table>






    <div class="row" style="padding-top: 0px;">
        <div style="display: inline-flex;overflow-y: hidden;overflow-x: scroll;white-space: nowrap;width: 100%;float: inline-end;margin-top: 5px;position: absolute;">
            <a *ngFor="let mnu of statusWiseLeads" class="btn btn-info text-white" style="margin-right: 3px!important;" (click)="getLeadsByStatus(mnu.name)">
            {{mnu.name}}( {{mnu.value}} )
            </a>
        </div>
    </div>

    <table class="table table-sm" style="padding-right: 20px!important;margin-top: 40px;font-size: small;padding: 0;">
        <tr class="thead-dark">
            <th>#</th>
            <th>Student Details</th>
            <th *ngIf="!androidOs">Address</th>
            <th>Lead Status</th>
            <th *ngIf="!androidOs">Other Details</th>
            <th style="max-width: 35px;">Action</th>
        </tr>
        <tbody id="myTable">
            <tr *ngFor="let item of pagedItems;let counter=index;">
                <td>{{counter+1}}</td>
                <td><b>Name : </b>{{item.studentName}}<br><b>Mobile : </b>{{item.mobileNo}}

                    <a (click)="phoneClick(item.refNo,item.studentName,item.mobileNo,item.status)"><img src="../../assets/img/phone-calls.png" style="width: 20px;height: 20px;"></a>
                    <!-- <a (click)="whatsappClick(item.refNo,item.studentName,item.mobileNo,item.status)"><img src="../../assets/img/whatsapp.png" style="width: 20px;height: 20px;"></a> -->

                    <br><b>EMail : </b>{{item.emailId}}</td>
                <td *ngIf="!androidOs"><b>Address : </b>{{item.address}}<br><b>City : </b>{{item.cityName}}<br><b>State : </b>{{item.stateName}}</td>
                <td><b>Assigned To :</b> {{item.assignToCounsellor}}<br><b>Status : </b>{{item.status}}</td>
                <td *ngIf="!androidOs"><b>On Date : </b>{{item.leadDate}}<br><b>Source : </b>{{item.source}}<br><b>Program : </b> {{item.courseName}}</td>
                <th style="max-width: 20px!important;">
                    <li class="nav-item dropdown no-arrow mx-1" style="cursor: pointer;">
                        <a class="nav-link " id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                            <a class="dropdown-item d-flex align-items-center" (click)="editLeadsClicked(item.refNo,item.userRefNo,true)">
                                <div class="mr-3">
                                    <div class="icon-circle">
                                        <img src="./../../../assets/img/edit.png" style="width: 30px;height:25px;padding-right: 10px;">
                                    </div>
                                </div>
                                <div>
                                    Edit
                                </div>
                            </a>
                            <!-- <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(item.refNo,true)">
                            <div class="mr-3">
                                <div class="icon-circle">
                                    <img src="./../../../assets/img/chatting.png" style="width: 30px;height:25px;padding-right: 10px;">
                                </div>
                            </div>
                            <div>
                                Start Chat
                            </div>
                        </a> -->
                            <a class="dropdown-item d-flex align-items-center" (click)="openNotesForm(item.refNo,item.studentName,item.status)">
                                <div class="mr-3">
                                    <div class="icon-circle">
                                        <img src="./../../../assets/img/project.png" style="width: 30px;height:25px;padding-right: 10px;">
                                    </div>
                                </div>
                                <div>
                                    Follow-Up Notes
                                </div>
                            </a>
                            <a class="dropdown-item d-flex align-items-center" (click)="assignToOtherCounsellor(item.refNo,item.userRefNo,item.studentName)">
                                <div class="mr-3">
                                    <div class="icon-circle">
                                        <img src="./../../../assets/img/assign.png" style="width: 30px;height:25px;padding-right: 10px;">

                                    </div>
                                </div>
                                <div>
                                    Assign to Other
                                </div>
                            </a>
                            <a class="dropdown-item d-flex align-items-center" (click)="viewLeadWiseActivityLog(item.refNo,item.studentName)">
                                <div class="mr-3">
                                    <div class="icon-circle">
                                        <img src="./../../../assets/img/followup.png" style="width: 30px;height:25px;padding-right: 10px;">
                                    </div>
                                </div>
                                <div>
                                    View Follow-Ups
                                </div>
                            </a>
                        </div>
                    </li>
                </th>
            </tr>
            <!-- <tr>
            <th colspan="4" *ngIf="(leads | async)?.length==0">No data found !</th>
        </tr> -->
        </tbody>
    </table>
    <div style="position: fixed;bottom: 30px;right: 20px;">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
            <li [ngClass]="{disabled:pager.currentPage === 1}">
                <a (click)="setPage(1)">First
                    <!-- <mat-icon [inline]="true">fast_rewind</mat-icon> -->
                </a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === 1}">
                <a (click)="setPage(pager.currentPage - 1)">
                    Prev
                    <!-- <mat-icon [inline]="true">skip_previous</mat-icon> -->
                </a>
            </li>
            <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a (click)="setPage(page)">{{page}}</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a (click)="setPage(pager.currentPage + 1)">
                    <!-- <mat-icon [inline]="true">skip_next</mat-icon> -->
                    Next
                </a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a (click)="setPage(pager.totalPages)">
                    <!-- <mat-icon [inline]="true">fast_forward</mat-icon> -->
                    Last
                </a>
            </li>
        </ul>
    </div>

</div>

<!-- FILTER FORM-->
<div id="myFilterForm" class="form-popup-popup" style="z-index: 999;">
    <div class="card-header d-flex flex-row align-items-center justify-content-between" style="background-color: rgb(1, 9, 15);">
        <h6 class="font-weight-bold " style="color: white;">Lead Filter</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeFilterForm()">X</button>
    </div>
    <div class="card-body" style="height: 250px!important;overflow-y: scroll;background-color: rgb(160, 170, 170);">

        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <input matInput type="date" name="fromDate" [(ngModel)]="leadFilter.fromDate" placeholder="From Date" [min]="fromDate">
            </mat-form-field>
            <mat-form-field class="col-sm-6">
                <input matInput type="date" name="toDate" [(ngModel)]="leadFilter.toDate" placeholder="To Date" [min]="fromDate">
            </mat-form-field>
        </div>
        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="Lead Status" [(ngModel)]="leadFilter.status">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listStatus" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-sm-6">

                <mat-select class="field-full-width" placeholder="Lead Sub-Status" [(ngModel)]="leadFilter.subStatus">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listSubStatus" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="State" [(ngModel)]="leadFilter.state">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listState" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="City" [(ngModel)]="leadFilter.city">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listCity" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="Counsellor" [(ngModel)]="leadFilter.assignedTo">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listCounsellor" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="Source" [(ngModel)]="leadFilter.leadSource">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listSource" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div class="card-footer" style="background-color: rgb(1, 9, 15);">
        <button type="button" class="btn btn-primary block" (click)="filterLeads();closeFilterForm();">Apply</button>
    </div>
</div>





<!-- NOTES FORM-->

<div id="myNotesForm" class="form-popup-popup" style="z-index: 999;">
    <div class="card-header d-flex flex-row align-items-center justify-content-between" style="background-color: rgb(1, 9, 15);">
        <h6 class="font-weight-bold " style="color: white;">Follow-Up Note</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeNotesForm()">X</button>
    </div>
    <div class="card-body" style="height: 390px!important;overflow-y: scroll;background-color: rgb(160, 170, 170);">


        <div class="form-group row" style="display: none;">
            <label class="col-sm-12" for="inputEmail3">Ref No</label>
            <div class="col-sm-12">
                <input type="text" class="form-control" name="studentId" [(ngModel)]="leadNotes.studentId">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-12" for="name">Candidate Name</label>
            <div class="col-sm-12" style="padding-bottom: -10px;">
                <input type="text" class="form-control" name="name" [(ngModel)]="leadNotes.name" readonly>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-6" for="inputEmail3">On Date</label>
            <label class="col-sm-6" for="inputEmail3">Next Follow-Up Date</label>

        </div>
        <div class="form-group row">

            <div class="col-sm-6">
                <input type="date" class="form-control" name="onDate" [(ngModel)]="leadNotes.onDate" readonly>
            </div>
            <div class="col-sm-6">
                <input type="date" class="form-control" name="nextActionDate" [(ngModel)]="leadNotes.nextActionDate">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-6" for="inputEmail3">Lead Activity</label>
            <label class="col-sm-6" for="inputEmail3">Current Status</label>

        </div>
        <div class="form-group row">
            <div class="col-sm-6">
                <select name="action" class="form-control" [(ngModel)]="leadNotes.action">
                    <option value="Call" selected>Call</option>
                    <option value="SMS">SMS</option>
                    <option value="WhatsApp">WhatsApp</option>
                    <option value="E-Mail">E-Mail</option>
                </select>
            </div>
            <div class="col-sm-6">
                <select name="status" class="form-control" [(ngModel)]="leadNotes.status">
                    <option value="0" selected disabled>Select Current Status</option>
                    <option *ngFor="let item of leadStatus" value="{{item.statusName}}">{{item.statusName}}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-12" for="inputEmail3">Details</label>
            <div class="col-sm-12">
                <textarea rows="3" class="form-control" name="note" [(ngModel)]="leadNotes.note">
                    </textarea>
            </div>
        </div>

    </div>

    <div class="card-footer" style="scroll;background-color:rgb(1, 9, 15);">
        <button type="button" class="btn btn-primary block" (click)="saveNotes();closeNotesForm();">Save</button>
    </div>
</div>