
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { LeadSourceService } from 'src/app/shared/services/leadsource.services';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogData } from 'src/app/shared/models/dialogData';
import { TblLeadConditions } from '../shared/models/LeadConditions';
import { LeadConditionsService } from '../shared/services/leadconditionservice';
import { UserServices } from '../shared/services/userservices';
import { TblUser } from '../shared/models/user';
import { TblSource } from '../shared/models/source';
import { TblLeadSources } from '../shared/models/leadsources';

@Component({
  selector: 'app-lead-conditions',
  templateUrl: './lead-conditions.component.html',
  styleUrls: ['./lead-conditions.component.css']
})
export class LeadConditionsComponent implements OnInit {
  sidePanelMode:boolean=false;
  isEditing:boolean;
  model: TblLeadConditions;
  dialogData: DialogData;
  members: Observable<TblLeadConditions[]>;
  users:Observable<TblUser[]>;
  sources:Observable<TblLeadSources[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate:any
  
  constructor(private leadConditionsService : LeadConditionsService,
    private modalService: BsModalService,
    private spinnerService: AppComponent ,
    private leadService:LeadSourceService,
    private userService:UserServices,
    private notifyService : NotificationService) {
  }

  ngOnInit(): void {
    this.model=new TblLeadConditions();
    this.getAllData();
    this.getAllSOurces()
    this.getAllUsers()
    this.startDate=new Date()
  }
  getAllUsers()
  {
    this.users= this.userService.getAllUsers()
  }
  getAllSOurces()
  {
    this.sources=this.leadService.getAllLeadSource()
  }
  ngAfterViewInit() {
    //this.dt1.datetimeFormat = {"yyyy-mm-dd"};
  }
  getAllData(){
    //this.spinnerService.spinnerStatus(true);
    this.members= this.leadConditionsService.getAllLeadConditions();
    
    //this.spinnerService.spinnerStatus(false);
  }
  sidePanel(id,isEditing){
    this.sidePanelMode=true
    // const initialState = {
    //   isEditing: true ,
    //   user_id:id
    // };
    this.edit=isEditing
    if(isEditing)
    {
      this.boxTitle="Edit Lead Conditions";
       this.leadConditionsService.getAllLeadConditionsById(id)
      .subscribe(
        resp=>{
          this.model=resp;
        },
        (error: HttpErrorResponse) => {
          this.editDataAvbl=false;
        });
      }
    else{
      this.boxTitle="Add New Condition";
      this.model=new TblLeadConditions();
    }
  }
  deleteData(id:any)
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Delete',  module: 'Lead Condition', refNo:id};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
  }

  //Editing Data
  validateForm():boolean{
    // if(!this.model.name){
    //   this.notifyService.showError("Name can not be empty!", "Error");
    //   return false;
    // }
   // else 
    if(!this.model.conditionName){
      this.notifyService.showError("Name can not be empty!", "Error");
      return false;
    }
    else {
      return true;
    }
  }

  create_edit(){

    if(!this.validateForm()){
      return;
    }
    // this.model.userId =document.getElementById("userId").innerText
    // this.model.leadSourceId =document.getElementById("leadSourceId").innerText
   alert( this.model.userId)
   alert(this.model.leadSourceId)
    if(this.edit){
           this.leadConditionsService.saveAllLeadConditions(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else{
      this.leadConditionsService.saveAllLeadConditions(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      },(err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked()
  {
    this.sidePanelMode=false
    this.getAllData();
  }
}

