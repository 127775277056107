import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { NotificationService } from 'src/app/notification.service';
// import { sendWhatsAppRequest } from 'src/jsFunctions.js'
import { TblWhatsAppTemplate } from '../../models/whatsapp-template';
import { TemplateService } from '../../services/template-service';
import { WhatsAppServices } from '../../services/WhatsAppService';
import { WhatsAppCommunication } from '../../models/WhatsAppCommunication';

@Component({
  selector: 'app-whatapp-dialog',
  templateUrl: './whatapp-dialog.component.html',
  styleUrls: ['./whatapp-dialog.component.css']
})
export class WhatappDialogComponent implements OnInit {
  selectedFile: any = null;

  mobileNo: string
  studentName: string
  sendWhatsApp: SendWhatsApp
  tblWhatsAppTemplate: TblWhatsAppTemplate[] = []
  whatasppCommunication:WhatsAppCommunication;
  srcResult: any;
  constructor(public modalRef: BsModalRef,
    public templateService: TemplateService,
    public localStorage: LocalStorageService,
    public notification: NotificationService,
  private whatasppServices:WhatsAppServices) { }

  ngOnInit(): void {
    this.tblWhatsAppTemplate = []
    this.sendWhatsApp = new SendWhatsApp();
    setTimeout(() => {
      this.sendWhatsApp.toName = this.studentName
      this.sendWhatsApp.toNumber = this.mobileNo
    }, 500);
    this.getWhatsAppTemplate()
  }
  getWhatsAppTemplate() {
    this.templateService.getWhatsAppTemplate()
      .subscribe(
        resp => {
          this.tblWhatsAppTemplate = resp;
        },
        (error: HttpErrorResponse) => {

        });
  }
  onTemplateSelect(data: any) {
    this.sendWhatsApp.message = this.tblWhatsAppTemplate.filter((tblWhatsAppTemplate) => tblWhatsAppTemplate.templateId == data)[0].templateDetails;
    this.sendWhatsApp.fileAllowed = this.tblWhatsAppTemplate.filter((tblWhatsAppTemplate) => tblWhatsAppTemplate.templateId == data)[0].fileAllowed;

  }
  sendWhatsAppMessage() {
    this.whatasppCommunication=new WhatsAppCommunication();
    this.whatasppCommunication.toNumber=this.sendWhatsApp.toNumber;
    this.whatasppCommunication.toName=this.sendWhatsApp.toName
    this.whatasppCommunication.templateId=this.sendWhatsApp.templateId
    this.whatasppCommunication.messageDetails=this.sendWhatsApp.message
    this.whatasppCommunication.fileName=this.selectedFile
    this.whatasppServices.sendWhatsAppMessage(this.whatasppCommunication);
  }
  onFileSelected(event: any): void {
  {
    // const inputNode: any = document.querySelector('#file');

    // if (typeof (FileReader) !== 'undefined') {
    //   const reader = new FileReader();
  
    //   reader.onload = (e: any) => {
    //     this.srcResult = e.target.result;
    //   };
  
    //   reader.readAsArrayBuffer(inputNode.files[0]);
    this.selectedFile = event.target.files[0] ?? null;
    }
  }
  handleFileInput(files: FileList) {
    console.log("file uploading")
    if (files[0].size > 2000)
      this.notification.showSuccess("File size limit 2MB", "Call")

    else {
      // this.createSetUpForm.patchValue({
      //   documentPath: files.item(0).name
      // })
      this.whatasppCommunication.file = files.item(0);
      this.whatasppCommunication.fileName = files.item(0).name;
      console.log("file uploaded")
    }
  }

}
export class SendWhatsApp {
  message: string
  templateId: string
  toNumber: string
  toName: string
  fileAllowed:string
}