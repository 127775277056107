<div class="container-fluid" id="container-wrapper" style="background-color: white;" *ngIf="userType=='Admin'">
    <table style=" width: 100%;border: 0;right: 0px;border-bottom: 2px solid grey; ">
        <tr>
            <td style="width: calc(100%-50px) ">
                <h1 class="h3 mb-0 text-gray-800 ">User Wise Activity Report</h1>
            </td>
        </tr>
    </table>
    <div class="row" style="margin-top: 10px;max-height: 50px!important;">

        <div class="col">
            <mat-form-field>
                <mat-select class="field-full-width" placeholder="User" [(ngModel)]="filter.userRefNo">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of users" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>

        </div>
        <div class="col">
            <mat-form-field>
                <mat-select class="field-full-width" placeholder="Action" [(ngModel)]="filter.action">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of action" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <input matInput type="date" name="fromDate" style="max-height: 30px!important;" [(ngModel)]="filter.fromDate" placeholder="From Date" [min]="fromDate">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field style="max-height: 30px;padding: 0px;border-bottom: 0;">
                <input matInput type="date" name="toDate" [(ngModel)]="filter.toDate" placeholder="To Date" [min]="fromDate">
            </mat-form-field>
        </div>
        <div class="col">
            <button class="btn btn-primary" type="button" style="width: 100%;">View</button>
        </div>
    </div>
</div>