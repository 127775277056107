<div id="mainBar">
    <mat-toolbar *ngIf="!inLoginPage" class="standard-color">
        <a class="sidebar-brand d-flex align-items-center justify-content-center" *ngIf="!androidOS">
            <div class="sidebar-brand-icon">
                <img src="./../../../assets/img/logo.png">
            </div>
        </a>
        <div class="sidebar-brand-text mx-3" style="font-size: 12px;color:white" *ngIf="!androidOS">IMIS-LMS</div>

        <div class="sidebar-brand-text mx-3" style="font-size: 12px;color:white" *ngIf="androidOS">Lead Management System</div>
        <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu" *ngIf="!androidOS">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="toolbar-item-spacer"></span>

        <li class="nav-item dropdown no-arrow mx-1" *ngIf="!androidOS">
            <a class="nav-link " id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell fa-fw" style="color: white;"></i>
                <!-- <span class="badge badge-danger badge-counter">3+</span> -->
            </a>
            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                <h6 class="dropdown-header">
                    Alerts
                </h6>


                <a class="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
            </div>
        </li>
        <button mat-icon-button matTooltip="My Account" [matMenuTriggerFor]="userAccountMenu" *ngIf="!androidOS">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
            <button mat-menu-item (click)="Profile();">
                <mat-icon>person</mat-icon><span>My Profile</span>
            </button>
            <button mat-menu-item (click)="settingsClick()">
                <mat-icon>settings</mat-icon><span>Settings</span>
            </button>
            <button mat-menu-item (click)="activityLog()">
                <mat-icon>help</mat-icon><span>Activity Log</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logOutClick()">
                <mat-icon>exit_to_app</mat-icon>Logout
            </button>
        </mat-menu>
    </mat-toolbar>

    <mat-sidenav-container class="app-container">
        <mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" class="app-sidenav" id="sideMenu" style="background-color: white;">
            <app-side-menu></app-side-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet (activate)="changeOfRoutes()">
            </router-outlet>
            <app-bottom-bar *ngIf="!inLoginPage" id="bottomBar"></app-bottom-bar>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>