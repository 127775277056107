import { environment } from "src/environments/environment";
import { CommonHeader } from "./common-header";
import { HttpClient } from "@angular/common/http";
import { ExceptionHandler } from "./errorhandler";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { WhatsAppCommunication } from "../models/WhatsAppCommunication";

@Injectable({
    providedIn: "root",
  })
  export class WhatsAppServices {
    
    private serviceRoot = `${environment.serviceRoot}`;
    commonHeader:CommonHeader=new CommonHeader() 
    private options=this.commonHeader.options;
    constructor(
      private http: HttpClient,
      private ehandler:ExceptionHandler,
      private localStorage:LocalStorageService
    ) { }
    sendWhatsAppMessage(whatsAppCommunication:WhatsAppCommunication) :Observable<any>{
        this.localStorage.set("method","Save/Update")
        this.localStorage.set("operation","Save/Update WhatsApp Message")
        const endPoint = `${this.serviceRoot}/saveAndSendWhatsAppMessage`;
        console.log("Data="+JSON.stringify(whatsAppCommunication))
        return this.http.post<any>(endPoint, whatsAppCommunication,this.options).pipe(catchError(this.ehandler.errorHandler));
    }
}