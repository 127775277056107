export class LeadFilter
{
    fromDate:string
    toDate:string
    state:string
    city:string
    status:string
    subStatus:string
    userRefNo:string
    leadSource:string
    leadCampaign:string
}