import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocalStorageService } from './utility/localStorageService';
import { BehaviorSubject, Subscription, SubscriptionLike, fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { UserServices } from './shared/services/userservices';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProfileDialogComponent } from './shared/dialogs/profile-dialog/profile-dialog.component';
import { SettingsDialogComponent } from './shared/dialogs/settings-dialog/settings-dialog.component';
import { LeadsDialogComponent } from './shared/dialogs/leads-dialog/leads-dialog.component';
import { LeadService } from './shared/services/lead.services';
import { EventSourceService } from './shared/services/EventSourceService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('andToken') andToken: ElementRef;
  @ViewChild('callFrom') callFrom: ElementRef;

  @ViewChild("leftSide") leftSide: HTMLElement
  @ViewChild("rightSide") rightSide: HTMLElement


  inLoginPage: boolean = true
  menuLevel: number = 0;
  manageMenuLevel: number = 0
  isShow: boolean = true
  isAuthenticated: true
  title = 'CRM';
  spinner: boolean = false;
  showTopBar: boolean = true;
  event$: Subscription;
  message: any = null;
  androidOS: boolean = false
  noofMessages: number = 0
  page: string = "Main"
  listenToClick: void;
  modalRef: BsModalRef;
  pageLoading: boolean = true
  leadDialogOpned: boolean = false
  showBottomBar: boolean = false
  screenHeight: number;
  screenWidth: number;
  deviceId:string;
  private readonly eventSourceSubscription: SubscriptionLike;

  constructor(private router: Router,
    private localStorage: LocalStorageService,
    private userService: UserServices,
    //private eventListenerService: EventListenerService,
    private modalService: BsModalService,
    private leadService: LeadService,
    private eventSourceService: EventSourceService

  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pageLoading = false
      }
    });

  }
  ngOnDestroy() {
    this.eventSourceSubscription.unsubscribe();
    this.eventSourceService.close();
}
  changeOfRoutes() {
    console.log(" I am here 3")
    if (window.location.href.includes("/login")) {
      this.showTopBar = false
      this.inLoginPage = true
      this.showBottomBar = false

    }
    else {
      this.inLoginPage = false
      this.showTopBar = true
      this.showBottomBar = true
    }
    if (this.getOS() == "Android")
      this.androidOS = true
    else
      this.androidOS = false
    if (this.localStorage.get("userName") == "null" || this.localStorage.get("refNo") == "null") {
      this.showTopBar = false
      this.router.navigateByUrl("login")
    }
  }

  spinnerStatus(status: boolean) {
    this.spinner = status;
  }

  ngOnInit(): void {
   
    this.localStorage.set("apiUrl",environment.serviceRoot);
    if(this.localStorage.get("deviceId")==null)
    {
      this.localStorage.set("deviceId",new Date().getTime().toString());
    }
    this.deviceId=this.localStorage.get("deviceId");
    if (this.getOS() != "Android") {
      //this.requestPermission();
      //this.listen()
    }


    fromEvent(window, 'token').subscribe((event: any) => {

      this.userService.updateUserTokenByIdAndSource(this.andToken.nativeElement.value, this.localStorage.get("refNo"), this.getOS())
        .subscribe(
          resp => { },
          (error: HttpErrorResponse) => {
          });
    })
    fromEvent(window, 'call').subscribe((event: any) => {
      this.leadService.getPhoneNoExistOrNot(this.callFrom.nativeElement.value)
        .subscribe(
          resp => {
            if (resp == true && this.leadDialogOpned == false) {
              this.modalRef = this.modalService.show(LeadsDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });
              this.modalRef.content.mobileNo = this.callFrom.nativeElement.value;
            }
            this.leadDialogOpned = true
          },
          (error: HttpErrorResponse) => {
          });


    })








    if (this.getOS() == "Android") {
      this.androidOS = true
      this.localStorage.set("osType", "Android")
      if (this.leftSide != null)
        this.leftSide.style.display = "block";
      if (this.rightSide != null)
        this.rightSide.setAttribute("style", "width: calc(100% - 220px)")
    }
    else {
      this.androidOS = false
      this.localStorage.set("osType", "Windows")
      if (this.leftSide != null)
        this.leftSide.setAttribute("style", "visibility:none")
      if (this.rightSide != null)
        this.rightSide.setAttribute("style", "width:100%")
    }
    setTimeout(() => {
      if(!window.location.href.includes("/login"))
      {
       
        let element = document.getElementById('sideMenu');
        if (element) {
          element.style.setProperty('display', 'block');
        }
        element = document.getElementById('bottomBar');
        if (element) {
          element.style.setProperty('display', 'block');
        }
        
      }
     
    }, 100);

  }
  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            console.log("Hurraaa!!! we got the token.....");
            if (currentToken != null || currentToken != undefined) {
              if (this.localStorage.get("refNo") != null) {
                console.log(currentToken);
                this.userService.updateUserTokenByIdAndSource(currentToken, this.localStorage.get("refNo"), this.getOS())
                  .subscribe(
                    resp => { },
                    (error: HttpErrorResponse) => {
                    });
              }

            }
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
    });
  }

  getOS() {
    var uA = navigator.userAgent || navigator.vendor;
    if ((/iPad|iPhone|iPod/.test(uA)) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }


 
 
}
