import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { LocalStorageService } from 'src/app/utility/localStorageService';

@Component({
  selector: 'app-user-wise-activity',
  templateUrl: './user-wise-activity.component.html',
  styleUrls: ['./user-wise-activity.component.css']
})
export class UserWiseActivityComponent implements OnInit {
  userType: string;
  filter: Filter;
  fromDate: string
  toDate:string
  constructor(
    private localStorage: LocalStorageService,
  ) { }

  ngOnInit(): void {
     this.fromDate = this.localStorage.get("showMeLeadsFromDate")
        this.toDate = (moment(this.addDays(new Date(),-1))).format('YYYY-MM-DD')
    this.userType = this.localStorage.get("userType")
    this.filter = new Filter()
    this.filter.fromDate = (moment(new Date(this.fromDate))).format('YYYY-MM-DD')
    this.filter.toDate = (moment(new Date())).format('YYYY-MM-DD')
   
    this.filter.userRefNo = "All"
    this.filter.action = "All"
  }
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
}
export class Filter
{
    fromDate:string
    toDate:string
 
    userRefNo:string
    action:string
}