<div class="card mb-4">
  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">Add Contact Details</h6>
  </div>
  <div class="card-body">
    <form>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">Name</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="inputName">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword3" class="col-sm-3 col-form-label">Designation</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="inputDesig">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">Mobile No</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="inputMobile">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">Email-Id</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="inputEmail">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">Remarks</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="inputRemarks">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-10">
          <button type="button" class="btn btn-primary">Save</button> &nbsp;&nbsp;
          <button type="button" class="btn btn-danger">Close</button>
        </div>
      </div>
    </form>
  </div>
</div>

