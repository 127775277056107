<div class="subPageComponent">
  <div class="row">
    <div class="col-md-10">
      <h4>
        Calls
      </h4>
    </div>
    <div class="col-md-2">
      <button class="btn btn-sm btn-primary" (click)="openModal();">
        <i class="fas fa-plus"></i> Add Call
      </button>
    </div>
  </div>
  <table class="table">
    <tr>
      <td>Date : 02-12-2021 10:17</td>
      <td>Call Type : Outgoing</td>
      <td>Call Status : Discussed and ready for purchase</td>
    </tr>
    <tr>
      <td colspan="3" style="font-size:smaller;">
          Created by : Admin on Thurs, 02 Dec 2021 10:23 PM
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <button class="btn btn-sm btn-primary">
          <i class="fas fa-pen"></i> Edit
        </button> &nbsp;
        <button class="btn btn-sm btn-danger">
          <i class="fas fa-trash"></i> Delete
        </button> &nbsp;
      </td>
    </tr>
  </table>
</div>
