<div class="container-fluid" id="container-wrapper" style="background-color: white;">
    <div class="row h4 mb-0 text-gray-800" style="border-bottom: 2px solid grey;display: inline-flex;overflow-y: hidden;overflow-x: scroll;white-space: nowrap;width: 100%;float: inline-end;margin-top: 5px;position: fixed;padding-right:10px ;">
        <div class="h4 mb-0 text-gray-800" style="padding-left: 0px;display: block;" class="col "><span class="btn-danger">Follow Ups ({{subStatusWiseLeads[0].value}})</span> </div>
        <div style="text-align: right !important;padding-right: 10px;">
            <li class="nav-item dropdown no-arrow mx-1">
                <a (click)="openFilterForm()" style="cursor: pointer;margin-right: 10px;">
                    <i class="fa fa-filter fa-sm text-danger pull-right" aria-hidden="true"></i>
                </a>
            </li>
        </div>
    </div>
    <div class="row" style="padding-top: 35px;">
        <div style="display: inline-flex;overflow-y: hidden;overflow-x: scroll;white-space: nowrap;width: 100%;float: inline-end;margin-top: 5px;position: fixed;">
            <a *ngFor="let mnu of subStatusWiseLeads" class="btn btn-primary text-white" style="margin-right: 3px!important;" (click)="getLeadsBySubStatus(mnu.name)">
            {{mnu.name}}( {{mnu.value}} )
            </a>
        </div>
    </div>
    <div class="row" id="dataArea" #dataArea style="margin-top: 50px!important;overflow-y: scroll !important;overflow-x: hidden;height: 570px;">
        <div class="div-viewport">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%!important;" *ngIf="!androidOs">
                <ng-container matColumnDef="Student Details">
                    <th class="mat-column" mat-header-cell *matHeaderCellDef>&nbsp;Student Details </th>
                    <td mat-cell class="mat-column" *matCellDef="let element"> <b>Name :</b> {{element.studentName}}<br><b>Mobile : </b>{{element.mobileNo}} <br><b>EMail : </b>{{element.emailId}}</td>
                </ng-container>
                <ng-container matColumnDef="Address Details">
                    <th class="mat-column" mat-header-cell *matHeaderCellDef>&nbsp;Address Details</th>
                    <td mat-cell class="mat-column" *matCellDef="let element"><b>Address : </b>{{element.address}}<br><b>City : </b>{{element.cityName}}<br><b>State :</b> {{element.stateName}} </td>
                </ng-container>
                <ng-container matColumnDef="Lead Status" style="min-width: 200px!important;">
                    <th class="mat-column" mat-header-cell *matHeaderCellDef>&nbsp;Lead Status </th>
                    <td mat-cell class="mat-column" *matCellDef="let element"><b>AlterNate No :</b> {{element.alternateNo}}<br><b> Counsellor :</b> {{element.assignToCounsellor}}<br><b>Status :</b> {{element.status}} </td>
                </ng-container>
                <ng-container matColumnDef="Lead Details">
                    <th class="mat-column" mat-header-cell *matHeaderCellDef>&nbsp;Lead Details </th>
                    <td mat-cell class="mat-column" *matCellDef="let element"><b>Lead Date :</b> {{element.leadDate}}<br><b>Source :</b> {{element.source}}<br><b>Channel :</b> {{element.leadMedium}} </td>
                </ng-container>
                <ng-container matColumnDef="Action" style="max-width: 100px!important;float: right;">
                    <th class="mat-column right_align" mat-header-cell *matHeaderCellDef>&nbsp;Action </th>
                    <td mat-cell class="mat-column right_align" *matCellDef="let element">
                        <img src="./../../../assets/img/phone-call.png" style="width: 30px;height:30px;cursor: pointer;" (click)="phoneClick(element.studentName,element.mobileNo)">
                        <img src="./../../../assets/img/sms.png" style="width: 30px;height:30px;cursor: pointer;" (click)="smsClick(element.studentName,element.mobileNo)">
                        <img src="./../../../assets/img/whatsapp.png" style="width: 28px;height:28px;cursor: pointer;" (click)="whatsappClick(element.studentName,element.mobileNo)">
                        <img src="./../../../assets/img/email.png" style="width: 26px;height:26px;cursor: pointer;" (click)="emailClick(element.studentName,element.email)">
                        <li class="nav-item dropdown no-arrow mx-1" style="cursor: pointer;">
                            <a class="nav-link " id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </a>




                            <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                <a class="dropdown-item d-flex align-items-center" (click)="editLeadsClicked(element.refNo,true)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/edit.png" style="width: 30px;height:25px;padding-right: 10px;">
                                        </div>
                                    </div>
                                    <div>
                                        Edit
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(element.refNo,true)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/chatting.png" style="width: 30px;height:25px;padding-right: 10px;">
                                        </div>
                                    </div>
                                    <div>
                                        Start Chat
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="openNotesForm(element.refNo,element.studentName)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/project.png" style="width: 30px;height:25px;padding-right: 10px;">
                                        </div>
                                    </div>
                                    <div>
                                        Notes
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="assignToOtherCounsellor(element.refNo,element.studentName)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/assign.png" style="width: 30px;height:25px;padding-right: 10px;">

                                        </div>
                                    </div>
                                    <div>
                                        Assign Other
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="viewLeadWiseActivityLog(element.refNo,element.studentName)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/followup.png" style="width: 30px;height:25px;padding-right: 10px;">

                                        </div>
                                    </div>
                                    <div>
                                        View All Action
                                    </div>
                                </a>
                            </div>
                        </li>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true" style="background-color: rgb(9, 144, 235);color: white!important;height: 40px;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%!important;" *ngIf="androidOs">
                <ng-container matColumnDef="Student Details">
                    <th class="mat-column" mat-header-cell *matHeaderCellDef>&nbsp;Student Details </th>
                    <td mat-cell class="mat-column" *matCellDef="let element"> {{element.studentName}} <br>{{element.mobileNo}} <br>{{element.emailId}}</td>
                </ng-container>

                <ng-container matColumnDef="Action" style="max-width: 100px!important;float: right;" class="right_align">
                    <th class="mat-column right_align" mat-header-cell *matHeaderCellDef>&nbsp;Action </th>
                    <td mat-cell class="mat-column right_align" *matCellDef="let element">
                        <img src="./../../../assets/img/phone-call.png" style="width: 30px;height:30px;cursor: pointer;" (click)="phoneClick(element.studentName,element.mobileNo)">
                        <img src="./../../../assets/img/sms.png" style="width: 30px;height:30px;cursor: pointer;" (click)="smsClick(element.studentName,element.mobileNo)">
                        <img src="./../../../assets/img/whatsapp.png" style="width: 28px;height:28px;cursor: pointer;" (click)="whatsappClick(element.studentName,element.mobileNo)">
                        <img src="./../../../assets/img/email.png" style="width: 26px;height:26px;cursor: pointer;" (click)="emailClick(element.studentName,element.email)">

                        <li class="nav-item dropdown no-arrow mx-1" style="cursor: pointer;">
                            <a class="nav-link " id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                <a class="dropdown-item d-flex align-items-center" (click)="editLeadsClicked(element.refNo,true)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/edit.png" style="width: 30px;height:25px;padding-right: 10px;">
                                        </div>
                                    </div>
                                    <div>
                                        Edit
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(element.refNo,true)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/chatting.png" style="width: 30px;height:25px;padding-right: 10px;">
                                        </div>
                                    </div>
                                    <div>
                                        Start Chat
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="openNotesForm(element.refNo,element.studentName)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/project.png" style="width: 30px;height:25px;padding-right: 10px;">
                                        </div>
                                    </div>
                                    <div>
                                        Notes
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="assignToOtherCounsellor(element.refNo,element.studentName)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/assign.png" style="width: 30px;height:25px;padding-right: 10px;">

                                        </div>
                                    </div>
                                    <div>
                                        Assign Other
                                    </div>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" (click)="viewLeadWiseActivityLog(element.refNo,element.studentName)">
                                    <div class="mr-3">
                                        <div class="icon-circle">
                                            <img src="./../../../assets/img/followup.png" style="width: 30px;height:25px;padding-right: 10px;">

                                        </div>
                                    </div>
                                    <div>
                                        View All Action
                                    </div>
                                </a>
                            </div>
                        </li>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAnd ; sticky: true" style="background-color: rgb(9, 144, 235);color: white!important;height: 35px;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAnd;"></tr>
            </table>

        </div>

    </div>


</div>

<!-- FILTER FORM-->
<div id="myFilterForm" class="form-popup-popup" style="z-index: 999;">
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Lead Filter</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeFilterForm()">X</button>
    </div>
    <div class="card-body" style="height: 170px!important;overflow-y: scroll;">

        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <input matInput type="date" name="fromDate" [(ngModel)]="leadFilter.fromDate" placeholder="From Date" [min]="fromDate">
            </mat-form-field>
            <mat-form-field class="col-sm-6">
                <input matInput type="date" name="toDate" [(ngModel)]="leadFilter.toDate" placeholder="To Date" [min]="fromDate">
            </mat-form-field>
        </div>
        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="Lead Status" [(ngModel)]="leadFilter.status">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listStatus" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-sm-6">

                <mat-select class="field-full-width" placeholder="Lead Sub-Status" [(ngModel)]="leadFilter.subStatus">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listSubStatus" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="State" [(ngModel)]="leadFilter.state">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listState" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-sm-6">

                <mat-select class="field-full-width" placeholder="City" [(ngModel)]="leadFilter.city">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listCity" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="filterLeads();closeFilterForm();">Apply</button>
    </div>
</div>





<!-- NOTES FORM-->

<div id="myNotesForm" class="form-popup-popup">
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Notes</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeNotesForm()">X</button>
    </div>
    <div class="card-body" style="height: 320px!important;overflow-y: hidden;">

        <div class="form-group row" style="display: none;">
            <label class="col-sm-12" for="inputEmail3">Ref No</label>
            <div class="col-sm-12">
                <input type="text" class="form-control" name="studentId" [(ngModel)]="leadNotes.studentId">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-12" for="inputEmail3">Name</label>
            <div class="col-sm-12">
                <input type="text" class="form-control" name="name" [(ngModel)]="leadNotes.name" readonly>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-12" for="inputEmail3">On Date</label>
            <div class="col-sm-12">
                <input type="date" class="form-control" name="onDate" [(ngModel)]="leadNotes.onDate">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-12" for="inputEmail3">Action</label>
            <div class="col-sm-12">
                <select name="action" class="form-control" [(ngModel)]="leadNotes.action">
                    <option value="Call" selected>Call</option>
                    <option value="SMS">SMS</option>
                    <option value="WhatsApp">WhatsApp</option>
                    <option value="E-Mail">E-Mail</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-12" for="inputEmail3">Details</label>
            <div class="col-sm-12">
                <input type="text" class="form-control" name="note" [(ngModel)]="leadNotes.note">
            </div>
        </div>
    </div>

    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="saveNotes();closeNotesForm();">Save</button>
    </div>
</div>