<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Profile</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <form>
            <div class="form-group row">
                <label class="col-sm-12" for="inputEmail3">Name</label>
                <div class="col-sm-12">
                    <input type="text" class="form-control" name="userName" [(ngModel)]="user.userName">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12" for="inputEmail3">EMail</label>
                <div class="col-sm-12">
                    <input type="email" class="form-control" name="emailId" [(ngModel)]="user.emailId">
                </div>
            </div>

            <div class="form-group row">
                <label for="inputPassword3" class="col-sm-12">Mobile No</label>
                <div class="col-sm-12">
                    <input type="text" class="form-control" name="mobileNo" [(ngModel)]="user.mobileNo">
                </div>
            </div>

            <div class="form-group row">
                <label for="inputPassword3" class="col-sm-12">Till Date</label>
                <div class="col-sm-12">
                    <input type="date" class="form-control" name="tillDate" [(ngModel)]="user.tillDate">
                </div>
            </div>
        </form>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="updateProfile()">Update Profile</button>
    </div>
</div>