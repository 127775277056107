import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideMenuComponent } from './shared/side-menu/side-menu.component';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { AddCallComponent } from './shared/calls/add-call/add-call.component';
import { ViewCallComponent } from './shared/calls/view-call/view-call.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AddContactsComponent } from './shared/contacts/add-contacts/add-contacts.component';
import { ViewContactsComponent } from './shared/contacts/view-contacts/view-contacts.component';
import { ViewNotesComponent } from './shared/notes/view-notes/view-notes.component';
import { AddNotesComponent } from './shared/notes/add-notes/add-notes.component';
import { ProductsIndexComponent } from './masters/products-index/products-index.component';
import { ViewEditProductsComponent } from './masters/view-edit-products/view-edit-products.component';
import { ViewEditLeadSourcesComponent } from './masters/view-edit-sources/view-edit-sources.component';
import { ExceptionHandler } from './shared/services/errorhandler';
import { TeamsIndexComponent } from './masters/teams-index/teams-index.component';
import { ViewEditTeamsComponent } from './masters/view-edit-teams/view-edit-teams.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LeadSourcesComponent } from './masters/lead-sources/lead-sources.component';
import { UsersComponent } from './masters/users/users.component';
import { LeadStatusComponent } from './masters/lead-status/lead-status.component';
import { LeadSubStatusComponent } from './masters/lead-sub-status/lead-sub-status.component';
import { LeadMediumComponent } from './masters/lead-medium/lead-medium.component';
import { CommunicationSetupComponent } from './masters/communication-setup/communication-setup.component';
import { LeadImportComponent } from './masters/lead-import/lead-import.component';
import { LeadDistributionComponent } from './masters/lead-distribution/lead-distribution.component';
import { CoursesComponent } from './masters/courses/courses.component';
import { LeadCampaignComponent } from './masters/lead-campign/lead-campaign.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LeadsComponent } from './leads/leads.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataTablesModule } from 'angular-datatables';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { TestComponent } from './masters/test/test.component';
import { ForgotPasswordDialogComponent } from './shared/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { ProfileDialogComponent } from './shared/dialogs/profile-dialog/profile-dialog.component';
import { LeadUploadComponent } from './masters/lead-upload/lead-upload.component';
import { SettingsDialogComponent } from './shared/dialogs/settings-dialog/settings-dialog.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { LeadsDialogComponent } from './shared/dialogs/leads-dialog/leads-dialog.component';
import { BottomBarComponent } from './shared/bottom-bar/bottom-bar.component';
import { ViewLeadWiseActivityComponent } from './shared/dialogs/view-lead-wise-activity/view-lead-wise-activity.component';
import { AssignLeadToOtherDialogComponent } from './shared/dialogs/assign-lead-to-other-dialog/assign-lead-to-other-dialog.component';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import {MatSelect, MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import {
  DefaultMatCalendarRangeStrategy,
  MatDatepickerModule,
  MatRangeDateSelectionModel,
} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { LoggingInterceptor } from './LoggingInterceptor';
import { ActivityLogDialogComponent } from './shared/dialogs/activity-log-dialog/activity-log-dialog.component';
import { UseractivityComponent } from './useractivity/useractivity.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SmsDialogComponent } from './shared/dialogs/sms-dialog/sms-dialog.component';
import { EmailDialogComponent } from './shared/dialogs/email-dialog/email-dialog.component';
import { ConfirmDialogComponent } from './shared/dialogs/confirm-dialog/confirm-dialog.component';
import { RawListComponent } from './raw-list/raw-list.component';
import { WhatappDialogComponent } from './shared/dialogs/whatapp-dialog/whatapp-dialog.component';
import { EmailTemplateComponent } from './communication-templates/email-template/email-template.component';
import { SmsTemplateComponent } from './communication-templates/sms-template/sms-template.component';
import { WhatsappTemplateComponent } from './communication-templates/whatsapp-template/whatsapp-template.component';
import { AuthInterceptor } from './AuthInterceptor';
import { LeadChannelComponent } from './masters/lead-channel/lead-channel.component';
import { AppsComponent } from './apps/apps.component';
import { PagerService } from './shared/pagination/pager.service';
import { SourceWiseLeadStatusComponent } from './source-wise-lead-status/source-wise-lead-status.component';
import { LeadConditionsComponent } from './lead-conditions/lead-conditions.component';
import { MyUploadsComponent } from './my-uploads/my-uploads.component';
import { UpcomingFollowUpsComponent } from './upcoming-follow-ups/upcoming-follow-ups.component';
import { FailedFollowUpsComponent } from './failed-follow-ups/failed-follow-ups.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loaderService';
import { CallDialogComponent } from './shared/dialogs/call-dialog/call-dialog.component';
import { DownloadDialogComponent } from './shared/dialogs/doadload-dialog/download-dialog.component';
import { StatusWiseAverageTimeComponent } from './reports/status-wise-average-time/status-wise-average-time.component';
import { CounsellorLeadStatusComponent } from './reports/counsellor-lead-status/counsellor-lead-status.component';
import { UserWiseActivityComponent } from './reports/user-wise-activity/user-wise-activity.component';
import { BackupComponent } from './backup/backup.component';
import { RestoreComponent } from './restore/restore.component';
import { WebSocketService } from './WebSocketService';
import { BulkReassignLeadsComponent } from './bulk-reassign-leads/bulk-reassign-leads.component';

initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SideMenuComponent,
    TopBarComponent,
    LeadsComponent,
    AddCallComponent,
    ViewCallComponent,
    AddContactsComponent,
    ViewContactsComponent,
    ViewNotesComponent,
    AddNotesComponent,
    ProductsIndexComponent,
    ViewEditProductsComponent,
    LeadSourcesComponent,
    ViewEditLeadSourcesComponent,
    UsersComponent,
    TeamsIndexComponent,
    ViewEditTeamsComponent,
    LeadStatusComponent,
    LeadSubStatusComponent,
    LeadCampaignComponent,
    LeadMediumComponent,
    CommunicationSetupComponent,
    LeadImportComponent,
    LeadDistributionComponent,
    CoursesComponent,
    LoginComponent,
    TestComponent,
    ForgotPasswordDialogComponent,
    ProfileDialogComponent,
    LeadUploadComponent,
    SettingsDialogComponent,
    UserDashboardComponent,
    LeadsDialogComponent,
    BottomBarComponent,
    ViewLeadWiseActivityComponent,
    AssignLeadToOtherDialogComponent,
    ActivityLogDialogComponent,
    UseractivityComponent,
    SmsDialogComponent,
    EmailDialogComponent,
    ConfirmDialogComponent,
    UpcomingFollowUpsComponent,
    RawListComponent,
    WhatappDialogComponent,
    EmailTemplateComponent,
    SmsTemplateComponent,
    WhatsappTemplateComponent,
    LeadChannelComponent,
    AppsComponent,
    SourceWiseLeadStatusComponent,
    LeadConditionsComponent,
    MyUploadsComponent,
    FailedFollowUpsComponent,
    LoaderComponent,
    CallDialogComponent,
    DownloadDialogComponent,
    StatusWiseAverageTimeComponent,
    CounsellorLeadStatusComponent,
    UserWiseActivityComponent,
    BackupComponent,
    RestoreComponent,
    BulkReassignLeadsComponent,
    
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    CommonModule,
    MatCardModule,
    
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatListModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MdbCheckboxModule,
    MatPaginatorModule,
    MatSidenavModule,
    BrowserModule, 
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    ReactiveFormsModule,
    DataTablesModule,
    BrowserModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ExceptionHandler,
    CanvasJSAngularChartsModule,
    
  ],
  providers: [DatePipe,LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    WebSocketService,
    PagerService,
  ],
  bootstrap: [AppComponent,DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel,HttpClient]
})
export class AppModule {
}
