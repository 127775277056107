import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TblUserWiseTransaction } from '../shared/models/userwisetransactions';
import { CommonService } from '../shared/services/common.services';
import * as moment from 'moment';
@Component({
  selector: 'app-useractivity',
  templateUrl: './useractivity.component.html',
  styleUrls: ['./useractivity.component.css']
})
export class UseractivityComponent implements OnInit {
  onDate:string
  loginForm!:FormGroup;
  TblUserActivity:TblUserWiseTransaction[]=[]
  constructor(private fb:FormBuilder,private commonService:CommonService) { }

  ngOnInit(): void {
    this.TblUserActivity=[]
    this.loginForm = this.fb.group({
      fromDate: [new Date(), Validators.required],
      toDate:[new Date(),[Validators.required]]
    });
    
     this.loginForm.get('fromDate').setValue(this.onDate);
     this.loginForm.get('toDate').setValue(new Date());
  }
  
  viewClick()
  {
    let momentFromoDate = new Date(this.loginForm.get('fromDate').value);
    let formattedFromDate = moment(momentFromoDate).format("YYYY-MM-DD 00:00:00");

    let momenttoDate = new Date(this.loginForm.get('toDate').value);
    let formattedToDate = moment(momenttoDate).format("YYYY-MM-DD 23:59:59");

    this.commonService.getUserWiseTransactionByUserIDAndDate(1,formattedFromDate,formattedToDate).subscribe(resp => {
      if (resp.length > 0) {
        this.TblUserActivity=resp;
        this.TblUserActivity.forEach(element => {
            element.requestDateTime= moment(element.requestDateTime).format("DD-MM-YYYY");
        });
      }
    });
  }
}
