import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from './utility/localStorageService';
import { environment } from 'src/environments/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

// case insensitive check against config and value
const startsWithAny = (arr: string[] = []) => (value = '') => {
  return arr.some(test => value.toLowerCase().startsWith(test.toLowerCase()));
};

// http, https, protocol relative
const isAbsoluteURL = startsWithAny(['http', '//']);
@Injectable({
  providedIn: 'root'
})
export class LoggingInterceptor implements HttpInterceptor {
  userId: string;
  method: string;
  operation: string;
  
  constructor(private localStorage: LocalStorageService,@Optional() @Inject(REQUEST) protected request: Request) {


  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.userId = this.localStorage.get("refNo")
    this.method = this.localStorage.get("method")
    this.operation = this.localStorage.get("operation")
    if(this.userId==null) this.userId="New Login"
    if(this.method==null) this.method="Get"
    if(this.operation==null) this.operation="Accessing Login Page"
    const modifiedRequest = request.clone({ setHeaders: { 'X-Request-ID': this.calcCorrelationId(), 'byUser': this.userId, 'method': this.method, 'operation': this.operation } });
    
    return next.handle(modifiedRequest).pipe(tap(response => console.log('Response:', response)));
  }
  calcCorrelationId() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
  }
}