<div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom:2px solid grey;">
        <h1 class="h3 mb-0 text-gray-800">Team Master</h1>
        <ol class="breadcrumb">
            <button class="btn btn-sm btn-success" (click)="openAddNewBox()">
        <i class="fas fa-plus"></i> Add New
      </button> &nbsp;
            <button class="btn btn-sm btn-primary">
        <i class="fas fa-upload"></i> Import
      </button>&nbsp;
            <button class="btn btn-sm btn-success">
        <i class="fas fa-download"></i> Export
      </button> &nbsp;
            <button class="btn btn-sm btn-warning" (click)="getAllData()">
        <i class="fas fa-refresh"></i> Refresh
      </button>
        </ol>
    </div>
    <div class="row" id="dataArea" #dataArea style="margin-top: 10px!important;overflow-y: scroll !important;overflow-x: hidden;height: 570px;">
        <div class="div-viewport">
            <div class="col-lg-12">
                <table class="table table-sm table-hover">
                    <tr style="background-color: blue;color: white;">
                        <th>#</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Created On</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let item of sources | async;let counter=index;">
                        <td>{{counter+1}}</td>
                        <td>{{item.name}}</td>
                        <td>
                            <span *ngIf="item.status==1">Active</span>
                            <span *ngIf="item.status!=1">In active</span>
                        </td>
                        <td>{{item.createdOn | date:'medium'}}</td>
                        <th>
                            <button class="btn btn-sm btn-primary" (click)="editBox(item.teamId)">
                  <i class="fas fa-pen"></i> Edit
                </button> &nbsp;
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>