
import { Component, HostListener, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { TblLead } from 'src/app/shared/models/leads';
import { LeadService } from 'src/app/shared/services/lead.services';

import { LocalStorageService } from '../utility/localStorageService';
import * as moment from 'moment';

import { LeadFilter } from '../shared/models/lead-filter';
import { LeadNotes } from '../shared/models/notes';
import { ViewLeadWiseActivityComponent } from '../shared/dialogs/view-lead-wise-activity/view-lead-wise-activity.component';
import { MatTableDataSource } from '@angular/material/table';
import { FirstLeadAnalysis } from '../shared/models/first-lead-analysis';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe, formatDate } from '@angular/common';
import { SSEActionCall } from '../shared/models/sse-action';
import { RawLeadsFromThirdParty } from '../shared/services/raw-data-from-third-party';
import { PagerService } from '../shared/pagination';
@Component({
  selector: 'app-source-wise-lead-status',
  templateUrl: './source-wise-lead-status.component.html',
  styleUrls: ['./source-wise-lead-status.component.css']
})
export class SourceWiseLeadStatusComponent implements OnInit {
  sidePanelMode: boolean = false;

  model: TblLead;
  rawLeadsFromThirdParty: RawLeadsFromThirdParty[];
  leadNotes: LeadNotes




  status: string = "All";
  rawLeadsFullData: RawLeadsFromThirdParty[];

  sSEActionCall: SSEActionCall;


  dataSource: MatTableDataSource<RawLeadsFromThirdParty>
  dataValue: FirstLeadAnalysis[] = [];

  displayedColumns: string[] = ['Student Details', 'Contact Details', 'Address', 'Lead Status', 'Lead Details'];
  pageEvent: PageEvent;
  length: number;
  pageSize: number;
  rawLeadsStatus: { name: string; value: number; }[];
  seqlistSource: any[];
  modalRef: BsModalRef;
  pager: any = {};
  private allItems: any[];
  leadFilter: LeadFilter
  listStatus: string[]
  listState: string[]
  listSource: string[]
  listCurrentStatus: string[]
  // paged items
  pagedItems: any[];
  androidOs: boolean;
  constructor(private _leadservices: LeadService,
    private modalService: BsModalService,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private pagerService: PagerService,
    @Inject(LOCALE_ID) public locale: string,

  ) {
    this.dataSource = new MatTableDataSource;
  }

  ngOnInit(): void {

    this.leadFilter = new LeadFilter()
    this.leadFilter.fromDate = (moment(new Date(this.fromDate))).format('YYYY-MM-DD')
    this.leadFilter.toDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadFilter.state = "All"
    this.leadFilter.leadSource = "All"
    this.leadFilter.status = "All"
    this.leadFilter.subStatus = "All"
    this.leadFilter.userRefNo = this.localStorage.get("refNo")



    this.fromDate = this.localStorage.get("showMeLeadsFromDate")
    this.status = "All"
    this.leadNotes = new LeadNotes()
    this.model = new TblLead()
    if (this.localStorage.get("osType") == "Android") {
      this.androidOs = true
    }
    else {
      this.androidOs = false
    }
    this.rawLeadsStatus = [{ name: 'All', value: 0 }]
    this.getAllRawLeads();
  }
  getLeadsBySource(leadSource: any) {
    this.rawLeadsFromThirdParty = this.rawLeadsFullData;
    if (leadSource != 'All') {
      this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.currentStatus == leadSource);
    }
    this.setPage(1)
  }

  getAllRawLeads() {
    this._leadservices.getRawLeadsByBatch(this.localStorage.get("accessSource")).subscribe(resp => {

      if (resp.length > 0) {
        this.rawLeadsFromThirdParty = resp
        let cnt = 0;


        this.rawLeadsFromThirdParty.forEach(e => {
          console.log(e)
          let notFound: Boolean = true
          let dd = "";
          for (let i = 0; i < 10; i++) {
            if (e.actionDate.toString().charAt(i) == ",")
              dd = dd + "-"
            else
              dd = dd + e.actionDate.toString().charAt(i)
          }
          e.actionDate = dd
          cnt++;
          this.rawLeadsStatus.forEach(f => {
            console.log(f)
            if (e.currentStatus == f.name) {
              f.value++;
              notFound = false
            }
          })
          if (notFound) {
            this.rawLeadsStatus.push({ name: e.currentStatus, value: 1 })
          }



        })
        this.rawLeadsStatus.forEach(f => {
          if (f.name == "All")
            f.value = cnt;
        });
        this.dataSource.data = this.rawLeadsFromThirdParty
        this.rawLeadsFullData = resp

        this.listCurrentStatus=[]
        this.listStatus=[];
        this.listSource=[]
        this.listState=[]
        this.rawLeadsFromThirdParty.forEach(e => {
          let found = false
          for (let i = 0; i < this.listCurrentStatus.length; i++) {
            if (e.currentStatus == this.listCurrentStatus[i])
              found = true
          }
          if (found == false) {

            this.listCurrentStatus.push(e.currentStatus)
          }
        
          found = false
          for (let i = 0; i < this.listStatus.length; i++) {
            if (e.leadAccepted == this.listStatus[i])
              found = true
          }
          if (found == false) {

            this.listStatus.push(e.leadAccepted)
          }
          found = false
          for (let i = 0; i < this.listSource.length; i++) {
            if (e.leadSource == this.listSource[i])
              found = true
          }
          if (found == false) {

            this.listSource.push(e.leadSource)
          }
          found = false
          for (let i = 0; i < this.listState.length; i++) {
            if (e.state == this.listState[i])
              found = true
          }
          if (found == false) {

            this.listState.push(e.state)
          }
        });
        
        this.setPage(1)
      }

    });




  }
  fromDate: string
  setPage(page: number) {

    this.allItems = this.rawLeadsFromThirdParty
    this.pager = this.pagerService.getPager(this.allItems.length, page, 9);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  viewLeadWiseActivityLog(id, name) {
    this.modalRef = this.modalService.show(ViewLeadWiseActivityComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.studentId = id;
    this.modalRef.content.studentName = name;
  }
  filterLeads() {
    this.rawLeadsFromThirdParty = this.rawLeadsFullData;
    // if (this.leadFilter.state != 'All') {
    //   this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.state.includes(this.leadFilter.state));
    // }
    // if (this.leadFilter.city != 'All') {
    //   this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.city.includes(this.leadFilter.city));
    // }
    // if (this.leadFilter.leadSource != 'All') {
    //   this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.leadSource.includes(this.leadFilter.leadSource));
    // }
    // if (this.leadFilter.leadCampaign != 'All') {
    //   this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.leadCampaign.includes(this.leadFilter.leadCampaign));
    // }
    //this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.actionDate >= this.datePipe.transform(this.leadFilter.fromDate, 'dd-MM-yyyy') && rawLeadsFromThirdParty.actionDate <= this.datePipe.transform(this.leadFilter.toDate, 'dd-MM-yyyy'));
    this.dataSource.data = this.rawLeadsFromThirdParty
  }


  openFilterForm() {
    document.getElementById("myFilterForm").style.display = "block";
  }

  closeFilterForm() {
    document.getElementById("myFilterForm").style.display = "none";
  }




  @HostListener('window:load', ['$event'])
  onPageLoad(event: Event) {
    setTimeout(() => {
      document.getElementById("dataArea").style.height = (window.innerHeight - 210) + 'px';
    }, 500);

  }
  ngAfterViewInit() {
    document.getElementById("dataArea").style.height = (window.innerHeight - 232) + 'px';
  }

  leftClick() {
    document.getElementById('pagecontainer').scrollLeft -= 20;
  }
  rightClick() {
    document.getElementById('pagecontainer').scrollLeft += 20;
  }


}

