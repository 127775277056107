// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAdQGhulbQPg14P-Z2P8gUEc5f0DBTUjVI",
    authDomain: "imis-crm.firebaseapp.com",
    projectId: "imis-crm",
    storageBucket: "imis-crm.appspot.com",
    messagingSenderId: "395637297996",
    appId: "1:395637297996:web:3b2d15f4128d08ce31f5e9",
    measurementId: "G-CVQK2NGN82",
    vapidKey:"BA3UZyu8yifFV1Vbs4qY1X7RVlai-AzSA6HiEMRTwakV72PT7BJeaSg3y6OMirso7PlhxzNsvDsaFFhauHgwKnQ"
  },
  serviceWorkerScript: 'src/firebase-messaging-sw.js',
  accountId:1000001,
  serviceRoot: '/api',
  ///Local
  // serviceRoot: 'http://localhost:8080/api',
   //authIp:"http://localhost"
  //IMIS CRM
    //authIp:"https://crm.imis.ac.in"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
