export class TblUser{

  userName:string;
  mobileNo:string;
  emailId:string;
  currentStatus:string;
  tillDate:string;
  password:string
  showMeUpcomingEventsOf:string
  showMeLeadsFromDate:string
  sendEMailFrom:string
  sendSMSFrom:string
  sendWhatsAppFrom:string
  emailPassword:string
  userType:string
  accessSource:string
  refNo:string
}
