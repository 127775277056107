<div class="container-fluid" id="container-wrapper" style="background-color: white;" *ngIf="userType=='Admin'">

    <table style="width: 100%;border: 0;right: 0px;border-bottom: 2px solid grey;">
        <tr>
            <td style="width: calc(100%-50px)">
                <h1 class="h3 mb-0 text-gray-800">Backup</h1>
            </td>

        </tr>
    </table>
    <div style="margin: auto;
    width: 810px;
    top: 140px;
    border-radius: 5px;
    border: 1px solid rgb(31, 32, 31);
    padding: 20px;
    text-align: center; margin-top: 10px;margin-bottom: 10px;" class="row">
        <button mat-raised-button (click)="startBackup()" class="btn-info">Start Backup</button>
        <textarea id="resp" cols="100" rows="20" readonly style="margin-top: 10px;"></textarea>
    </div>
</div>