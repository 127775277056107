<div class="card mb-4">
  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">Add Call Details</h6>
  </div>
  <div class="card-body">
    <form>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">Date</label>
        <div class="col-sm-9">
          <input type="datetime-local" class="form-control" id="inputDate">
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword3" class="col-sm-3 col-form-label">Description</label>
        <div class="col-sm-9">
          <textarea rows="2" class="form-control"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">Call Type</label>
        <div class="col-sm-9">
          <select class="form-control">
            <option>Incoming</option>
            <option>Outgoing</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">Status</label>
        <div class="col-sm-9">
          <select class="form-control">
            <option>A</option>
            <option>B</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-10">
          <button type="button" class="btn btn-primary">Save</button> &nbsp;&nbsp;
          <button type="button" class="btn btn-danger">Close</button>
        </div>
      </div>
    </form>
  </div>
</div>
