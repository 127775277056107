import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblCommunicationSetup } from "../models/communicationsetup";
import { ApiResponse } from "../models/api-response";
import { FCMCommunication } from "../models/fcm-communication";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { SSEActionCall } from "../models/sse-action";
import { TblSMSTemplate } from "../models/sms-template";
import { TblWhatsAppTemplate } from "../models/whatsapp-template";
import { TblEMailTemplate } from "../models/EMail-template";
@Injectable({
  providedIn: "root",
})
export class CommunicationSetupService {
  
  
 
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllCommunicationSetup(): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Communication")
    const endPoint = `${this.serviceRoot}/getAllCommunicationSetup`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

   getCommunicationSetupById(id:number): Observable<TblCommunicationSetup> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Communication By Id")
    const endPoint = `${this.serviceRoot}/getAllCommunicationSetupById/${id}`;
    return this.http.get<TblCommunicationSetup>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateCommunicationSetup(data:TblCommunicationSetup):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Communication")
    const endPoint = `${this.serviceRoot}/saveCommunicationSetup`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  sendOTPByEMailId(email:String,communicationType:string): Observable<ApiResponse> {
    this.localStorage.set("method","Send")
    this.localStorage.set("operation","Send OTP For Password Changed")
    const endPoint = `${this.serviceRoot}/sendOTPByEMailId/${email}/${communicationType}`;
    console.log("End Point=="+endPoint)
    return this.http.get<ApiResponse>(endPoint, this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  updatePassword(userId: number, pwd: string, otp: string) : Observable<ApiResponse> {
    this.localStorage.set("method","Update")
    this.localStorage.set("operation","Update Password")
    const endPoint = `${this.serviceRoot}/updatePassword/${userId}/${pwd}/${otp}`;
    console.log("End Point=="+endPoint)
    return this.http.get<ApiResponse>(endPoint, this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  sendFCMMessageByUserId(data:FCMCommunication): Observable<ApiResponse> {
    this.localStorage.set("method","Send")
    this.localStorage.set("operation","Send Notification Message")
    const endPoint = `${this.serviceRoot}/sendFCMMessageByUserId`;
    console.log("End Point=="+endPoint)
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  sendSSEMessage(data: SSEActionCall): Observable<ApiResponse> {
    this.localStorage.set("method","Call")
    this.localStorage.set("operation","SSE Call Request")
    const endPoint = `${this.serviceRoot}/notifyToDeviceByEMailId`;
    console.log("End Point=="+endPoint)
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  
}
