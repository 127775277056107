import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLeadStatus } from "../models/leadstatus";
import { TblStatusWiseLeads } from "../models/statuswiseleads";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";

@Injectable({
  providedIn: "root",
})
export class LeadStatusService {
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllLeadStatus() {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Status")
    const endPoint = `${this.serviceRoot}/getAllLeadStatus`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  deleteLeadStatus(refNo: string) : Observable<any[]> {
    this.localStorage.set("method","Delete")
    this.localStorage.set("operation","Delete Lead Status")
    const endPoint = `${this.serviceRoot}/deleteLeadStatus/${refNo}`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
   getLeadStatusById(memberId:number): Observable<TblLeadStatus> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Status By Id")
    const endPoint = `${this.serviceRoot}/getLeadStatusById/${memberId}`;
    return this.http.get<TblLeadStatus>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateLeadStatus(data:TblLeadStatus):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Status")
    const endPoint = `${this.serviceRoot}/saveLeadStatus`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getStatusWiseLeadsCount(refNo:string,fromDate:string,toDate:string): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Status Wise Lead Count")
    const endPoint = `${this.serviceRoot}/getStatusWiseLeadsCount/${refNo}/${fromDate}/${toDate}`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

  
}
