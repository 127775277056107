

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { TblLead } from 'src/app/shared/models/leads';
import { LeadService } from 'src/app/shared/services/lead.services';
import { TblCourses } from 'src/app/shared/models/courses';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { TblUser } from 'src/app/shared/models/user';
import { TblLeadStatus } from 'src/app/shared/models/leadstatus';
import { TblLeadSubStatus } from 'src/app/shared/models/leadsubstatus';
import { TblLeadSources } from 'src/app/shared/models/leadsources';
import { TblLeadMedium } from 'src/app/shared/models/leadMedium';
import { TblLeadCampaign } from 'src/app/shared/models/leadcampaign';

import { LocalStorageService } from '../utility/localStorageService';
import * as moment from 'moment';
import { FCMCommunication } from '../shared/models/fcm-communication';
import { CommunicationSetupService } from '../shared/services/communicationsetup.service';
import { LeadFilter } from '../shared/models/lead-filter';
// import { onPhoneInitiated } from 'src/jsFunctions.js'
import { LeadNotes } from '../shared/models/notes';
import { ViewLeadWiseActivityComponent } from '../shared/dialogs/view-lead-wise-activity/view-lead-wise-activity.component';
import { AssignLeadToOtherDialogComponent } from '../shared/dialogs/assign-lead-to-other-dialog/assign-lead-to-other-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { FirstLeadAnalysis } from '../shared/models/first-lead-analysis';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { SmsDialogComponent } from '../shared/dialogs/sms-dialog/sms-dialog.component';
import { SSEActionCall } from '../shared/models/sse-action';

@Component({
  selector: 'app-my-follow-ups',
  templateUrl: './my-follow-ups.component.html',
  styleUrls: ['./my-follow-ups.component.css']
})
export class MyFollowUpsComponent implements OnInit {

  sidePanelMode: boolean = false;
  isEditing: boolean;
  model: TblLead;
  allData: TblLead[];
  leads: TblLead[];
  leadNotes: LeadNotes

  courses: Observable<TblCourses[]>;
  counsellors: Observable<TblUser[]>;
  leadStatus: Observable<TblLeadStatus[]>;
  leadSubStatus: Observable<TblLeadSubStatus[]>;
  leadSourcs: Observable<TblLeadSources[]>;
  leadMedium: Observable<TblLeadMedium[]>;
  leadCampaign: Observable<TblLeadCampaign[]>;
  fCMCommunication: FCMCommunication
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  leadFilter: LeadFilter
  edit: any;
  today: Date
  filteredData;
  androidOs: boolean = false
  currentDate: string
  listStatus: string[]
  listSubStatus: string[]
  listState: string[]
  listCity: string[]

  seqlistState: string[]
  seqlistCity: string[]
  seqlistStatus: string[]
  seqlistSubStatus: string[]
  noOfLPages: number = 5;
  noOfPagesData: number[] = []
  noOfRecPerPage: number = 10;
  status: string = "All";
  leadsFullData: TblLead[];

  sSEActionCall:SSEActionCall;


  dataSource: MatTableDataSource<TblLead>
  dataValue: FirstLeadAnalysis[] = [];

  displayedColumns: string[] = ['Student Details', 'Address Details', 'Lead Status', 'Lead Details', 'Action'];
  displayedColumnsAnd: string[] = ['Student Details', 'Action'];
  pageEvent: PageEvent;
  length: number;
  pageSize: number;
  subStatusWiseLeads: { name: string; value: number; }[];
  constructor(private _leadservices: LeadService,

    private modalService: BsModalService,
    private notifyService: NotificationService,
    private localStorage: LocalStorageService,
    private communicationService: CommunicationSetupService,
    private datePipe: DatePipe
  ) {
    this.dataSource = new MatTableDataSource;
  }

  ngOnInit(): void {
    this.fromDate = this.localStorage.get("showMeLeadsFromDate")
    this.pageSize = 1
    this.length = 2
    this.status = "All"
    this.noOfPagesData = []
    for (let i = 1; i <= this.noOfLPages; i++) {
      this.noOfPagesData.push(i)
    }
    this.currentDate = (moment(new Date())).format('YYYY-MM-DD')

    this.leadFilter = new LeadFilter()
    this.leadFilter.fromDate = (moment(new Date(this.fromDate))).format('YYYY-MM-DD')
    this.leadFilter.toDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadFilter.state = "All"
    this.leadFilter.city = "All"
    this.leadFilter.status = "All"
    this.leadFilter.subStatus = "All"
    this.leadFilter.userRefNo = this.localStorage.get("refNo")

    this.leadNotes = new LeadNotes()


    this.model = new TblLead();

    if (this.localStorage.get("osType") == "Android") {
      this.noOfRecPerPage = 11;
      this.androidOs = true
    }
    else {
      this.noOfRecPerPage = 6;
      this.androidOs = false
    }
    this.subStatusWiseLeads = [{ name: 'All', value: 0 }]
    this.getAllData();
  }
  getLeadsBySubStatus(subStatus: any) {
    this.leads = this.leadsFullData;
    if (subStatus != 'All') {
      this.leads = this.leads.filter((leads) => leads.subStatus==subStatus);
    }
    this.dataSource.data = this.leads
  }

  getAllData() {

    this._leadservices.getAllLeadsByuserId(Number(this.localStorage.get("refNo")), this.fromDate).subscribe(resp => {
      if (resp.length > 0) {
        this.leads = resp
        this.dataSource.data = this.leads;
        let cnt = 0;
        this.leads.forEach(e => {
          let notFound: Boolean = true
          cnt++;
          this.subStatusWiseLeads.forEach(f => {
            if (e.subStatus == f.name) {
              f.value++;
              notFound = false
            }
          })
          if (notFound) {
            this.subStatusWiseLeads.push({ name: e.subStatus, value: 1 })
          }
        })
        this.subStatusWiseLeads.forEach(f => {
          if (f.name == "All")
            f.value = cnt;
        });
        this.dataSource.data = this.leads
        this.leadsFullData = resp
        this.listStatus = []
        this.listSubStatus = []
        this.listCity = []
        this.listState = []
        this.seqlistStatus = []
        this.seqlistSubStatus = []
        this.seqlistState = []
        this.seqlistCity = []
        this.leads.forEach(e => {
          let found = false

          for (let i = 0; i < this.listStatus.length; i++) {
            if (e.status == this.listStatus[i])
              found = true
          }
          if (found == false) {

            this.listStatus.push(e.status)
            this.seqlistStatus.push(e.status)
          }
          found = false
          for (let i = 0; i < this.listSubStatus.length; i++) {
            if (e.subStatus == this.listSubStatus[i])
              found = true
          }

          if (found == false) {
            this.listSubStatus.push(e.subStatus)
            this.seqlistSubStatus.push(e.subStatus)
          }

          found = false
          for (let i = 0; i < this.listCity.length; i++) {
            if (e.cityName == this.listCity[i])
              found = true
          }


          if (found == false) {
            this.listCity.push(e.cityName)
            this.seqlistCity.push(e.cityName)
          }


          found = false
          for (let i = 0; i < this.listState.length; i++) {
            if (e.stateName == this.listState[i])
              found = true
          }


          if (found == false) {
            this.listState.push(e.stateName)
            this.seqlistState.push(e.stateName)
          }
        })
      }

    });




  }
  fromDate: string

  filterLeads() {
    this.leads = this.leadsFullData;
    if (this.leadFilter.state != 'All') {
      this.leads = this.leads.filter((leads) => leads.stateName.includes(this.leadFilter.state));
    }
    if (this.leadFilter.city != 'All') {
      this.leads = this.leads.filter((leads) => leads.cityName.includes(this.leadFilter.city));
    }
    if (this.leadFilter.status != 'All') {
      this.leads = this.leads.filter((leads) => leads.status.includes(this.leadFilter.status));
    }
    if (this.leadFilter.subStatus != 'All') {
      this.leads = this.leads.filter((leads) => leads.subStatus.includes(this.leadFilter.subStatus));
    }

    this.leads = this.leads.filter((leads) => leads.leadDate >= this.datePipe.transform(this.leadFilter.fromDate, 'dd-MM-yyyy') && leads.leadDate <= this.datePipe.transform(this.leadFilter.toDate, 'dd-MM-yyyy'));

    this.dataSource.data = this.leads
  }
  
  viewLeadWiseActivityLog(id, name) {
    this.modalRef = this.modalService.show(ViewLeadWiseActivityComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.studentId = id;
    this.modalRef.content.studentName = name;
  }


  phoneClick(name: string, mobileNo: string) {
    if (this.localStorage.get("osType") == "Android") {
      //onPhoneInitiated(mobileNo);
    } else {
      // this.sSEActionCall=new SSEActionCall();
      // this.sSEActionCall.deviceType="And";
      // this.sSEActionCall.email=this.localStorage.get("email")
      // this.sSEActionCall.message="Call";
      // this.sSEActionCall.refNo=mobileNo;
      // this.communicationService.sendSSEMessage(this.sSEActionCall).subscribe(resp => {
      //   this.notifyService.showSuccess("Phone Call Initiated!", "Success");
      // }, (err) => {
      //   this.notifyService.showError(err, "Error")
      // });
      this.fCMCommunication = new FCMCommunication()
      this.fCMCommunication.userId = this.localStorage.get("refNo")
      this.fCMCommunication.messageType = "Call"
      this.fCMCommunication.messageTitle = "Phone Call to " + name
      this.fCMCommunication.messageBody = mobileNo
      this.communicationService.sendFCMMessageByUserId(this.fCMCommunication).subscribe(resp => {
        if (resp.status == "Success") {
          this.notifyService.showSuccess("Phone Call Initiated!", "Success");
        }
        else {
          this.notifyService.showError(resp.description, "Error")
        }
      }, (err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  smsClick(name: string, mobileNo: string) {
    if (this.localStorage.get("sendSMSFrom") == "Personal") {
      if (this.localStorage.get("osType") == "Android") {
        this.modalRef = this.modalService.show(SmsDialogComponent, { class: 'modal-dialog modal-dialog-centered' });
        this.modalRef.content.mobileNo = name;
        this.modalRef.content.studentName = name;
      } else {
        this.notifyService.showSuccess("It Will Notify", "Call")
      }
    }
    else {
      this.notifyService.showSuccess("It Will Notify", "Call")
    }
  }
  whatsappClick(name: string, mobileNo: string) {
    if (this.localStorage.get("sendSMSFrom") == "Personal") {
      if (this.localStorage.get("osType") == "Android") {
      } else {
        this.notifyService.showSuccess("It Will Notify", "Call")
      }

    }
    else {
      this.notifyService.showSuccess("It Will Notify", "Call")
    }
  }
  emailClick(name: string, emailId: string) {
    if (this.localStorage.get("sendSMSFrom") == "Personal") {
      if (this.localStorage.get("osType") == "Android") {
      } else {
        this.notifyService.showSuccess("It Will Notify", "Call")
      }
    }
    else {
      this.notifyService.showSuccess("It Will Notify", "Call")
    }
  }

  openFilterForm() {
    document.getElementById("myFilterForm").style.display = "block";
  }

  closeFilterForm() {
    document.getElementById("myFilterForm").style.display = "none";
  }

  assignToOtherCounsellor(studentId: number, studentName: string) {
    this.modalRef = this.modalService.show(AssignLeadToOtherDialogComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.studentId = studentId;
    this.modalRef.content.studentName = studentName;
  }

  openNotesForm(refNo: number, name: string) {
    document.getElementById("myNotesForm").style.display = "block";
    document.getElementById("myNotesForm").style.zIndex = "9999";
    this.leadNotes = new LeadNotes()
    this.leadNotes.studentId = refNo
    this.leadNotes.name = name
    this.leadNotes.action = "Call"
    this.leadNotes.onDate = (moment(new Date())).format('YYYY-MM-DD')
  }

  closeNotesForm() {
    document.getElementById("myNotesForm").style.display = "none";
  }
  saveNotes() {
    this._leadservices.saveLeadNotes(this.leadNotes).subscribe(resp => {
      if (resp.refNo > 0) {
        this.notifyService.showSuccess("Note Saved!", "Success");
      }
      else {
        this.notifyService.showError("Something Wrong", "Error")
      }
    }, (err) => {
      this.notifyService.showError(err, "Error")
    });
  }
  @HostListener('window:load', ['$event'])
  onPageLoad(event: Event) {
    setTimeout(() => {
      document.getElementById("dataArea").style.height = (window.innerHeight - 210) + 'px';
    }, 500);

  }
  ngAfterViewInit() {
    document.getElementById("dataArea").style.height = (window.innerHeight - 232) + 'px';
  }

  leftClick() {
    document.getElementById('pagecontainer').scrollLeft -= 20;
  }
  rightClick() {
    document.getElementById('pagecontainer').scrollLeft += 20;
  }


}
