import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { TblUser } from '../../models/user';
import { TblLead } from '../../models/leads';
import { TblleadActivity } from '../../models/lead-activity';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { LeadActivityService } from '../../services/lead-activity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DateFormat } from 'src/app/utility/DateFormat';
import { TblNote } from '../../models/note';
import { LeadNotes } from '../../models/notes';

@Component({
  selector: 'app-view-lead-wise-activity',
  templateUrl: './view-lead-wise-activity.component.html',
  styleUrls: ['./view-lead-wise-activity.component.css']
})
export class ViewLeadWiseActivityComponent implements OnInit {
  notes:LeadNotes[]
  studentName:string;
  studentId:number
  dateFormatPipe:DateFormat
  constructor(public modalRef: BsModalRef,
    private localStorage:LocalStorageService,
    private leadActivityService: LeadActivityService) { }

  ngOnInit(): void {
    this.notes=[]
    
    setTimeout(() => {
      this.leadActivityService.getLeadANotesById(this.studentId)
    .subscribe(
      resp=>{
        this.notes=resp;
      },
      (error: HttpErrorResponse) => {
        
      });
    }, 500);

    
  }

}
