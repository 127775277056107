import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLead } from "../models/leads";
import { UseeActivityStatus } from "../models/useractivitystatus";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { UserLogin } from "../models/user-login";

@Injectable({
  providedIn: "root",
})
export class UserActivityService {
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getUserAllowedOrNotByEmailIdAndPassword(userLogin:UserLogin): Observable<any> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get User Login Details")
    const endPoint = `${this.serviceRoot}/getUserAllowedOrNotByEmailIdAndPassword`;
    return this.http.post<any>(endPoint, userLogin,this.options).pipe(catchError(this.ehandler.errorHandler));

  }
  getCountries() : Observable<any> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Sources")
    const endPoint = `${this.serviceRoot}/getCountries`;
    return this.http.get<any>(endPoint)
      .pipe(
        catchError(this.ehandler.errorHandler)
      );

  }
  getAllLeadSource(): Observable<any> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Sources")
    const endPoint = `${this.serviceRoot}/getAllLeadSources`;
    
    return this.http.get<any>(endPoint)
      .pipe(
        catchError(this.ehandler.errorHandler)
      );
  }
 
}
