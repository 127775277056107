import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TblSMSTemplate } from '../../models/sms-template';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { NotificationService } from 'src/app/notification.service';
// import { sendSMSRequest } from 'src/jsFunctions.js'
import { TemplateService } from '../../services/template-service';

@Component({
  selector: 'app-sms-dialog',
  templateUrl: './sms-dialog.component.html',
  styleUrls: ['./sms-dialog.component.css']
})
export class SmsDialogComponent implements OnInit {
  mobileNo: string
  studentName: string
  sendSMSData: SendSMSData
  tblSMSTemplate: TblSMSTemplate[] = []
  constructor(public modalRef: BsModalRef,
    public templateService: TemplateService,
    public localStorage: LocalStorageService,
    public notification: NotificationService) { }

  ngOnInit(): void {
    this.tblSMSTemplate = []
    this.sendSMSData = new SendSMSData();
    setTimeout(() => {
      this.sendSMSData.toName = this.studentName
      this.sendSMSData.toNumber = this.mobileNo
    }, 500);
    this.getSMSTemplate()
  }
  getSMSTemplate() {
    this.templateService.getSMSTemplate()
      .subscribe(
        resp => {
          this.tblSMSTemplate = resp;
        },
        (error: HttpErrorResponse) => {

        });
  }
  onTemplateSelect(data: any) {
    this.sendSMSData.message = this.tblSMSTemplate.filter((tblSMSTemplate) => tblSMSTemplate.templateId == data)[0].templateDetails;
  }
  sendSMS() {
    if (this.localStorage.get("sendSMSFrom") == "Personal") {
      if (this.localStorage.get("osType") != "Android") {
        this.notification.showError("This service not available as per your configuration and device", "SMS")
      }
      else
      {
        //sendSMSRequest(this.sendSMSData.toNumber ,this.sendSMSData.message);
      }
    }
  }
}
export class SendSMSData {
  message: string
  templateId: string
  toNumber: string
  toName: string
}