import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { DatePipe } from '@angular/common';
import { TblUser } from 'src/app/shared/models/user';
import { UserServices } from 'src/app/shared/services/userservices';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { TblLeadSources } from 'src/app/shared/models/leadsources';
import { LeadSourceService } from 'src/app/shared/services/leadsource.services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild('dt1') dt1: any; 
  sidePanelMode:boolean=false;
  isEditing:boolean;
  model: TblUser;
  users: Observable<TblUser[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate:any
  leadSources: Observable<TblLeadSources[]>;
  
  constructor(private _userservices : UserServices,
    private modalService: BsModalService,
    private spinnerService: AppComponent ,
    private _leadSourceService : LeadSourceService,
    private notifyService : NotificationService) {
  }

  ngOnInit(): void {
    this.model=new TblUser();
    this.getAllData();
    this.startDate=new Date()
    this.getAllLeadSources();
  }
  ngAfterViewInit() {
    //this.dt1.datetimeFormat = {"yyyy-mm-dd"};
  }
  getAllLeadSources()
  {
    this.leadSources= this._leadSourceService.getAllLeadSource();
  }
  getAllData(){
    //this.spinnerService.spinnerStatus(true);
    this.users= this._userservices.getAllUsers();
    //this.spinnerService.spinnerStatus(false);
  }
  sidePanel(id,isEditing){
    this.sidePanelMode=true
    // const initialState = {
    //   isEditing: true ,
    //   user_id:id
    // };
    this.edit=isEditing
    if(isEditing)
    {
      this.boxTitle="Edit User";
      
       this._userservices.getUserByUserId(id)
      .subscribe(
        resp=>{
          this.model=resp;
        },
        (error: HttpErrorResponse) => {
          this.editDataAvbl=false;
        });
      }
    else{
      this.boxTitle="Add New User";
      this.model=new TblUser();
    }




    //this.modalRef = this.modalService.show(ViewEditUsersComponent,{initialState});
  }


  //Editing Data
  validateForm():boolean{
    // if(!this.model.name){
    //   this.notifyService.showError("Name can not be empty!", "Error");
    //   return false;
    // }
   // else 
    if(!this.model.emailId){
      this.notifyService.showError("Email ID can not be empty!", "Error");
      return false;
    }
    else {
      return true;
    }
  }
  deleteClick(id:string)
  {
    
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Delete',  module: 'User', refNo:id};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))


    
  }
  create_edit(){

    if(!this.validateForm())
      {
      return;
    }
    if(this.edit){
           this._userservices.saveAndUpdate(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else{
      this._userservices.saveAndUpdate(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      },(err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked()
  {
    this.sidePanelMode=false
    this.getAllData();
  }
}
