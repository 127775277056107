<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
      <h6 class="font-weight-bold text-primary">Reset Password</h6>
      <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
      <form>
        <div class="form-group row"  *ngIf="!otpsent">
          <label class="col-sm-12" for="inputEmail3">EMail</label>
          <div class="col-sm-12">
            <input type="email" class="form-control" name="email" [(ngModel)]="changePassword.email">
          </div>
        </div>
        <div class="form-group row"  *ngIf="!otpsent">
            <div class="col-sm-12">
              <button class="btn btn-info block" name="inputDate" (click)="sendOTPByEMailId()">Send OTP</button>
            </div>
          </div>
        <div class="form-group row" *ngIf="otpsent">
          <label for="inputPassword3" class="col-sm-12">OTP</label>
          <div class="col-sm-12">
            <input type="text" class="form-control" name="otp" [(ngModel)]="changePassword.otp">
          </div>
        </div>
        <div class="form-group row" *ngIf="otpsent">
            <label class="col-sm-12">New Password</label>
            <div class="col-sm-12">
              <input type="password" class="form-control" name="newPassword" [(ngModel)]="changePassword.newPassword">
            </div>
          </div>
          <div class="form-group row" *ngIf="otpsent">
            <label class="col-sm-12">Password Again</label>
            <div class="col-sm-12">
              <input type="password" class="form-control" name="passwordAgain" [(ngModel)]="changePassword.passwordAgain">
            </div>
          </div>
        
        
      </form>
    </div>
    <div class="card-footer" *ngIf="otpsent">
        <button type="button" class="btn btn-primary block" (click)="updatePassword()">Update Password</button>
    </div>
  </div>
  