import { environment } from "src/environments/environment";
import { CommonHeader } from "./common-header";
import { HttpClient } from "@angular/common/http";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { TblUserWiseTransaction } from "../models/userwisetransactions";

@Injectable({
    providedIn: "root",
})
export class CommonService {

    private serviceRoot = `${environment.serviceRoot}`;
    commonHeader: CommonHeader = new CommonHeader()
    private options = this.commonHeader.options;
    constructor(
        private http: HttpClient,
        private ehandler: ExceptionHandler,
        private localStorage: LocalStorageService
    ) { }

    getUserWiseTransactionByUserIDAndDate(id: number, fromDate: string, toDate: string): Observable<TblUserWiseTransaction[]> {
        this.localStorage.set("method", "Read")
        this.localStorage.set("operation", "User Wise Transaction")
        const endPoint = `${this.serviceRoot}/getUserWiseTransactionByUserIDAndDate/${id}/${fromDate}/${toDate}`;
        return this.http.get<TblUserWiseTransaction[]>(endPoint, this.options)
            .pipe(catchError(this.ehandler.errorHandler));
    }
    startBackup(): Observable<Blob> {
        this.localStorage.set("method", "Backup")
        this.localStorage.set("operation", "Backup Transaction")
        const endPoint = `${this.serviceRoot}/startBackup`;
        return this.http.get(endPoint, { responseType: 'blob' })
            .pipe(catchError(this.ehandler.errorHandler));
    }
    restoreFromBackup(selectedFile:File,pwd:string): Observable<any> {
        if (!selectedFile) {
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        this.localStorage.set("method", "Backup")
        this.localStorage.set("operation", "Backup Transaction")
        const endPoint = `${this.serviceRoot}/restoreFromBackup/${pwd}`;

        this.http.post(endPoint, formData).subscribe(
            response => console.log('File uploaded successfully:', response),
            error => console.error('File upload failed:', error)
        );
    }
}