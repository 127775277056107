import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { TblLead } from 'src/app/shared/models/leads';
import { LeadService } from 'src/app/shared/services/lead.services';
import { TblCourses } from 'src/app/shared/models/courses';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { TblUser } from 'src/app/shared/models/user';
import { TblLeadStatus } from 'src/app/shared/models/leadstatus';
import { TblLeadSubStatus } from 'src/app/shared/models/leadsubstatus';
import { TblLeadSources } from 'src/app/shared/models/leadsources';
import { TblLeadMedium } from 'src/app/shared/models/leadMedium';
import { TblLeadCampaign } from 'src/app/shared/models/leadcampaign';
import { UserServices } from 'src/app/shared/services/userservices';
import { LeadStatusService } from 'src/app/shared/services/leadStatus.services';
import { LeadSubStatusService } from 'src/app/shared/services/leadSubStatusService';
import { LeadSourceService } from 'src/app/shared/services/leadsource.services';
import { LeadMediumService } from 'src/app/shared/services/leadMedium.service';
import { LeadCampaignService } from 'src/app/shared/services/leadcampaign.services';
import { LocalStorageService } from '../utility/localStorageService';
import { TblStatusWiseLeads } from '../shared/models/statuswiseleads';
import * as moment from 'moment';
import { LeadsDialogComponent } from '../shared/dialogs/leads-dialog/leads-dialog.component';
import { FCMCommunication } from '../shared/models/fcm-communication';
import { CommunicationSetupService } from '../shared/services/communicationsetup.service';
import { DateFormat } from '../utility/DateFormat';
import { LeadFilter } from '../shared/models/lead-filter';
import { LeadNotes } from '../shared/models/notes';
import { ViewLeadWiseActivityComponent } from '../shared/dialogs/view-lead-wise-activity/view-lead-wise-activity.component';
import { AssignLeadToOtherDialogComponent } from '../shared/dialogs/assign-lead-to-other-dialog/assign-lead-to-other-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { FirstLeadAnalysis } from '../shared/models/first-lead-analysis';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { SmsDialogComponent } from '../shared/dialogs/sms-dialog/sms-dialog.component';
import { SSEActionCall } from '../shared/models/sse-action';
import { EmailDialogComponent } from '../shared/dialogs/email-dialog/email-dialog.component';
import { WhatappDialogComponent } from '../shared/dialogs/whatapp-dialog/whatapp-dialog.component';
import { PagerService } from '../shared/pagination';
import { LeadFollowUps } from '../shared/models/LeadFollowUps';
import { FCMCommunicationService } from '../shared/services/FCMCommunicationService';
import { onPhoneInitiated } from 'src/jsFunctions';

@Component({
  selector: 'app-failed-follow-ups',
  templateUrl: './failed-follow-ups.component.html',
  styleUrls: ['./failed-follow-ups.component.css']
})
export class FailedFollowUpsComponent implements OnInit {

  sidePanelMode: boolean = false;
  isEditing: boolean;
  model: TblLead;
  allData: TblLead[];
  leadsFollowUps: LeadFollowUps[];
  leadNotes: LeadNotes

  courses: Observable<TblCourses[]>;
  counsellors: TblUser[];
  leadStatus: TblLeadStatus[];
  leadSubStatus: Observable<TblLeadSubStatus[]>;
  leadSourcs: Observable<TblLeadSources[]>;
  leadMedium: Observable<TblLeadMedium[]>;
  //channelName:Observable<Tblc[]>;
  leadCampaign: Observable<TblLeadCampaign[]>;
  fCMCommunication: FCMCommunication
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  leadFilter: LeadFilter
  edit: any;
  today: Date
  filteredData;
  androidOs: boolean = false
  currentDate: string
  listStatus: string[]
  listSubStatus: string[]
  listState: string[]
  listCity: string[]
  listCounsellor: string[]
  listSource: string[];


  noOfLPages: number = 5;
  noOfPagesData: number[] = []
  noOfRecPerPage: number = 10;
  status: string = "All";
  leadsFullData: LeadFollowUps[];

  sSEActionCall: SSEActionCall;



  dataValue: FirstLeadAnalysis[] = [];

  displayedColumns: string[] = ['Student Details', 'Address Details', 'Lead Status', 'Lead Details', 'Action'];
  displayedColumnsAnd: string[] = ['Student Details', 'Action'];
  pageEvent: PageEvent;
  length: number;
  pageSize: number;
  statusWiseLeads: { name: string; value: number; }[];
  pager: any = {};
  private allItems: any[];
  // paged items
  pagedItems: any[];
  fromDate: string
  toDate:string
  userType: string;
 
  constructor(private _leadservices: LeadService,
    private coursesService: CoursesService,
    private counsellorService: UserServices,
    private leadStatusService: LeadStatusService,
    private leadSubStatusService: LeadSubStatusService,
    private leadSourceService: LeadSourceService,
    private leadMediumService: LeadMediumService,
    private leadCampaignService: LeadCampaignService,
    private modalService: BsModalService,
    private spinnerService: AppComponent,
    private notifyService: NotificationService,
    private localStorage: LocalStorageService,
    private communicationService: FCMCommunicationService,
    private datePipe: DatePipe,
    private pagerService: PagerService,
    private communicationService2: CommunicationSetupService
  ) {
  }
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.leadStatusService.getAllLeadStatus().subscribe(resp => {
      this.leadStatus = resp;
      //this.counsellors = this.counsellors.filter((fl) => fl.userType==="Counsellor");
    });
    
    this.fromDate = this.localStorage.get("showMeLeadsFromDate")
    this.toDate = (moment(this.addDays(new Date(),-1))).format('YYYY-MM-DD')
    
    this.pageSize = 1
    this.length = 2
    this.status = "All"
    this.noOfPagesData = []
    for (let i = 1; i <= this.noOfLPages; i++) {
      this.noOfPagesData.push(i)
    }
    this.currentDate = (moment(new Date())).format('YYYY-MM-DD')

    this.leadFilter = new LeadFilter()
    this.leadFilter.fromDate = (moment(new Date(this.fromDate))).format('YYYY-MM-DD')
    this.leadFilter.toDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadFilter.state = "All"
    this.leadFilter.city = "All"
    this.leadFilter.status = "All"
    this.leadFilter.subStatus = "All"
    this.leadFilter.assignedTo = "All"
    this.leadFilter.leadSource = "All"

    this.leadFilter.userRefNo = this.localStorage.get("refNo")

    this.leadNotes = new LeadNotes()


    this.model = new TblLead();

    if (this.localStorage.get("osType") == "Android") {
      this.noOfRecPerPage = 8;
      this.androidOs = true
    }
    else {
      this.noOfRecPerPage = 5;
      this.androidOs = false
    }
    this.statusWiseLeads = [{ name: 'All', value: 0 }]
    this.getAllData();
  }
  getLeadsByStatus(status: any) {
    this.leadsFollowUps = this.leadsFullData;
    if (status != 'All') {
      this.leadsFollowUps = this.leadsFollowUps.filter((leads) => leads.status == status);
    }
    this.setPage(1)
  }

  getAllData() {
    let refNo:number=Number(this.localStorage.get("refNo"));
    this._leadservices.getAllLeadsWithFollowUpsByuserId(refNo, this.fromDate,this.toDate).subscribe(resp => {
      if (resp.length > 0) {
        this.leadsFollowUps = resp
        console.log(resp)
        let cnt = 0;
        this.leadsFollowUps.forEach(e => {
          let notFound: Boolean = true
          cnt++;
          this.statusWiseLeads.forEach(f => {
            if (e.status == f.name) {
              f.value++;
              notFound = false
            }
          })
          if (notFound) {
            this.statusWiseLeads.push({ name: e.status, value: 1 })
          }
        })
        this.statusWiseLeads.forEach(f => {
          if (f.name == "All")
            f.value = cnt;
        });
        this.leadsFullData = resp
        this.listStatus = []
        this.listSubStatus = []
        this.listCity = []
        this.listState = []
        this.listSource = []
        this.listCounsellor = []

        this.leadsFollowUps.forEach(e => {
          let found = false
          for (let i = 0; i < this.listStatus.length; i++) {
            if (e.status == this.listStatus[i]) {
              found = true
            }

          }
          if (found == false) {
            this.listStatus.push(e.status)
          }
          found = false
          for (let i = 0; i < this.listSubStatus.length; i++) {
            if (e.subStatus == this.listSubStatus[i])
              found = true
          }

          if (found == false) {
            this.listSubStatus.push(e.subStatus)
          }
          found = false
          for (let i = 0; i < this.listState.length; i++) {
            if (e.stateName == this.listState[i])
              found = true
          }
          if (found == false) {
            this.listState.push(e.stateName)
          }
          found = false
          for (let i = 0; i < this.listCity.length; i++) {
            if (e.cityName == this.listCity[i])
              found = true
          }
          if (found == false) {
            this.listCity.push(e.cityName)
          }
          found = false
          for (let i = 0; i < this.listSource.length; i++) {
            if (e.source == this.listSource[i])
              found = true
          }
          if (found == false) {
            this.listSource.push(e.source)
          }
          found == false
          for (let i = 0; i < this.listCounsellor.length; i++) {
            if (e.assignToCounsellor == this.listCounsellor[i])
              found = true
          }
          if (found == false) {
            this.listCounsellor.push(e.assignToCounsellor)
          }
        })
        this.setPage(1);
      }

    });




  }
  
  setPage(page: number) {

    this.allItems = this.leadsFollowUps
    this.pager = this.pagerService.getPager(this.allItems.length, page, this.noOfRecPerPage);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);


  }

  filterLeads() {
    this.leadsFollowUps = this.leadsFullData;
    console.log(this.leadsFollowUps)
    if (this.leadFilter.state != 'All') {
      this.leadsFollowUps = this.leadsFollowUps.filter((ld) => ld.stateName.includes(this.leadFilter.state));
    }
    if (this.leadFilter.city != 'All') {
      this.leadsFollowUps = this.leadsFollowUps.filter((ld) => ld.cityName.includes(this.leadFilter.city));
    }
    if (this.leadFilter.status != 'All') {
      this.leadsFollowUps = this.leadsFollowUps.filter((ld) => ld.status.includes(this.leadFilter.status));
    }
    if (this.leadFilter.subStatus != 'All') {
      this.leadsFollowUps = this.leadsFollowUps.filter((ld) => ld.subStatus.includes(this.leadFilter.subStatus));
    }
    if (this.leadFilter.assignedTo != 'All') {
      this.leadsFollowUps = this.leadsFollowUps.filter((ld) => ld.assignToCounsellor === this.leadFilter.assignedTo);
    }
    if (this.leadFilter.leadSource != 'All') {
      this.leadsFollowUps = this.leadsFollowUps.filter(ld => ld.source.includes(this.leadFilter.leadSource));
    }
    let dataFilt: LeadFollowUps[] = []
    this.leadsFollowUps.forEach(e => {
      if (e.nextFolloupDate.split("-")[0] + e.nextFolloupDate.split("-")[1] + e.nextFolloupDate.split("-")[2] >= this.leadFilter.fromDate.split("-")[0] + this.leadFilter.fromDate.split("-")[1] + this.leadFilter.fromDate.split("-")[2] &&
        e.nextFolloupDate.split("-")[0] + e.nextFolloupDate.split("-")[1] + e.nextFolloupDate.split("-")[2] <= this.leadFilter.toDate.split("-")[0] + this.leadFilter.toDate.split("-")[1] + this.leadFilter.toDate.split("-")[2]) {
        dataFilt.push(e)
      }
    });
    this.leadsFollowUps = dataFilt
    this.setPage(1)
  }
  editLeadsClicked(id, isEditing) {
    this.modalRef = this.modalService.show(LeadsDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });
    this.modalRef.content.refNo = id;

  }
  newLead(id, isEditing) {
    this.modalRef = this.modalService.show(LeadsDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-lg' });
  }
  viewLeadWiseActivityLog(id, name) {
    this.modalRef = this.modalService.show(ViewLeadWiseActivityComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.studentId = id;
    this.modalRef.content.studentName = name;
  }


  phoneClick(name: string, mobileNo: string) {
     if (this.localStorage.get("osType") == "Android") {
        
          onPhoneInitiated(mobileNo)
        }
        else {
    
          this.notifyService.showInfo("Please stay in the dashboard of your mobile", "Please Note")
          this.communicationService2.callPhone(mobileNo).subscribe(resp => {
            this.notifyService.showSuccess("Phone Call Initiated!", "Success");
          }, (err) => {
            this.notifyService.showError(err, "Error")
          });
        }
  }
  smsClick(name: string, mobileNo: string) {

    this.modalRef = this.modalService.show(SmsDialogComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.mobileNo = mobileNo;
    this.modalRef.content.studentName = name;

  }
  whatsappClick(name: string, mobileNo: string) {
    this.modalRef = this.modalService.show(WhatappDialogComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.mobileNo = mobileNo;
    this.modalRef.content.studentName = name;
  }
  emailClick(name: string, emailId: string) {

    this.modalRef = this.modalService.show(EmailDialogComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.eMail = emailId;
    this.modalRef.content.studentName = name;
  }

  openFilterForm() {
    document.getElementById("myFilterForm").style.display = "block";
  }

  closeFilterForm() {
    document.getElementById("myFilterForm").style.display = "none";
  }

  assignToOtherCounsellor(studentId: number, studentName: string) {
    this.modalRef = this.modalService.show(AssignLeadToOtherDialogComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.studentId = studentId;
    this.modalRef.content.studentName = studentName;
  }

  openNotesForm(refNo: number, name: string, status: string) {
    document.getElementById("myNotesForm").style.display = "block";
    document.getElementById("myNotesForm").style.zIndex = "9999";
    this.leadNotes = new LeadNotes()
    this.leadNotes.studentId = refNo
    this.leadNotes.name = name
    this.leadNotes.action = "Call"
    this.leadNotes.onDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadNotes.nextActionDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadNotes.scheduleTime = (moment(new Date())).format('hh:mm')
    this.leadNotes.status = status
  }

  closeNotesForm() {
    document.getElementById("myNotesForm").style.display = "none";
  }
  saveNotes() {
    if(this.leadNotes.note.length<2)
    {
      this.notifyService.showError("Please Enter Note","Error")
      return;
    }
    this.leadNotes.counsellorId = this.localStorage.get("byUser")
    this._leadservices.saveLeadNotes(this.leadNotes).subscribe(resp => {
      if (resp.refNo > 0) {
        this.notifyService.showSuccess("Note Saved!", "Success");
        window.location.reload()
      }
      else {
        this.notifyService.showError("Something Wrong", "Error")
      }
    }, (err) => {
      this.notifyService.showError(err, "Error")
    });
    this.closeNotesForm();
  }
  @HostListener('window:load', ['$event'])
  onPageLoad(event: Event) {
    setTimeout(() => {
      //document.getElementById("dataArea").style.height = (window.innerHeight - 210) + 'px';
    }, 500);

  }
  ngAfterViewInit() {
    //document.getElementById("dataArea").style.height = (window.innerHeight - 232) + 'px';
  }

  leftClick() {
    document.getElementById('pagecontainer').scrollLeft -= 20;
  }
  rightClick() {
    document.getElementById('pagecontainer').scrollLeft += 20;
  }
  stopPropagation(event){
    event.stopPropagation();
    }
  searchTable() {
    var term, table;
    term=document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table =document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) ==
            -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
}

}
