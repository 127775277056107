import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { ProfileDialogComponent } from '../dialogs/profile-dialog/profile-dialog.component';
import { SettingsDialogComponent } from '../dialogs/settings-dialog/settings-dialog.component';
import { ActivityLogDialogComponent } from '../dialogs/activity-log-dialog/activity-log-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],

})
export class TopBarComponent implements OnInit {
  userName: string
  androidOS: boolean = false
  modalRef: BsModalRef;
  showSubmenu: boolean = false
  panelOpenState = false;
  showTopBar: boolean;
  inLoginPage: boolean;
  notInLogin: boolean;
  constructor(private localStorage: LocalStorageService,
    private router: Router,
    private modalService: BsModalService

  ) { }

  ngOnInit(): void {
    if (this.localStorage.get("osType") == "Android") {
      this.androidOS = true
    }
    else {
      this.androidOS = false
    }

    this.userName = this.localStorage.get("userName")

   
  }

  changeOfRoutes() {
    console.log(" I am here 3")
    if (window.location.href.includes("/login")) {
      this.showTopBar = false
      this.inLoginPage = true
      this.showTopBar = false

    }
    else {
      this.inLoginPage = false
      this.showTopBar = true
      this.showTopBar = true
    }
    if (this.localStorage.get("osType") == "Android")
      this.androidOS = true
    else
      this.androidOS = false
    if (this.localStorage.get("userName") == "null" || this.localStorage.get("refNo") == "null") {
      this.showTopBar = false
      this.router.navigateByUrl("login")
    }
  }
  Profile() {
    this.modalRef = this.modalService.show(ProfileDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });
  }
  logOutClick()
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Logout',  module: 'Logout', refNo:'0'};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
    
  }
  
  settingsClick() {
    this.modalRef = this.modalService.show(SettingsDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });

  }
  activityLog() {
    this.modalRef = this.modalService.show(ActivityLogDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-lg' });
  }

  openFilterForm() {
    document.getElementById("myFilterForm").style.display = "block";
  }

  closeFilterForm() {
    document.getElementById("myFilterForm").style.display = "none";
  }
}
