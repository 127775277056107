import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLeadSubStatus } from "../models/leadsubstatus";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";

@Injectable({
  providedIn: "root",
})
export class LeadSubStatusService {
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllLeadSubStatus(): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Sub Status")
    const endPoint = `${this.serviceRoot}/getLeadStatusAndSubStatus`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

   getLeadSubStatusById(memberId:number): Observable<TblLeadSubStatus> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Sub Status By Id")
    const endPoint = `${this.serviceRoot}/getLeadSubStatusById/${memberId}`;
    return this.http.get<TblLeadSubStatus>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateLeadSubStatus(data:TblLeadSubStatus):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Sub Status")
    const endPoint = `${this.serviceRoot}/saveLeadSubStatus`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint,data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  deleteLeadSubStatusById(memberId:any): Observable<TblLeadSubStatus> {
    this.localStorage.set("method","Delete")
    this.localStorage.set("operation","Delete Lead Sub Status By Id")
    const endPoint = `${this.serviceRoot}/deleteLeadSubStatusById/${memberId}`;
    return this.http.get<TblLeadSubStatus>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
}
