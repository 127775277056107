export class TblLeadConditions
{
    refNo: number;
    conditionName: string;
    assignLead:string;
    leadsAtATime:string;
    leadSourceId:string;
    userId:string
    userName:string
    source:string
}