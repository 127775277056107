<div class="container-fluid" id="container-wrapper">
    <div class="row" style="border-bottom: 2px solid grey;padding-bottom: 3px;">
        <h4 style="text-align: center;">Dashboard</h4>
    </div>
    <div class="row" style="padding-top: 10px;">
        <a style="cursor: pointer;display: inline-flex;font-size: 14px;width: 100%;" (click)="openRangeForm()">
            <input id="inputdefault" placeholder="Date Range" type="text" [value]="selectedDate" style=" position: relative;
                color: #aaa;width: 100%;" readonly>
            <i class="fa fa-calendar fa-lg" style="position: fixed;margin-top: 5px;right: 15px;"></i>
        </a>
    </div>


    <div class="row" style="border: 1px solid rgb(186, 185, 243);margin-top: 10px!important;padding: 5px;">
        <div class="chart-area " style="width: 100%!important;">
            <canvasjs-chart [options]="leadCountBySource" [styles]="{width: '100%', height:'100%'}"></canvasjs-chart>
        </div>
    </div>
    <div class="row" style="border: 1px solid rgb(186, 185, 243);margin-top: 10px!important;padding: 5px;">
        <div class="chart-area " style="width: 100%!important;">
            <canvasjs-chart [options]="leadCountByStatus" [styles]="{width: '100%', height:'100%'}"></canvasjs-chart>
        </div>
    </div>
    <h4><u>Lead Status</u></h4>
    <div class="table-responsive">
        <table class="table table-fixed">
            <thead>
                <tr>
                    <th scope="col" class="col-3">#</th>
                    <th scope="col" class="col-3">First</th>
                    <th scope="col" class="col-3">Last</th>
                    <th scope="col" class="col-3">Handle</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row" class="col-3">1</th>
                    <td class="col-3">Mark</td>
                    <td class="col-3">Otto</td>
                    <td class="col-3">@mdo</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">2</th>
                    <td class="col-3">Jacob</td>
                    <td class="col-3">Thornton</td>
                    <td class="col-3">@fat</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">3</th>
                    <td colspan="2" class="col-6">Larry the Bird</td>
                    <td class="col-3">@twitter</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">4</th>
                    <td class="col-3">Martin</td>
                    <td class="col-3">Williams</td>
                    <td class="col-3">@Marty</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">5</th>
                    <td colspan="2" class="col-3">Sam</td>
                    <td colspan="2" class="col-3">Pascal</td>
                    <td class="col-3">@sam</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">6</th>
                    <td class="col-3">John</td>
                    <td class="col-3">Green</td>
                    <td class="col-3">@john</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">7</th>
                    <td colspan="2" class="col-3">David</td>
                    <td colspan="2" class="col-3">Bowie</td>
                    <td class="col-3">@david</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">8</th>
                    <td class="col-3">Pedro</td>
                    <td class="col-3">Rodriguez</td>
                    <td class="col-3">@rod</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">5</th>
                    <td colspan="2" class="col-3">Sam</td>
                    <td colspan="2" class="col-3">Pascal</td>
                    <td class="col-3">@sam</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">10</th>
                    <td class="col-3">Jacob</td>
                    <td class="col-3">Thornton</td>
                    <td class="col-3">@fat</td>
                </tr>
                <tr>
                    <th scope="row" class="col-3">11</th>
                    <td colspan="2" class="col-6">Larry the Bird</td>
                    <td class="col-3">@twitter</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div id="dateRangeForm" class="form-popup-popup" style="z-index: 9999999!important;width: 100%;top:60px ">
    <div class="card-header d-flex flex-row align-items-center justify-content-between ">
        <h6 class="font-weight-bold text-primary ">Lead Filter</h6>
        <button type="button " class="btn btn-danger " style="float: right; " (click)="closeRangeForm() ">X</button>
    </div>
    <div class="card-body ">
        <div class="row">
            <div class="col">
                <label class="radio-container">Creation Date
                    <input type="checkbox" name="rangeType" id="creationDate"  checked="checked"  (click)="rangeTypeClicked(1)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>
            <div class="col">
                <label class="radio-container">Modification Date
                    <input type="checkbox" name="rangeType" id="notificationDate" (click)="rangeTypeClicked(2)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="radio-container">Next Action Date
                    <input type="checkbox" name="rangeType" id="nextActionDate" (click)="rangeTypeClicked(3)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>
            <div class="col">
                <label class="radio-container">Re Enquire Date
                    <input type="checkbox" name="rangeType" id="reEnquireDate" (click)="rangeTypeClicked(4)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>

        </div>
        <div class="row">

            <button mat-button *ngFor="let preset of presets " (click)="selectPreset(preset.range) " class="col-sm-3 col-3 m-auto btn-default" style="text-align: left;font-size: smaller;border: 1px solid rgb(180, 160, 226);">
              {{ preset.label }}
            </button>

        </div>
        <div class="wrapper">
            <mat-card class="calendar ">
                <mat-calendar #calendar [(selected)]="selectedDateRange" (selectedChange)="rangeChanged($event)" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
            </mat-card>
        </div>
    </div>
    <div class="card-footer" style="float: right;padding-right: 30px;">

        <button type="button" class="btn btn-danger block">Reset</button>
        <button type="button" class="btn btn-info block">Apply</button>
    </div>
</div>