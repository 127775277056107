import { Injectable } from '@angular/core';
declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class FileSystemService {

  constructor() { }

  

  async selectFolder(): Promise<FileSystemDirectoryHandle | null> {
    try {
      const directoryHandle = await window.showDirectoryPicker();
      return directoryHandle;
    } catch (error) {
      console.error('Error selecting folder:', error);
      return null;
    }
  }
}