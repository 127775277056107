import { environment } from "src/environments/environment";
import { CommonHeader } from "./common-header";
import { HttpClient } from "@angular/common/http";
import { ExceptionHandler } from "./errorhandler";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { Injectable } from "@angular/core";
import { TblLeadConditions } from "../models/LeadConditions";
import { catchError, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
  })
  export class LeadConditionsService
  {

  deleteLeadCondition(refNo: string): Observable<TblLeadConditions> {
    this.localStorage.set("method","Delete")
    this.localStorage.set("operation","Delete Lead Source")
    const endPoint = `${this.serviceRoot}/deleteLeadCondition/${refNo}`;
    return this.http.get<TblLeadConditions>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
    private serviceRoot = `${environment.serviceRoot}`;
    commonHeader:CommonHeader=new CommonHeader() 
    private options=this.commonHeader.options;
    constructor(
      private http: HttpClient,
      private ehandler:ExceptionHandler,
      private localStorage:LocalStorageService
    ) { }
    getAllLeadConditions(): Observable<TblLeadConditions[]> {
        this.localStorage.set("method","Read")
        this.localStorage.set("operation","Get All Lead Conditions")
        const endPoint = `${this.serviceRoot}/getAllLeadConditions`;
        return this.http.get<TblLeadConditions[]>(endPoint, this.options)
        .pipe(catchError(this.ehandler.errorHandler));
      }
    
      getAllLeadConditionsById(memberId:number): Observable<TblLeadConditions> {
        this.localStorage.set("method","Read")
        this.localStorage.set("operation","Get Lead Conditions By Id")
        const endPoint = `${this.serviceRoot}/getAllLeadConditionsById/${memberId}`;
        return this.http.get<TblLeadConditions>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
      }
      saveAllLeadConditions(data:TblLeadConditions):Observable<any>{
        this.localStorage.set("method","Save/Update")
        this.localStorage.set("operation","Save/Update Lead Conditions")
        const endPoint = `${this.serviceRoot}/saveAllLeadConditions`;
        console.log("Data="+JSON.stringify(data))
        return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
      }
  }