import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { NotificationService } from 'src/app/notification.service';
import { TblEMailTemplate } from '../../models/EMail-template';
import { TemplateService } from '../../services/template-service';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.css']
})
export class EmailDialogComponent implements OnInit {
  selectedFile: any = null;

  eMail: string
  studentName: string
  sendEMail: SendEMail
  tblEMailTemplate: TblEMailTemplate[] = []
  srcResult: any;
  constructor(public modalRef: BsModalRef,
    public templateService: TemplateService,
    public localStorage: LocalStorageService,
    public notification: NotificationService) { }

  ngOnInit(): void {
    this.tblEMailTemplate = []
    this.sendEMail = new SendEMail();
    setTimeout(() => {
      this.sendEMail.toName = this.studentName
      this.sendEMail.eMail = this.eMail
    }, 500);
    this.getEMailTemplate()
  }
  getEMailTemplate() {
    this.templateService.getEMailTemplate()
      .subscribe(
        resp => {
          this.tblEMailTemplate = resp;
        },
        (error: HttpErrorResponse) => {

        });
  }
  onTemplateSelect(data: any) {
    this.sendEMail.message = this.tblEMailTemplate.filter((tblEMailTemplate) => tblEMailTemplate.templateId == data)[0].templateDetails;
    this.sendEMail.fileAllowed = this.tblEMailTemplate.filter((tblEMailTemplate) => tblEMailTemplate.templateId == data)[0].fileAllowed;
    this.sendEMail.subject = this.tblEMailTemplate.filter((tblEMailTemplate) => tblEMailTemplate.templateId == data)[0].subject;

  }
  sendEMailMessage() {
    if (this.localStorage.get("sendEMailFrom") == "Personal") {
    
       // sendEMailRequest(this.sendEMail.eMail ,this.sendEMail.message);
     
    }
  }
  onFileSelected(event: any): void {
  {
    // const inputNode: any = document.querySelector('#file');

    // if (typeof (FileReader) !== 'undefined') {
    //   const reader = new FileReader();
  
    //   reader.onload = (e: any) => {
    //     this.srcResult = e.target.result;
    //   };
  
    //   reader.readAsArrayBuffer(inputNode.files[0]);
    this.selectedFile = event.target.files[0] ?? null;
    }
  }
}
export class SendEMail {
  message: string
  templateId: string
  eMail: string
  toName: string
  subject:string
  fileAllowed:string
}