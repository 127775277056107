<div class="container-fluid" id="container-wrapper">
    <div class="row" style="border-bottom: 2px solid grey;padding-bottom: 3px;">
        <h4 style="text-align: center;">User Activity</h4>
    </div>
    <form [formGroup]="loginForm">
        <div class="row" style="padding-top: 10px;">

            <div class="col-md-2 col-sm-6 col-6">
                <div class="contain_card">
                    <mat-form-field>
                        <input style="cursor: pointer;" matInput [matDatepicker]="fromDateView" placeholder="From Date" (click)="fromDateView.open()" formControlName="fromDate" readonly required>
                        <mat-datepicker-toggle matSuffix [for]="fromDateView"></mat-datepicker-toggle>
                        <mat-datepicker #fromDateView disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-md-2 col-sm-6 col-6">
                <div class="contain_card">

                    <mat-form-field>
                        <input style="cursor: pointer;" matInput [matDatepicker]="toDateView" placeholder="To Date" (click)="toDateView.open()" formControlName="toDate" readonly required>
                        <mat-datepicker-toggle matSuffix [for]="toDateView"></mat-datepicker-toggle>
                        <mat-datepicker #toDateView disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 col-sm-12 col-12">

                <input type="button" class="block btn-info" value="View" style="width: 100%;" (click)="viewClick()">

            </div>
        </div>
    </form>
    <div class="table-responsive">
        <table class="table table-fixed">
            <thead>
                <tr>
                    <th scope="col" class="col-3">On Date</th>
                    <th scope="col" class="col-2">Action</th>
                    <th scope="col" class="col-5">Details</th>
                    <th scope="col" class="col-2">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of TblUserActivity;i as index">
                    <td class="col-3">{{data.requestDateTime}}</td>
                    <td class="col-2">{{data.method}}</td>
                    <td class="col-5">{{data.operation}}</td>
                    <td class="col-2">{{data.status}}</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>