<div class="container-fluid" id="container-wrapper" style="background-color: white;padding: 5px;" *ngIf="userType=='Admin' || userType=='Counsellor'">



    <table style="width: 100%;border-bottom: 2px solid grey;">
        <tr>
            <td style="font-size: 20px;">
                Bulk Re-Assign Leads
            </td>
        </tr>
    </table>

    <div class="form-group row" style="margin-bottom: 5px;">
        <mat-form-field class="col-sm-3">
            <input matInput type="date" name="fromDate" [(ngModel)]="leadFilter.fromDate" placeholder="From Date" [min]="fromDate">
        </mat-form-field>
        <mat-form-field class="col-sm-3">
            <input matInput type="date" name="toDate" [(ngModel)]="leadFilter.toDate" placeholder="To Date" [min]="fromDate">
        </mat-form-field>

        <mat-form-field class="col-sm-3">
            <mat-select class="field-full-width" placeholder="Lead Status" [(ngModel)]="leadFilter.status">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let stat of listStatus" [value]="stat">{{stat}}</mat-option>
            </mat-select>

        </mat-form-field>
        <mat-form-field class="col-sm-3">

            <mat-select class="field-full-width" placeholder="Lead Sub-Status" [(ngModel)]="leadFilter.subStatus">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let stat of listSubStatus" [value]="stat">{{stat}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="col-sm-3">
            <mat-select class="field-full-width" placeholder="State" [(ngModel)]="leadFilter.state">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let stat of listState" [value]="stat">{{stat}}</mat-option>
            </mat-select>

        </mat-form-field>
        <mat-form-field class="col-sm-3">
            <mat-select class="field-full-width" placeholder="City" [(ngModel)]="leadFilter.city">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let stat of listCity" [value]="stat">{{stat}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-sm-3">
            <mat-select class="field-full-width" placeholder="Counsellor" [(ngModel)]="leadFilter.assignedTo">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let stat of listCounsellor" [value]="stat">{{stat}}</mat-option>
            </mat-select>

        </mat-form-field>
        <mat-form-field class="col-sm-3">
            <mat-select class="field-full-width" placeholder="Source" [(ngModel)]="leadFilter.leadSource">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let stat of listSource" [value]="stat">{{stat}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>



    <div class="card-footer" style="background-color: rgb(1, 9, 15);">
        <button type="button" class="btn btn-primary block" (click)="filterLeads();">View Leads</button>
        <button type="button" style="float: inline-end;" class="btn btn-primary block" (click)="checkBoxClicked();">Re-Assign</button>

    </div>



    <table class="table table-sm" style="padding-right: 20px!important;margin-top: 10px;font-size: small;padding: 0;height: 100px;overflow-y: scroll;" *ngIf="filterClicked">
        <tr class="thead-dark">
            <th>#</th>
            <th style="max-width: 15px;">Select</th>
            <th>Student Details</th>
            <th *ngIf="!androidOs">Address</th>
            <th>Lead Status</th>
            <th *ngIf="!androidOs">Other Details</th>

        </tr>
        <tbody id="myTable">
            <tr *ngFor="let item of leads;let counter=index;">
                <td>{{counter+1}}</td>
                <td><input type="checkbox"></td>
                <td><b>Name : </b>{{item.studentName}}<br><b>Mobile : </b>{{item.mobileNo}}
                    <br><b>EMail : </b>{{item.emailId}}</td>
                <td *ngIf="!androidOs"><b>Address : </b>{{item.address}}<br><b>City : </b>{{item.cityName}}<br><b>State : </b>{{item.stateName}}</td>
                <td><b>Assigned To :</b> {{item.assignToCounsellor}}<br><b>Status : </b>{{item.status}}</td>
                <td *ngIf="!androidOs"><b>On Date : </b>{{item.leadDate}}<br><b>Source : </b>{{item.source}}<br><b>Program : </b> {{item.courseName}}</td>

            </tr>

        </tbody>
    </table>


</div>