import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "./utility/localStorageService";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class AuthInterceptor implements HttpInterceptor {
  
    token: string | null;
    platform: string = "Web";
    locId: number = 0;
  userId: string;
  method: string;
  operation: string;
  
    constructor(
     
      private persistenceService: LocalStorageService) {
      if (this.persistenceService.get("OS") == "Android")
        this.platform = "App"
      else
        this.platform = "Web"
  
    }
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.userId = this.persistenceService.get("refNo")
    this.method = this.persistenceService.get("method")
    this.operation = this.persistenceService.get("operation")
    if(this.userId==null) this.userId="New Login"
    if(this.method==null) this.method="Get"
    if(this.operation==null) this.operation="Accessing Login Page"
      const modifiedReq = req.clone({
        headers: new HttpHeaders({
        //   Authorization: 'Bearer ' + this.persistenceService.get("access_token"),
          platform: this.platform,
          byUser:this.userId,
          method:this.method,
          operation:this.operation,
          'X_Correlation_Id': this.calcCorrelationId(),
          'Access-Control-Allow-Origin':'*',
          'Access-Control-Allow-Credentials': 'true',
          'contentType': "application/json; charset=utf-8"
  
        })
      })
      return next.handle(modifiedReq);
    }
    calcCorrelationId() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
        var uuid = s.join("");
        return uuid;
      }
}