import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ExceptionHandler } from "./errorhandler";
import { TblLeadCateCory } from "../models/lead-category";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";






@Injectable({
    providedIn: "root",
  })
  export class LeadCategoryService {
    
    private serviceRoot = `${environment.serviceRoot}`;
    commonHeader:CommonHeader=new CommonHeader() 
    private options=this.commonHeader.options;
    constructor(
      private http: HttpClient,
      private ehandler:ExceptionHandler,
      private localStorage:LocalStorageService
    ) { }
  
    
    getAllLeadCategory(): Observable<TblLeadCateCory[]> {
      this.localStorage.set("method","Read")
      this.localStorage.set("operation","Get All Lead Category")
      const endPoint = `${this.serviceRoot}/getAllLeadCategory`;
      return this.http.get<TblLeadCateCory[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
    }
  }