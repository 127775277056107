<mat-toolbar [ngClass]="androidOS == true ? 'andr' : 'web'">
    <button mat-icon-button (click)="sidenav.toggle();changeIcon()" matTooltip="Menu">
        <mat-icon *ngIf="menuIconToggle">menu</mat-icon>
        <mat-icon *ngIf="!menuIconToggle">close</mat-icon>
    </button>
    <span>Lead Management System</span>

    <span class="toolbar-item-spacer"></span>

    <button mat-icon-button matTooltip="New Leads" [matMenuTriggerFor]="newLeads" *ngIf="userType=='Admin' || userType=='Counsellor'">
        <mat-icon [ngStyle]="{'color':'#27682'}">notifications</mat-icon>
        <span class="position-absolute top-20 start-150  
                translate-middle p-1 rounded-pill  
                bg-danger border border-light  
                rounded-circle badge" style="margin-top: 8px;">{{cntNewLeads}}</span>
    </button>
    <mat-menu #newLeads [overlapTrigger]="false" yPosition="below">
        <form (click)="stopPropagation($event)">
            <table class="table-striped">
                <tr>
                    <td colspan="2">
                        <h6 class="dropdown-header text-center" style="background-color: rgb(14, 96, 155);margin-top: -10px;color: white;">
                            New Leads
                        </h6>
                    </td>
                </tr>
                <tbody style="overflow-y: scroll;display:block;
                height:300px;">
                    <tr style="font-size: small; padding-left: 10px;padding-right: 3px;line-height: 1.5;margin: 0px; font-family: 'Courier New', Courier, monospace; word-wrap: break-word; white-space: -moz-pre-wrap; white-space: pre-wrap;" *ngFor="let item of newLeadsTillToday">
                        <td *ngIf="item.display" style="padding-left:5px ;">A New Student {{item.studentName}} from {{item.source}} added to your list on {{item.onDate}}

                        </td>
                        <td *ngIf="item.display" style="cursor: pointer;">
                            <mat-icon (click)="clearLeadClick(item.refNo)">clear</mat-icon>
                        </td>
                    </tr>
                </tbody>
                <tr>
                    <td colspan="2">
                        <a class="dropdown-item text-center small " style="padding-bottom: -10px;background-color: rgb(5, 60, 110);color: white;" href="/leads">Show All New Leads</a>
                    </td>
                </tr>
            </table>




        </form>
    </mat-menu>
    <button mat-icon-button matTooltip="Upcoming Leads" [matMenuTriggerFor]="upcoingFollowups" *ngIf="userType=='Admin' || userType=='Counsellor'">
        <mat-icon>notifications_active</mat-icon>
        <span class="position-absolute top-20 start-150  
                translate-middle p-1 rounded-pill  
                bg-danger border border-light  
                rounded-circle badge" style="margin-top: 8px;">{{cntFolloUps}}</span>
    </button>
    <mat-menu #upcoingFollowups [overlapTrigger]="false" yPosition="below">

        <form (click)="stopPropagation($event)">
            <table class="table-striped">
                <tr>
                    <td colspan="2">
                        <h6 class="dropdown-header text-center" style="background-color: rgb(14, 96, 155);margin-top: -10px;color: white;">
                            Follow-Ups
                        </h6>
                    </td>
                </tr>
                <tbody style="overflow-y: scroll;display:block;
                height:300px;">
                    <tr style="font-size: small; padding-left: 10px;padding-right: 3px;line-height: 1.5;margin: 0px; font-family: 'Courier New', Courier, monospace; word-wrap: break-word; white-space: -moz-pre-wrap; white-space: pre-wrap;" *ngFor="let item of followUpList">
                        <td *ngIf="item.display" style="padding-left:5px ;">
                            Follow-Ups scheduled on {{item.onDate}} at {{item.scheduleTime}} for {{item.studentName}}. Last note was '{{item.note}}'

                        </td>
                        <td *ngIf="item.display" style="cursor: pointer;">
                            <mat-icon (click)="clearFollowUpClick(item.refNo)">clear</mat-icon>
                        </td>
                    </tr>
                </tbody>
                <tr>
                    <td colspan="2">
                        <a class="dropdown-item text-center small " style="padding-bottom: -10px;background-color: rgb(5, 60, 110);color: white;" href="/upcoming-follow-ups">Show All Follow-Ups</a>
                    </td>
                </tr>
            </table>




        </form>







    </mat-menu>
    <button mat-icon-button matTooltip="My Account" [matMenuTriggerFor]="userAccountMenu" style="padding-right: 5px;">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
        <button mat-menu-item (click)="Profile();">
            <mat-icon>person</mat-icon><span>My Profile</span>
        </button>
        <button mat-menu-item (click)="settingsClick()">
            <mat-icon>settings</mat-icon><span>Settings</span>
        </button>
        <button mat-menu-item (click)="activityLog()">
            <mat-icon>help</mat-icon><span>Activity Log</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logOutClick()">
            <mat-icon>exit_to_app</mat-icon>Logout
        </button>
    </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container" color="primary">
    <mat-sidenav #sidenav mode="over" [class.mat-elevation-z4]="true" class="app-sidenav">
        <mat-nav-list>
            <a mat-list-item href="/dashboard" *ngIf="userType=='Admin' || userType=='Counsellor'">
                <i class="fas fa-fw fa-tachometer-alt"></i><span style="padding-left: 10px;">Dashboard</span>
            </a>
            <!-- <a mat-list-item href="/user-dashboard" *ngIf="userType=='Counsellor'">
                <i class="fas fa-fw fa-tachometer-alt"></i><span style="padding-left: 10px;">Dashboard</span>
            </a> -->
            <mat-expansion-panel [class.mat-elevation-z0]="true" style="left: -10px;">
                <mat-expansion-panel-header style="background: white;">
                    <i class="fab fa-fw fa-wpforms"></i> <span style="padding-left: 10px;">Admin</span>
                </mat-expansion-panel-header>
                <mat-nav-list style="padding: 0px;" *ngIf="userType=='Admin'">
                    <a mat-list-item href="/lead-source">Lead Source</a>
                    <a mat-list-item href="/users">Users</a>
                    <a mat-list-item href="/courses">Courses</a>
                    <a mat-list-item href="/lead-channel">Lead Channel</a>
                    <a mat-list-item href="/lead-status">Lead Status</a>
                    <a mat-list-item href="/lead-sub-status">Lead Sub-Status</a>
                    <a mat-list-item href="/lead-campign">Lead Campign</a>
                    <a mat-list-item href="/lead-medium">Lead Medium</a>
                    <a mat-list-item href="/lead-upload">Lead Import/Upload</a>
                    <a mat-list-item href="/lead-distribution">Lead Distribution</a>
                    <a mat-list-item href="/raw-list">Raw/API Leads</a>
                    <a mat-list-item href="/bulk-reassign-leads">Bulk Re-Assign Leads</a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel [class.mat-elevation-z0]="true" style="left: -10px;">
                <mat-expansion-panel-header>
                    <i class="far fa-fw fa-window-maximize"></i> <span style="padding-left: 10px;">Lead Management</span>

                </mat-expansion-panel-header>
                <mat-nav-list>
                    <a mat-list-item href="/leads" *ngIf="userType!='Third Party'">All Leads</a>
                    <a mat-list-item href="/my-uploads" *ngIf="userType!='Third Party'">My Uploads</a>
                    <a mat-list-item href="/upcoming-follow-ups" *ngIf="userType!='Third Party'">Upcoming Follow Ups</a>
                    <a mat-list-item href="/failed-follow-ups" *ngIf="userType!='Third Party'">Failed Follow Ups</a>
                    <a mat-list-item href="/raw-list" *ngIf="userType!='Third Party'">Raw/API Leads</a>
                    <a mat-list-item href="/source-wise-lead-status" *ngIf="userType=='Third Party' || userType=='Admin'">Source Wise Lead Status</a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel [class.mat-elevation-z0]="true" style="left: -10px;">
                <mat-expansion-panel-header>
                    <i class="fas fa-fw fa-table"></i> <span style="padding-left: 10px;">Reports</span>

                </mat-expansion-panel-header>
                <mat-nav-list *ngIf="userType=='Admin'">
                    <!-- <a mat-list-item href="/status-wise-average-time">Status Wise Average Time</a> -->
                    <a mat-list-item href="/counsellor-lead-status">Counsellor/Lead Status</a>
                    <a mat-list-item href="/user-wise-activity">User Wise Activity</a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel [class.mat-elevation-z0]="true" style="left: -10px;">
                <mat-expansion-panel-header>
                    <i class="fas fa-fw fa-tachometer-alt"></i> <span style="padding-left: 10px;">Advance Settings</span>

                </mat-expansion-panel-header>
                <mat-nav-list *ngIf="userType=='Admin'">
                    <a mat-list-item href="/lead-conditions">Lead Conditions</a>
                    <a mat-list-item href="/backup">Backup</a>
                    <a mat-list-item href="/restore">Restore</a>
                </mat-nav-list>
            </mat-expansion-panel>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet>

        </router-outlet>
        <app-bottom-bar></app-bottom-bar>
    </mat-sidenav-content>

</mat-sidenav-container>