import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SettingsDialogComponent } from '../dialogs/settings-dialog/settings-dialog.component';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { ActivityLogDialogComponent } from '../dialogs/activity-log-dialog/activity-log-dialog.component';
import { ProfileDialogComponent } from '../dialogs/profile-dialog/profile-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.css']
})
export class BottomBarComponent implements OnInit {
  modalRef: BsModalRef;
  androidOS:boolean=true
  constructor(private router: Router,
    private modalService: BsModalService,
    private localStorage:LocalStorageService
    ) { }

  ngOnInit(): void {
    if(this.localStorage.get("osType")=="Android")
    {
      this.androidOS=true
    }
    else
    {
      this.androidOS=false
    }
  }
  navButtonClick(url: string) {
    this.router.navigateByUrl(url)
  }
  Profile() {
    this.modalRef = this.modalService.show(ProfileDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });
  }
  logOutClick()
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Logout',  module: 'Logout', refNo:'0'};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
  }
  
 
 
  activityLog() {
    this.modalRef = this.modalService.show(ActivityLogDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-lg' });
  }


  settingsClick() {
    this.modalRef = this.modalService.show(SettingsDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });

  }
  upcomingEvents() {
    this.modalRef = this.modalService.show(SettingsDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });

  }
}
