<body>
    <div class="main">
        <h3 style="color: white;text-align: center;margin-top: 50px;">Lead Mangement System</h3>
        <div class="login" style="margin-top: 250px;">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <label for="chk" aria-hidden="true" style="font-size: 30px!important;">Login</label>
                <input class="inputs" style="margin-top: -30px;" type="email" name="email" placeholder="User Name/E-Mail" id="userName" autocomplete="off" formControlName="userName">
                <input class="inputs" type="password" name="pswd" placeholder="Password" id="passWord" formControlName="passWord">
                <div class="row" style="font-size: small;">
                    <input style="margin-left: 89px;cursor: pointer;margin-top: -5px;width: 16px;height: 16px;" type="checkbox" name="remember" id="remember" #remember (change)="onChange($event)" checked="isRememberChecked" formControlName="remember">                    &nbsp;&nbsp;Remember me
                </div>

                <button type="submit">Login</button>

                <p align="center" style="margin-top: 20px;cursor: pointer;"><a (click)="openModal();">Forgot Password?</a></p>
            </form>
        </div>
    </div>
</body>