import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { TblLeadDistribution } from 'src/app/shared/models/lead-distribution';
import { LeadDistributionService } from 'src/app/shared/services/LeadDistributionService';
import { UserServices } from 'src/app/shared/services/userservices';
import { UserIdAndName } from 'src/app/shared/models/useridandname';
import { TblLead } from 'src/app/shared/models/leads';
import { LeadService } from 'src/app/shared/services/lead.services';
import { PagerService } from 'src/app/shared/pagination/pager.service';
@Component({
  selector: 'app-lead-distribution',
  templateUrl: './lead-distribution.component.html',
  styleUrls: ['./lead-distribution.component.css']
})
export class LeadDistributionComponent implements OnInit {
  assignedData: UserIdAndName
  sidePanelMode: boolean = false;
  isEditing: boolean;
  members: Observable<TblLeadDistribution[]>;
  leadDistributionData: TblLeadDistribution[];
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate: any
  counsellors: UserIdAndName[];
  leadId: any;
  lead: TblLead;

  pager: any = {};
  private allItems: any[];
  // paged items
  pagedItems: any[];

  constructor(private _leadDistributionService: LeadDistributionService,
    private modalService: BsModalService,
    private spinnerService: AppComponent, private notifyService: NotificationService,
    private counsellorService: UserServices,
    private leadService: LeadService,
    private pagerService: PagerService
  ) {
  }

  ngOnInit(): void {
    this.assignedData = new UserIdAndName();
    this.getAllLeadDistribution();
    this.startDate = new Date()
    this.getAllCounsellors();
  }
  getAllLeadDistribution() {
   
    this.members =
      this._leadDistributionService.getAllLeadDistribution();
     
      // this.allItems=[{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},
      //   {"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},
      //   {"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"},{"name":"dsdsds"}
      // ]
      // this.members.forEach(e=>{
      //   let distributionData=new TblLeadDistribution();
      //   distributionData.source="dds"

      //   //distributionData=e.
      // })
      

      // initialize to page 1
    
      this.setPage(1);
  }
  getAllCounsellors() {
    this.counsellorService.getUsersActive().subscribe(resp => {
      this.counsellors = resp;
      //this.counsellors = this.counsellors.filter((fl) => fl.userType==="Counsellor");
    });
  }

  sidePanel(id, isEditing) {
    this.leadId = id
    this.sidePanelMode = true
    this.edit = isEditing
    if (isEditing) {
      this.boxTitle = "Assign Counsellor";
    }
  }

  //Editing Data
  validateForm(): boolean {
    if (!this.assignedData.refNo) {
      this.notifyService.showError("Select any counsellor!", "Error");
      return false;
    }
    else {
      return true;
    }
  }

  create_edit() {
    if (!this.validateForm()) {
      return;
    }
    this.leadService.getLeadsById(this.leadId)
      .subscribe(
        resp => {
          this.lead = resp;

          this.counsellors.forEach(e => {
            if (e.refNo == this.assignedData.refNo) {
              this.lead.assignToCounsellor = e.userName
            }
          })

          this.lead.userRefNo = this.assignedData.refNo

          this.leadService.saveAndUpdateLeads(this.lead).subscribe(resp => {
            if (resp.refNo > 0) {
              this.notifyService.showSuccess("Saved Successfully!", "Success");
            }
            else {
              this.notifyService.showError(resp.message, "Error")
            }
          });


          window.location.reload()
        },
        (error: HttpErrorResponse) => {
        });
  }
  close_clicked() {
    this.sidePanelMode = false
  }

  setPage(page: number) {
   
    // get pager object from service
   // this.pager = this.pagerService.getPager(this.leadDistributionData.length, page);

    // get current page of items
   // this.pagedItems = this.leadDistributionData.slice(this.pager.startIndex, this.pager.endIndex + 1);

   this.members.subscribe(
    val => {
      this.allItems=val
      this.pager = this.pagerService.getPager(this.allItems.length, page,8);
      this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
  )
  
  
  
    
}
}

