import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { TblEMailTemplate } from 'src/app/shared/models/EMail-template';
import { TemplateService } from 'src/app/shared/services/template-service';


@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {

  sidePanelMode: boolean = false;
  isEditing: boolean;
  emailTemplate: TblEMailTemplate;
  emailTemplates: Observable<TblEMailTemplate[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate: any
  constructor(private templateService: TemplateService,
    private modalService: BsModalService,
    private spinnerService: AppComponent,
    private notifyService: NotificationService) {
  }

  ngOnInit(): void {
    this.emailTemplate = new TblEMailTemplate();
    this.getAllData();
    this.startDate = new Date()
  }

  getAllData() {
    this.emailTemplates = this.templateService.getEMailTemplate();
  }
  sidePanel(id, isEditing) {
    this.sidePanelMode = true
    this.edit = isEditing
    if (isEditing) {
      this.boxTitle = "Edit Template";

      this.templateService.getEMailTemplateById(id)
        .subscribe(
          resp => {
            this.emailTemplate = resp;
          },
          (error: HttpErrorResponse) => {
            this.editDataAvbl = false;
          });
    }
    else {
      this.boxTitle = "New Template";
      this.emailTemplate = new TblEMailTemplate();
    }
  }


  //Editing Data
  validateForm(): boolean {

    if (!this.emailTemplate.fileAllowed) {
      this.notifyService.showError("Can't Be Blank!", "Error");
      return false;
    }
    else {
      return true;
    }
  }

  create_edit() {

    if (!this.validateForm()) {
      return;
    }
    if (this.edit) {
      this.templateService.saveAndUpdateEMailTemplate(this.emailTemplate).subscribe(resp => {
        if (resp.refNo > 0) {
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode = false
          this.getAllData()
        }
        else {
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else {
      this.templateService.saveAndUpdateEMailTemplate(this.emailTemplate).subscribe(resp => {
        if (resp.refNo > 0) {
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode = false
          this.getAllData()
        }
        else {
          this.notifyService.showError(resp.message, "Error")
        }
      }, (err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked() {
    this.sidePanelMode = false
    this.getAllData();
  }
}
