import { TblAccount } from "./account";
import { TblCall } from "./call";
import { TblContact } from "./contact";
import { TblLeadStatus } from "./leadstatus";
import { TblNote } from "./note";
import { TblProduct } from "./product";
import { TblSource } from "./source";

export class TblLead {
    refNo:number;
    leadDate:string;
    studentName:string;
    mobileNo:string;
    alternateNo:string;
    emailId:string;
    address:string;
    cityName:string;
    stateName:string;
    channelName:string;
    source:string;
    leadCampaignName:string;
    status:string;
    subStatus:string;
    leadMedium:string;
    courseName:string;
    userRefNo:string
    assignToCounsellor:string;
}
