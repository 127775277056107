import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from 'rxjs/operators';
import { ExceptionHandler } from "./errorhandler";
import { TblUser } from "../models/user";
import { UserIdAndName } from "../models/useridandname";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";

@Injectable({
  providedIn: "root",
})
export class UserServices {
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllUsers(): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","All User Details")
    const endPoint = `${this.serviceRoot}/getAllUsers`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

  getUserByUserId(memberId:number): Observable<TblUser> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","User Details By Id")
    const endPoint = `${this.serviceRoot}/getUserByUserId/${memberId}`;
    return this.http.get<TblUser>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  deleteUser(id: string) {
    this.localStorage.set("method","Delete")
    this.localStorage.set("operation","User Details By Id")
    const endPoint = `${this.serviceRoot}/deleteUser/${id}`;
    return this.http.get<TblUser>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  saveAndUpdate(data:TblUser):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update User Data")
    const endPoint = `${this.serviceRoot}/saveUser`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  updateUserTokenByIdAndSource(currentToken: string, id: string, source: string) :Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Notification Token")
    const endPoint = `${this.serviceRoot}/updateUserTokenByIdAndSource/${currentToken}/${id}/${source}`;
    return this.http.get<any>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getUsersActive(): Observable<UserIdAndName[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get List Of Active Users")
    const endPoint = `${this.serviceRoot}/getUsersActive`;
    return this.http.get<UserIdAndName[]>(endPoint,  this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  // getListOfActiveCounsellor(): Observable<UserIdAndName[]> {
  //   this.localStorage.set("method","Read")
  //   this.localStorage.set("operation","Get List Of Active Counsellor")
  //   const endPoint = `${this.serviceRoot}/getListOfActiveCounsellor`;
  //   return this.http.get<UserIdAndName[]>(endPoint,  this.options)
  //   .pipe(catchError(this.ehandler.errorHandler));
  // }
}
