import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLeadChannel } from "../models/lead-channel";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";

@Injectable({
  providedIn: "root",
})
export class LeadChannelService {
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllLeadChannel(): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Channel")
    const endPoint = `${this.serviceRoot}/getAllLeadChannel`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  deleteLeadChannelById(refNo: string) : Observable<any[]> {
    this.localStorage.set("method","Delete")
    this.localStorage.set("operation","Delete Lead Channel")
    const endPoint = `${this.serviceRoot}/deleteLeadChannelById/${refNo}`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
   getLeadChannelById(memberId:number): Observable<TblLeadChannel> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Channel By Id")
    const endPoint = `${this.serviceRoot}/getLeadChannelById/${memberId}`;
    return this.http.get<TblLeadChannel>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateLeadChannel(data:TblLeadChannel):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Channel")
    const endPoint = `${this.serviceRoot}/saveLeadChannel`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getChannelWiseLeadsCount(refNo:string,fromDate:string,toDate:string): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Channel Wise Lead Count")
    const endPoint = `${this.serviceRoot}/getChannelWiseLeadsCount/${refNo}/${fromDate}/${toDate}`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

  
}
