import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { LeadSubStatusService } from 'src/app/shared/services/leadSubStatusService';
import { TblLeadSubStatus } from 'src/app/shared/models/leadsubstatus';
import { TblLeadStatus } from 'src/app/shared/models/leadstatus';
import { LeadStatusService } from 'src/app/shared/services/leadStatus.services';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-status',
  templateUrl: './lead-sub-status.component.html',
  styleUrls: ['./lead-sub-status.component.css']
})
export class LeadSubStatusComponent implements OnInit {
  sidePanelMode:boolean=false;
  isEditing:boolean;
  TblLeadSubStatus: TblLeadSubStatus;
  subleadStatus: Observable<TblLeadSubStatus[]>;
  leadStatus: Observable<TblLeadStatus[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate:any
  constructor(private _leadSubStatusService : LeadSubStatusService,
    private _leadStatusService: LeadStatusService,
    private modalService: BsModalService,
    private spinnerService: AppComponent ,private notifyService : NotificationService) {
  }

  ngOnInit(): void {
    this.TblLeadSubStatus=new TblLeadSubStatus();
    this.getAllData();
  }
  getAllLeadData(){
    this.leadStatus= this._leadStatusService.getAllLeadStatus();
  }
  getAllData(){
    this.subleadStatus= this._leadSubStatusService.getAllLeadSubStatus();
  }
  sidePanel(id,isEditing){
    this.sidePanelMode=true
    this.edit=isEditing
    this.getAllLeadData()
    if(isEditing)
    {
      this.boxTitle="Edit Lead Status";
      
       this._leadSubStatusService.getLeadSubStatusById(id)
      .subscribe(
        resp=>{
          this.TblLeadSubStatus=resp;
        },
        (error: HttpErrorResponse) => {
          this.editDataAvbl=false;
        });
      }
    else{
      this.boxTitle="New Lead Sub-Status";
      this.TblLeadSubStatus=new TblLeadSubStatus();
    }
  }


  //Editing Data
  validateForm():boolean{
    if(!this.TblLeadSubStatus.subStatusName){
      this.notifyService.showError("Sub Status can not be empty!", "Error");
      return false;
    }
    else {
      return true;
    }
  }
  deleteClick(id:any)
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Delete',  module: 'Lead Sub-Status', refNo:id};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
  }
  create_edit(){

    if(!this.validateForm()){
      return;
    }
    if(this.edit){
           this._leadSubStatusService.saveAndUpdateLeadSubStatus(this.TblLeadSubStatus).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
          
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else{
      this._leadSubStatusService.saveAndUpdateLeadSubStatus(this.TblLeadSubStatus).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
          
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      },(err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked()
  {
    this.sidePanelMode=false
    this.getAllData();
  }
}
