import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLeadSources } from "../models/leadsources";
import { TblLead } from "../models/leads";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";

@Injectable({
  providedIn: "root",
})
export class LeadSourceService {
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllLeadSource(): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Sources")
    const endPoint = `${this.serviceRoot}/getAllLeadSources`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

   getLeadSourceById(memberId:number): Observable<TblLeadSources> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Source By Id")
    const endPoint = `${this.serviceRoot}/getLeadSourceByUserId/${memberId}`;
    return this.http.get<TblLeadSources>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getLeadSourceDetailsByLeadSource(source:string): Observable<TblLeadSources[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Sources By Lead Source")
    const endPoint = `${this.serviceRoot}/getLeadSourceDetailsByLeadSource/${source}`;
    return this.http.get<TblLeadSources[]>(endPoint,  this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  saveAndUpdateLeadSource(data:TblLeadSources):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Sources")
    const endPoint = `${this.serviceRoot}/saveLeadSources`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getLeadsMatchingWithMobileNos(): Observable<TblLead[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Leads Matching With Mobile No")
    const endPoint = `${this.serviceRoot}/getLeadsMatchingWithMobileNos`;
    return this.http.get<TblLead[]>(endPoint,  this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  deleteLeadSource(refNo: string): Observable<TblLeadSources> {
    this.localStorage.set("method","Delete")
    this.localStorage.set("operation","Delete Lead Source")
    const endPoint = `${this.serviceRoot}/deleteLeadSource/${refNo}`;
    return this.http.get<TblLeadSources>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
}
