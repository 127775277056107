import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblleadActivity } from "../models/lead-activity";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { TblNote } from "../models/note";
import { LeadNotes } from "../models/notes";

@Injectable({
  providedIn: "root",
})
export class LeadActivityService {
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  
  getLeadActivityById(id:number) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Activity By Id")
    const endPoint = `${this.serviceRoot}/getLeadActivityById/${id}`;
    return this.http.get<TblleadActivity[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getLeadANotesById(id:number) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Notes By Student Id")
    const endPoint = `${this.serviceRoot}/getLeadANotesById/${id}`;
    return this.http.get<LeadNotes[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
}
