<div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom: 2px solid grey;">
        <h1 class="h3 mb-0 text-gray-800">Admin Dashboard</h1>
    </div>
    <div class="row div-viewport">
        <div class="col-xl-2 col-md-2 mb-2">
            <a (click)="openFilterForm()" style="cursor: pointer;width: 50%;">

                <input id="inputdefault" placeholder="Create Filter" type="text" style=" position: relative;
                    color: #aaa;
                    font-size: 16px; width: 100%;padding-left: 20px;" readonly>
                <i class="fa fa-filter" style="  position: absolute;
                    top: 10px;
                    left: 15px;"></i>
                <i class="fa fa-caret-down fa-lg pull-right" style="  position: absolute;
                    top: 10px;
                    right: 20px;"></i>
            </a>
        </div>
        <div class="col-xl-2 col-md-2 mb-2">
            &nbsp;
        </div>
        <div class="col-xl-1 col-md-1 mb-1">
            &nbsp;
        </div>
        <div class="col-xl-3 col-md-3 mb-3">
            <ng-multiselect-dropdown [placeholder]="'Select Counsellor'" [settings]="dropdownSettings" [data]="userIdAndName" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-xl-4 col-md-4 mb-4">

            <a (click)="openRangeForm()" style="cursor: pointer;">
                <input id="inputdefault" placeholder="Date Range" type="text" style=" position: relative;
                    color: #aaa;
                    font-size: 16px; width: 100%;" [value]="selectedDate" readonly>
                <i class="fa fa-calendar fa-lg pull-right" style="  position: absolute;
                    top: 10px;
                    right: 20px;"></i>
            </a>

        </div>

    </div>
    <div class="row" style="overflow-y: scroll;height: 500px;">
        <div class="row">
            <div class=" col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body " style="background-color: rgb(224, 224, 81);color: white!important;">
                        <div class="row align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">Total Application<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{totalApplications}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Earnings (Annual) Card Example -->
            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body btn-primary">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">Total Paid Application<i class="fa fa-arrow-up" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{totalPaidApplication}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- New User Card Example -->
            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body btn-info">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">New Leads For Today<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{newLeadsForToday}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body btn-success">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">E-Mail Sent<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{eMailSent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body " style="background-color: rgb(86, 224, 127);color: white!important;">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">Last Day Active Users <i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{lastDayActiveUsers}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body " style="background-color: rgb(23, 143, 143);color: white!important;">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">Total Verified Leads<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{totalVerifiedLeads}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body btn-success">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">Total Re-Enquiries<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{totalReEnquiries}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body btn-danger">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">Total Missed Follow-Ups<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{totalMissedFollowUps}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 mb-2 ">
                <div class="card h-100 ">
                    <div class="card-body btn-warning">
                        <div class="row no-gutters align-items-center ">
                            <div class="col mr-2 ">
                                <div class="text-xs font-weight-bold text-uppercase mb-1 ">Total WhatsApp Consumed<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800 ">{{totalWhatsAppConsumed}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <!--====================================================================== Area Chart -->
        <div class="col-xl-6 col-md-6 mb-6 ">
            <div class="card mb-4 ">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between ">
                    <h6 class="m-0 font-weight-bold text-primary ">Total Lead Funnel by Status</h6>
                    <div class="dropdown no-arrow ">
                        <a class="dropdown-toggle " href="# " role="button " id="dropdownMenuLink " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400 "></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in " aria-labelledby="dropdownMenuLink ">
                            <div class="dropdown-header ">Dropdown Header:</div>
                            <a class="dropdown-item " href="# ">Action</a>
                            <a class="dropdown-item " href="# ">Another action</a>
                            <div class="dropdown-divider "></div>
                            <a class="dropdown-item " href="# ">Something else here</a>
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="chart-area " style="padding-left: 100px;">
                        <canvasjs-chart [options]="chartOptionsLeadFunnel" [styles]="{width: '90%', height:'260px'}"></canvasjs-chart>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pie Chart -->
        <div class="col-xl-6 col-md-6 mb-6 ">
            <div class="card mb-4 ">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between ">
                    <h6 class="m-0 font-weight-bold text-primary ">Application Funnel by Status</h6>
                    <div class="dropdown no-arrow ">
                        <a class="dropdown-toggle " href="# " role="button " id="dropdownMenuLink " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400 "></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in " aria-labelledby="dropdownMenuLink ">
                            <div class="dropdown-header ">Dropdown Header:</div>
                            <a class="dropdown-item " href="# ">Action</a>
                            <a class="dropdown-item " href="# ">Another action</a>
                            <div class="dropdown-divider "></div>
                            <a class="dropdown-item " href="# ">Something else here</a>
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="chart-area " style="padding-left: 100px;">
                        <canvasjs-chart [options]="chartOptionsApplicationFunnel" [styles]="{width: '90%', height:'260px'}"></canvasjs-chart>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-6 col-md-6 mb-6 ">
            <div class="card mb-4 ">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between ">
                    <h6 class="m-0 font-weight-bold text-primary ">Top Performing Lead Channel</h6>
                    <div class="dropdown no-arrow ">
                        <a class="dropdown-toggle " href="# " role="button " id="dropdownMenuLink " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400 "></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in " aria-labelledby="dropdownMenuLink ">
                            <div class="dropdown-header ">Dropdown Header:</div>
                            <a class="dropdown-item " href="# ">Action</a>
                            <a class="dropdown-item " href="# ">Another action</a>
                            <div class="dropdown-divider "></div>
                            <a class="dropdown-item " href="# ">Something else here</a>
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="chart-area ">
                        <canvasjs-chart [options]="chartOptionsDoughnut" [styles]="{width: '100%', height:'260px'}"></canvasjs-chart>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 mb-6 ">
            <div class="card mb-4 ">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between ">
                    <h6 class="m-0 font-weight-bold text-primary ">Lead Count by Source</h6>
                    <div class="dropdown no-arrow ">
                        <a class="dropdown-toggle " href="# " role="button " id="dropdownMenuLink " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400 "></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in " aria-labelledby="dropdownMenuLink ">
                            <div class="dropdown-header ">Dropdown Header:</div>
                            <a class="dropdown-item " href="# ">Action</a>
                            <a class="dropdown-item " href="# ">Another action</a>
                            <div class="dropdown-divider "></div>
                            <a class="dropdown-item " href="# ">Something else here</a>
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="chart-area ">
                        <canvasjs-chart [options]="leadCountBySource" [styles]="{width: '100%', height:'260px'}"></canvasjs-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 mb-6 ">
            <div class="card mb-4 ">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between ">
                    <h6 class="m-0 font-weight-bold text-primary ">Top Reasons for Closed Leads</h6>
                    <div class="dropdown no-arrow ">
                        <a class="dropdown-toggle " href="# " role="button " id="dropdownMenuLink " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400 "></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in " aria-labelledby="dropdownMenuLink ">
                            <div class="dropdown-header ">Dropdown Header:</div>
                            <a class="dropdown-item " href="# ">Action</a>
                            <a class="dropdown-item " href="# ">Another action</a>
                            <div class="dropdown-divider "></div>
                            <a class="dropdown-item " href="# ">Something else here</a>
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="chart-area ">
                        <canvasjs-chart [options]="topReasonsForClosedLeads" [styles]="{width: '100%', height:'260px'}"></canvasjs-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 mb-6 ">
            <div class="card mb-4 ">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between ">
                    <h6 class="m-0 font-weight-bold text-primary ">Lead Count by Counselor</h6>
                    <div class="dropdown no-arrow ">
                        <a class="dropdown-toggle " href="# " role="button " id="dropdownMenuLink " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400 "></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in " aria-labelledby="dropdownMenuLink ">
                            <div class="dropdown-header ">Dropdown Header:</div>
                            <a class="dropdown-item " href="# ">Action</a>
                            <a class="dropdown-item " href="# ">Another action</a>
                            <div class="dropdown-divider "></div>
                            <a class="dropdown-item " href="# ">Something else here</a>
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="chart-area ">
                        <canvasjs-chart [options]="leadCountByCounsellor" [styles]="{width: '100%', height:'260px'}"></canvasjs-chart>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-xl-12 col-md-12 mb-12 ">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between ">
                <h6 class="m-0 font-weight-bold text-primary ">First Lead Owner Analysis</h6>
                <div class="dropdown no-arrow ">
                    <a class="dropdown-toggle " href="# " role="button " id="dropdownMenuLink " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                        <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400 "></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in " aria-labelledby="dropdownMenuLink ">
                        <div class="dropdown-header ">Dropdown Header:</div>
                        <a class="dropdown-item " href="# ">Action</a>
                        <a class="dropdown-item " href="# ">Another action</a>
                        <div class="dropdown-divider "></div>
                        <a class="dropdown-item " href="# ">Something else here</a>
                    </div>
                </div>
            </div>
            <div class="card-body" style="width: 100%!important;">




                <!-- <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </th>
                        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                    </ng-container> -->
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc" matSortDisableClear #empTbSort="matSort">
                    <ng-container matColumnDef="leadOwner">
                        <th class="mat-column" mat-header-cell mat-sort-header *matHeaderCellDef>&nbsp;Lead Owner </th>
                        <td mat-cell class="mat-column" *matCellDef="let element"> {{element.leadOwner}} </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th class="mat-column" mat-header-cell mat-sort-header *matHeaderCellDef>&nbsp;Total </th>
                        <td mat-cell class="mat-column center_align" *matCellDef="let element"> {{element.total}} </td>
                    </ng-container>
                    <ng-container matColumnDef="unTouched" style="min-width: 200px!important;">
                        <th class="mat-column" mat-header-cell mat-sort-header *matHeaderCellDef>&nbsp;Un-Touched </th>
                        <td mat-cell class="mat-column center_align" *matCellDef="let element"> {{element.unTouched}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ror">
                        <th class="mat-column" mat-header-cell mat-sort-header *matHeaderCellDef>&nbsp;RoR </th>
                        <td mat-cell class="mat-column center_align" *matCellDef="let element"> {{element.ror}} </td>
                    </ng-container>
                    <ng-container matColumnDef="followUp">
                        <th class="mat-column" mat-header-cell mat-sort-header *matHeaderCellDef>&nbsp;Follow-Up </th>
                        <td mat-cell class="mat-column center_align" *matCellDef="let element"> {{element.followUp}} </td>
                    </ng-container>
                    <ng-container matColumnDef="closed">
                        <th class="mat-column" mat-header-cell mat-sort-header *matHeaderCellDef>&nbsp;Closed </th>
                        <td mat-cell class="mat-column center_align" *matCellDef="let element"> {{element.closed}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true" style="background-color: rgb(57, 48, 61);color: white!important;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>

        </div>









    </div>




</div>

<h1>&nbsp;</h1>

<h1>&nbsp;</h1>


<div id="filterForm" class="form-popup-popup" style="z-index: 9999999!important;width: 540px;top:110px;left:12px; ">
    <div class="card-header d-flex flex-row align-items-center justify-content-between ">
        <h6 class="font-weight-bold text-primary " style="padding-left: 20px;">Create Filter</h6>
        <i class="fa fa-filter" style="  position: absolute;
                    top: 17px;
                    left: 15px;"></i>
        <button type="button " class="btn btn-danger " style="float: right; " (click)="closeFilterForm() ">X</button>
    </div>
    <div class="card-body " style="height: 350px!important;">
        <div class="row" style="height: 40px;border-bottom:1px solid rgb(224, 214, 214);" *ngIf="!leadSubTypeSelected">No filter is selected.</div>

        <div class="row" style="height: 40px;border-bottom:1px solid rgb(224, 214, 214);" *ngIf="leadSubTypeSelected">
            <p *ngFor="let data of subTypeStatus;i as index " style="padding-right: 10px;"><a *ngIf="data.value!=0">{{data.name}}({{data.value}})&nbsp;<span style="cursor: pointer;" (click)="selectedFilterRemove(data.name)">X</span></a></p>
        </div>
        <div class="row" style="height: 300px;padding: 10px;">
            <div style="border-right:1px solid rgb(224, 214, 214);width: 180px;">
                <p *ngFor="let data of filterType;i as index " (click)="filterTypeClicked(data.name)"><i class="fa fa-square" *ngIf="data.value==0"></i><i class="fa fa-window-minimize" style="color: red;" *ngIf="data.value==1"></i>&nbsp;{{data.name}}</p>
            </div>
            <div class="col" *ngIf="!leadTypeSelected">Please select filters and click apply.</div>
            <div class="col" [style.display]="leadChannelDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <p *ngFor="let data of leadChannel;i as index " (click)="filterSubTypeClicked($event, 'Lead Channel',data.leadMediumName)"><input name="leadChannelDisp" type="checkbox" readonly>&nbsp;{{data.leadMediumName}}</p>
            </div>
            <div class="col" [style.display]="leadSourseDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <p *ngFor="let data of leadSource;i as index " (click)="filterSubTypeClicked($event,'Lead Source',data.leadMediumName)"><input name="leadSourseDisp" type="checkbox" readonly>&nbsp;{{data.sourceName}}</p>
            </div>
            <div class="col" [style.display]="leadStatusDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <p *ngFor="let data of leadStatus;i as index " (click)="filterSubTypeClicked($event,'Status',data.statusName)"><input name="leadStatusDisp" type="checkbox" readonly>&nbsp;{{data.statusName}}</p>
            </div>
            <div class="col" [style.display]="leadSubStatusDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <p *ngFor="let data of leadSubStatus;i as index " (click)="filterSubTypeClicked($event,'Sub-Status',data.subStatusName)"><input name="leadSubStatusDisp" type="checkbox" readonly>&nbsp;{{data.subStatusName}}</p>
            </div>
            <div class="col" [style.display]="leadCourseDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <p *ngFor="let data of leadCourse;i as index " (click)="filterSubTypeClicked($event,'Course',data.courseName)"><input name="leadCourseDisp" type="checkbox" readonly>&nbsp;{{data.courseName}}</p>
            </div>
            <div class="col" [style.display]="leadCategoryDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <p *ngFor="let data of leadCategory;i as index " (click)="filterSubTypeClicked($event,'Lead Category',data.categoryName)"><input name="leadCategoryDisp" type="checkbox" readonly>&nbsp;{{data.categoryName}}</p>
            </div>
        </div>
    </div>
    <div class="card-footer" style="float: right;padding-right: 30px;">

        <button type="button" class="btn btn-danger block" (click)="filterResetClick()">Reset</button>
        <button type="button" class="btn btn-info block" style="float: right;" style="float: right;" (click)="filterApplyClick()">Apply</button>
    </div>
</div>

<div id="dateRangeForm" class="form-popup-popup" style="z-index: 9999999!important;width: 550px;top:140px ">
    <div class="card-header d-flex flex-row align-items-center justify-content-between ">
        <h6 class="font-weight-bold text-primary ">Lead Filter</h6>
        <button type="button " class="btn btn-danger " style="float: right; " (click)="closeRangeForm() ">X</button>
    </div>
    <div class="card-body " style="height: 380px!important;overflow-y: hidden;">
        <div class="row">
            <div class="col">
                <label class="radio-container">Creation Date
                    <input type="checkbox" name="rangeType" id="creationDate"  checked="checked"  (click)="rangeTypeClicked(1)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>
            <div class="col">
                <label class="radio-container">Modification Date
                    <input type="checkbox" name="rangeType" id="notificationDate" (click)="rangeTypeClicked(2)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>
            <div class="col">
                <label class="radio-container">Next Action Date
                    <input type="checkbox" name="rangeType" id="nextActionDate" (click)="rangeTypeClicked(3)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>
            <div class="col">
                <label class="radio-container">Re Enquire Date
                    <input type="checkbox" name="rangeType" id="reEnquireDate" (click)="rangeTypeClicked(4)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>

        </div>
        <div class="wrapper ">
            <mat-card class="mat-elevation-z3 presets ">
                <button mat-button *ngFor="let preset of presets " (click)="selectPreset(preset.range) ">
              {{ preset.label }}
            </button>
            </mat-card>

            <mat-card class="calendar ">
                <mat-calendar #calendar [(selected)]="selectedDateRange" (selectedChange)="rangeChanged($event)" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
            </mat-card>
        </div>
    </div>
    <div class="card-footer" style="float: right;padding-right: 30px;">

        <button type="button" class="btn btn-danger block">Reset</button>
        <button type="button" class="btn btn-info block" style="float: right;">Apply</button>
    </div>
</div>