import { Component, OnInit } from '@angular/core';
import { TblUser } from '../../models/user';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { UserServices } from '../../services/userservices';
import { NotificationService } from 'src/app/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.css']
})
export class SettingsDialogComponent implements OnInit {
  user: TblUser;
  selected: string;
  showPassword: boolean = false
  constructor(
    private localStorage: LocalStorageService,
    private userservices: UserServices,
    private notifyService: NotificationService,
    public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.selected = "Official"
    this.user = new TblUser();
    this.userservices.getUserByUserId(Number(this.localStorage.get("refNo")))
      .subscribe(
        resp => {
          this.user = resp;
          if (this.user.sendEMailFrom == null)
            this.user.sendEMailFrom = "Official"
          if (this.user.sendSMSFrom == null)
            this.user.sendSMSFrom = "Official"
          if (this.user.sendWhatsAppFrom == null)
            this.user.sendWhatsAppFrom = "Official"
          if (this.user.sendEMailFrom.toString() == "Personal")
            this.showPassword = true
          else
            this.showPassword = false
        },
        (error: HttpErrorResponse) => {

        });
  }

  updateSettings() {
    this.userservices.saveAndUpdate(this.user)
      .subscribe(
        resp => {
          if (resp.userName == this.user.userName) {
            this.localStorage.set("showMeUpcomingEventsOf", this.user.showMeUpcomingEventsOf.toString());
            this.localStorage.set("showMeLeadsFromDate", this.user.showMeLeadsFromDate);
            this.localStorage.set("sendWhatsAppFrom", this.user.sendWhatsAppFrom.toString());
            this.localStorage.set("sendSMSFrom", this.user.sendSMSFrom);
            this.localStorage.set("sendEMailFrom", this.user.sendEMailFrom.toString());
            this.notifyService.showSuccess("Settings Updated Successfully", "Update")
           
          }
        },
        (error: HttpErrorResponse) => {

        });
  }
  onEMailSenderChanged(data: any) {
    if (data == "Personal")
      this.showPassword = true
    else
      this.showPassword = false
  }
}