<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">{{module}}</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">

        <h4>Are you sure you want to {{mode}}</h4>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="modalRef.hide()">Cancel</button>
        <button type="button" class="btn btn-danger block" style="float: right;" (click)="confirm();modalRef.hide()">Confirm</button>
    </div>
</div>