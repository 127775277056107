import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { UserIdAndName } from '../shared/models/useridandname';
import { UserServices } from '../shared/services/userservices';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { TblLeadSources } from '../shared/models/leadsources';
import { TblLeadMedium } from '../shared/models/leadMedium';
import { TblLeadStatus } from '../shared/models/leadstatus';
import { TblLeadSubStatus } from '../shared/models/leadsubstatus';
import { TblCourses } from '../shared/models/courses';
import { TblLeadCateCory } from '../shared/models/lead-category';
import { LeadMediumService } from '../shared/services/leadMedium.service';
import { LeadSourceService } from '../shared/services/leadsource.services';
import { LeadStatusService } from '../shared/services/leadStatus.services';
import { LeadSubStatusService } from '../shared/services/leadSubStatusService';
import { CoursesService } from '../shared/services/courses.service';
import { LeadCategoryService } from '../shared/services/lead-category.service';
import { NotificationService } from '../notification.service';
import { FirstLeadAnalysis } from '../shared/models/first-lead-analysis';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel]
})
export class DashboardComponent implements OnInit {
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('calendar') calendar: MatCalendar<Date> | undefined;
  selectedDateRange: DateRange<Date> | undefined;
  maxDate: Date | undefined;
  minDate: Date | undefined;
  userIdAndName: UserIdAndName[]
  selectedDate: string
  dropdownSettings: any = {};
  closeDropdownSelection = false;

  presets = [
    {
      label: 'Today',
      range: {
        start: new Date(),
        end: new Date(),
      },
    },
    {
      label: 'Yesterday',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
        end: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
      },
    },
    {
      label: 'Last 7 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 7);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 30 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 90 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 90);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 12 months',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date();
          start.setFullYear(today.getFullYear() - 1);
          start.setDate(start.getDate() + 1);
          return start;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last year',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date(today.getFullYear() - 1, 0, 1);
          return start;
        })(),
        end: (() => {
          const today = new Date();
          const end = new Date(today.getFullYear() - 1, 11, 31);
          return end;
        })(),
      },
    },
    {
      label: 'This year',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date(today.getFullYear(), 0, 1);
          return start;
        })(),
        end: new Date(),
      },
    },
  ];



  totalApplications: number = 10
  totalPaidApplication: number = 0
  newLeadsForToday: number = 2
  eMailSent: number = 23
  lastDayActiveUsers: number = 2
  totalVerifiedLeads: number = 23
  totalReEnquiries: number = 3
  totalMissedFollowUps: number = 1
  totalWhatsAppConsumed: number = 28



  rangeOptionSelected = "Creation Date ";


  leadChannel: TblLeadMedium[]
  leadSource: TblLeadSources[]
  leadStatus: TblLeadStatus[]
  leadSubStatus: TblLeadSubStatus[]
  leadCourse: TblCourses[]
  leadCategory: TblLeadCateCory[]


  dropdownList = [];
  selectedItems = [];
  leadTypeSelected: boolean = false;
  leadSubTypeSelected: boolean = false

  leadChannelDisp: boolean = false
  leadSourseDisp: boolean = false
  leadStatusDisp: boolean = false
  leadSubStatusDisp: boolean = false
  leadCourseDisp: boolean = false
  leadCategoryDisp: boolean = false

  subTypeStatus: { name: string; value: number; }[];
  filterType: { name: string; value: number; }[];

  chartOptionsLeadFunnel = {
    animationEnabled: true,
    data: [{
      type: "funnel",
      indexLabel: "{name}: {y}",
      valueRepresents: "area",
      dataPoints: [
        { y: 8, name: "Untouched" },
        { y: 7, name: "RnR" },
        { y: 6, name: "Follow Up" },
        { y: 5, name: "Interested" },
        { y: 2, name: "Closed" }
      ]
    }]
  }
  chartOptionsApplicationFunnel = {
    animationEnabled: true,
    data: [{
      type: "funnel",
      indexLabel: "{name}: {y}",
      valueRepresents: "area",
      dataPoints: [
        { y: 8, name: "Untouched" },
        { y: 7, name: "RnR" },
        { y: 6, name: "Follow Up" },
        { y: 5, name: "Interested" },
        { y: 2, name: "Closed" }
      ]
    }]
  }
  chartOptionsDoughnut = {
    animationEnabled: true,

    colorSet: "customColorSet",

    data: [{
      type: "doughnut",
      indexLabel: "{name}: {y}",
      innerRadius: "90%",
      yValueFormatString: "#,##0.00'%'",
      dataPoints: [
        { y: 33, name: "Open Dump" },
        { y: 25, name: "Landfill" },
        { y: 13.5, name: "Recycling" },
        { y: 11, name: "Incineration" },
        { y: 7.7, name: "Sanitary Landfill (with landfill gas collection)" },
        { y: 5.5, name: "Composting" },
        { y: 4, name: "Controlled Landfill" },
        { y: 0.3, name: "Others" }
      ]
    }]
  }

  leadCountBySource = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: [{
      type: "column",

      showInLegend: false,
      dataPoints: [
        { label: "NoPaper", y: 262 },
        { label: "NewsPaper", y: 211 },
        { label: "Others", y: 175 }
      ]
    }]
  }
  leadCountByCounsellor = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: [{
      type: "column",

      showInLegend: false,
      dataPoints: [
        { label: "Tara", y: 262 },
        { label: "Sara", y: 211 },
        { label: "Mara", y: 175 },
        { label: "Fera", y: 137 },
        { label: "Iqbal", y: 115 },

      ]
    }]
  }
  topReasonsForClosedLeads = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: [{
      type: "bar",

      showInLegend: false,
      dataPoints: [
        { label: "Not Folloups", y: 262 },
        { label: "No Appropriate Cours", y: 211 }

      ]
    }]
  }
  
  dataSource : MatTableDataSource<FirstLeadAnalysis>
  dataValue : FirstLeadAnalysis[] = [];
  
  displayedColumns: string[] = ['leadOwner', 'total', 'unTouched', 'ror', 'followUp', 'closed'];
  
  constructor(
    private leadChannelService: LeadMediumService,
    private leadSourceService: LeadSourceService,
    private leadStatusService: LeadStatusService,
    private leadSubStatusService: LeadSubStatusService,
    private leadCourseService: CoursesService,
    private leadCategoryService: LeadCategoryService,
    private userService: UserServices,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    public datepipe: DatePipe,
    private notifyService: NotificationService,
    private _liveAnnouncer:LiveAnnouncer

  ) {

    this.dataSource=new MatTableDataSource;
  }

  ngAfterViewInit() {    
    this.dataSource.sort = this.empTbSort;
}
  sortData(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngOnInit(): void {
    this.getActiveCounsellors()
    this.getAllSubLeadTypes()
        this.dataSource.data = [{leadOwner:'Alok',total:10,unTouched:7,ror:2,followUp:2,closed:0},{leadOwner:'Sagarik',total:12,unTouched:3,ror:5,followUp:5,closed:1}];

    this.subTypeStatus = [{ name: 'Lead Channel', value: 0 }, { name: 'Lead Source', value: 0 }, { name: 'Status', value: 0 }, { name: 'Sub-Status', value: 0 }, { name: 'Course', value: 0 }, { name: 'Lead Category', value: 0 }];
    this.filterType = [{ name: 'Lead Channel', value: 0 }, { name: 'Lead Source', value: 0 }, { name: 'Status', value: 0 }, { name: 'Sub-Status', value: 0 }, { name: 'Course', value: 0 }, { name: 'Lead Category', value: 0 }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'refNo',
      textField: 'userName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.minDate = new Date(2023, 0, 1);
    this.maxDate = new Date(2024, 11, 31);
    this.selectedDateRange = new DateRange<Date>(
      new Date(),
      new Date()
    );
    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')
  }
  getAllSubLeadTypes() {
    this.leadChannelService.getAllLeadMedium().subscribe(resp => {
      if (resp.length > 0) {
        this.leadChannel = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadChannel = []
      }
    });

    this.leadSourceService.getAllLeadSource().subscribe(resp => {
      if (resp.length > 0) {
        this.leadSource = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadSource = []
      }
    });


    this.leadStatusService.getAllLeadStatus().subscribe(resp => {
      if (resp.length > 0) {
        this.leadStatus = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadStatus = []
      }
    });

    this.leadSubStatusService.getAllLeadSubStatus().subscribe(resp => {
      if (resp.length > 0) {
        this.leadSubStatus = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadSubStatus = []
      }
    });

    this.leadCourseService.getAllCourses().subscribe(resp => {
      if (resp.length > 0) {
        this.leadCourse = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadCourse = []
      }
    });

    this.leadCategoryService.getAllLeadCategory().subscribe(resp => {
      if (resp.length > 0) {
        this.leadCategory = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadCategory = []
      }
    });
  }
  filterTypeClicked(data: any) {

    this.leadTypeSelected = true
    if (data == "Lead Channel") {
      this.leadChannelDisp = true
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.leadSubStatusDisp = false
      this.leadCourseDisp = false
      this.leadCategoryDisp = false
    }
    if (data == "Lead Source") {
      this.leadChannelDisp = false
      this.leadSourseDisp = true
      this.leadStatusDisp = false
      this.leadSubStatusDisp = false
      this.leadCourseDisp = false
      this.leadCategoryDisp = false
    }
    if (data == "Status") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = true
      this.leadSubStatusDisp = false
      this.leadCourseDisp = false
      this.leadCategoryDisp = false
    }
    if (data == "Sub-Status") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.leadSubStatusDisp = true
      this.leadCourseDisp = false
      this.leadCategoryDisp = false
    }
    if (data == "Course") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.leadSubStatusDisp = false
      this.leadCourseDisp = true
      this.leadCategoryDisp = false
    }
    if (data == "Lead Category") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.leadSubStatusDisp = false
      this.leadCourseDisp = false
      this.leadCategoryDisp = true
    }

  }
  selectedFilterRemove(data: any) {
    this.subTypeStatus.forEach(e => {
      if (e.name == data) {
        e.value = 0;
      }
    });

    this.filterType.forEach(e => {
      if (e.name == data) {

        e.value = 0;

      }
    })

    if (data == "Lead Channel") {
      document.getElementsByName("leadChannelDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Lead Source") {
      document.getElementsByName("leadSourseDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Status") {
      document.getElementsByName("leadStatusDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Sub-Status") {
      document.getElementsByName("leadSubStatusDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Course") {
      document.getElementsByName("leadCourseDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Lead Category") {
      document.getElementsByName("leadCategoryDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
  }
  filterSubTypeClicked(event: any, source: string, refData: string) {
    let selected: boolean = false

    let itemGrSelected = 0;
    // this.filterType.forEach(e => {
    //   if (e.name == source) {
    //     if (event.target.checked) {
    //       e.value = 1;
    //     }
    //     else {
    //       e.value = 0;
    //     }
    //   }
    // })
    this.subTypeStatus.forEach(e => {
      if (e.name == source) {
        if (event.target.checked) {
          e.value++;
        }
        else {
          e.value--;
        }
      }
      if (e.value > 0) {
        itemGrSelected++;
        selected = true
      }
      this.filterType.forEach(f => {
        if (e.name == f.name) {
          if (e.value > 0)
            f.value = 1
          else
            f.value = 0
        }
      });

    })
    if (!selected)
      this.leadSubTypeSelected = false
    else
      this.leadSubTypeSelected = true

    if (itemGrSelected == 0)
      this.leadSubTypeSelected = false
    else
      this.leadSubTypeSelected = true
  }


  rangeTypeClicked(id: number) {
    const r1 = document.getElementById("creationDate") as HTMLInputElement
    const r2 = document.getElementById("notificationDate") as HTMLInputElement
    const r3 = document.getElementById("nextActionDate") as HTMLInputElement
    const r4 = document.getElementById("reEnquireDate") as HTMLInputElement
    if (id == 1 && r1?.checked) {
      r2.checked = false;
      r3.checked = false;
      r4.checked = false;
      this.rangeOptionSelected = "Creation Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 2 && r2?.checked) {
      r1.checked = false;
      r3.checked = false;
      r4.checked = false;
      this.rangeOptionSelected = "Modification Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 3 && r3?.checked) {
      r1.checked = false;
      r2.checked = false;
      r4.checked = false;
      this.rangeOptionSelected = "Next Action Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 4 && r4?.checked) {
      r1.checked = false;
      r2.checked = false;
      r3.checked = false;
      this.rangeOptionSelected = "Re Enquire Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
  }

  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );
  }

  // Selects a preset date range.
  selectPreset(presetDateRange: { start: Date; end: Date }) {
    this.selectedDateRange = new DateRange<Date>(
      presetDateRange.start,
      presetDateRange.end
    );

    // Jump into month of presetDateRange.start
    if (presetDateRange.start && this.calendar)
      this.calendar._goToDateInView(presetDateRange.start, 'month');

    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

  }
  filterApplyClick() {
    this.subTypeStatus.forEach(e => {
      if (e.value > 0)
        this.notifyService.showError(e.name,"Test")
    })
  }
  filterResetClick() {

  }
  onDropDownClose() {
    console.log('dropdown closed');
  }
  onItemDeSelect(item: any) {
    console.log('onItem DeSelect', item);
    //console.log('form model', this.myForm.get('city').value);
  }
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onDeSelectAll(items: any) {
    console.log('onDeSelectAll', items);
  }
  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }








  getActiveCounsellors() {
    this.userService.getUsersActive()
      .subscribe(
        resp => {
          this.userIdAndName = resp;

        },
        (error: HttpErrorResponse) => {

        });
  }
  openRangeForm() {
    document.getElementById("dateRangeForm").style.display = "block";
  }

  closeRangeForm() {
    document.getElementById("dateRangeForm").style.display = "none";
  }
  openFilterForm() {
    document.getElementById("filterForm").style.display = "block";
  }

  closeFilterForm() {
    document.getElementById("filterForm").style.display = "none";
  }
}
