import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel]
})
export class UserDashboardComponent implements OnInit {


  presets = [
    {
      label: 'Today',
      range: {
        start: new Date(),
        end: new Date(),
      },
    },
    {
      label: 'Yesterday',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
        end: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
      },
    },
    {
      label: 'Last 7 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 7);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 30 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 90 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 90);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 12 months',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date();
          start.setFullYear(today.getFullYear() - 1);
          start.setDate(start.getDate() + 1);
          return start;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last year',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date(today.getFullYear() - 1, 0, 1);
          return start;
        })(),
        end: (() => {
          const today = new Date();
          const end = new Date(today.getFullYear() - 1, 11, 31);
          return end;
        })(),
      },
    },
    {
      label: 'This year',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date(today.getFullYear(), 0, 1);
          return start;
        })(),
        end: new Date(),
      },
    },
  ];



  leadCountBySource = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: [{
      type: "column",

      showInLegend: false,
      dataPoints: [
        { label: "NoPaper", y: 262 },
        { label: "NewsPaper", y: 211 },
        { label: "Others", y: 175 }
      ]
    }]
  }
  leadCountByStatus = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: [{
      type: "column",

      showInLegend: false,
      dataPoints: [
        { label: "Initiated", y: 13 },
        { label: "In Process", y: 21 },
        { label: "Admitted", y: 17 },
        { label: "Not Interested", y: 1 }
      ]
    }]
  }
  @ViewChild('calendar') calendar: MatCalendar<Date> | undefined;
  selectedDateRange: DateRange<Date> | undefined;
  rangeOptionSelected = "Creation Date ";

  maxDate: Date | undefined;
  minDate: Date | undefined;



  constructor(
    public datepipe: DatePipe,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    
  ) { }
  selectedDate: string
  ngOnInit(): void {
    this.minDate = new Date(2023, 0, 1);
    this.maxDate = new Date(2024, 11, 31);
    this.selectedDateRange = new DateRange<Date>(
      new Date(),
      new Date()
    );
    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')
  }


  // Selects a preset date range.
  selectPreset(presetDateRange: { start: Date; end: Date }) {
    this.selectedDateRange = new DateRange<Date>(
      presetDateRange.start,
      presetDateRange.end
    );

    // Jump into month of presetDateRange.start
    if (presetDateRange.start && this.calendar)
      this.calendar._goToDateInView(presetDateRange.start, 'month');

    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

  }
  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );
  }
  rangeTypeClicked(id: number) {
    const r1 = document.getElementById("creationDate") as HTMLInputElement
    const r2 = document.getElementById("notificationDate") as HTMLInputElement
    const r3 = document.getElementById("nextActionDate") as HTMLInputElement
    const r4 = document.getElementById("reEnquireDate") as HTMLInputElement
    if (id == 1 && r1?.checked) {
      r2.checked = false;
      r3.checked = false;
      r4.checked = false;
      this.rangeOptionSelected = "Creation Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 2 && r2?.checked) {
      r1.checked = false;
      r3.checked = false;
      r4.checked = false;
      this.rangeOptionSelected = "Modification Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 3 && r3?.checked) {
      r1.checked = false;
      r2.checked = false;
      r4.checked = false;
      this.rangeOptionSelected = "Next Action Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 4 && r4?.checked) {
      r1.checked = false;
      r2.checked = false;
      r3.checked = false;
      this.rangeOptionSelected = "Re Enquire Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
  }
  openRangeForm() {
    document.getElementById("dateRangeForm").style.display = "block";
  }

  closeRangeForm() {
    document.getElementById("dateRangeForm").style.display = "none";
  }
}
