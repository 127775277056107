import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { DatePipe } from '@angular/common';
import { TblLeadMedium } from 'src/app/shared/models/leadMedium';
import { LeadMediumService } from 'src/app/shared/services/leadMedium.service';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-medium',
  templateUrl: './lead-medium.component.html',
  styleUrls: ['./lead-medium.component.css']
})
export class LeadMediumComponent implements OnInit {
  sidePanelMode:boolean=false;
  isEditing:boolean;
  model: TblLeadMedium;
  members: Observable<TblLeadMedium[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate:any
  constructor(private _leadMediumervice : LeadMediumService,
    private modalService: BsModalService,
    private spinnerService: AppComponent ,private notifyService : NotificationService) {
  }

  ngOnInit(): void {
    this.model=new TblLeadMedium();
    this.getAllData();
    this.startDate=new Date()
  }
  ngAfterViewInit() {
    //this.dt1.datetimeFormat = {"yyyy-mm-dd"};
  }
  getAllData(){
    this.members= this._leadMediumervice.getAllLeadMedium();
  }
  sidePanel(id,isEditing){
    this.sidePanelMode=true
    this.edit=isEditing
    if(isEditing)
    {
      this.boxTitle="Edit Lead Medium";
      
       this._leadMediumervice.getLeadMediumById(id)
      .subscribe(
        resp=>{
          this.model=resp;
        },
        (error: HttpErrorResponse) => {
          this.editDataAvbl=false;
        });
      }
    else{
      this.boxTitle="New Lead Medium";
      this.model=new TblLeadMedium();
    }
  }


  //Editing Data
  validateForm():boolean{
    
    if(!this.model.leadMediumName){
      this.notifyService.showError("Lead Medium Name can not be empty!", "Error");
      return false;
    }
    else {
      return true;
    }
  }

  create_edit(){

    if(!this.validateForm()){
      return;
    }
    if(this.edit){
           this._leadMediumervice.saveAndUpdateLeadMedium(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else{
      this._leadMediumervice.saveAndUpdateLeadMedium(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      },(err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked()
  {
    this.sidePanelMode=false
    this.getAllData();
  }
  deleteClick(id:any)
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Delete',  module: 'Lead Medium', refNo:id};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
  }
}
