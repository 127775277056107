<div class="container-fluid" id="container-wrapper" style="background-color: white;">
    <div class="row h4 mb-0 text-gray-800" style="border-bottom: 2px solid grey;display: inline-flex;overflow-y: hidden;overflow-x: scroll;white-space: nowrap;width: 100%;float: inline-end;margin-top: 5px;position: fixed;padding-right:10px ;">
        <div class="h4 mb-0 text-gray-800" style="padding-left: 0px;" class="col">Raw Leads</div>

        <div style="text-align: right !important;padding-right: 10px;">
            <li class="nav-item dropdown no-arrow mx-1">
                <a (click)="openFilterForm()" style="cursor: pointer;margin-right: 10px;">
                    <i class="fa fa-filter fa-sm text-danger pull-right" aria-hidden="true"></i>
                </a>
            </li>
        </div>
    </div>
    <div class="row" style="padding-top: 55px;">
        <div style="display: inline-flex;overflow-y: hidden;overflow-x: scroll;white-space: nowrap;width: 100%;float: inline-end;margin-top: 5px;position: fixed;">
            <a *ngFor="let mnu of rawLeadsSource" class="btn btn-primary text-white" style="margin-right: 3px!important;" (click)="getLeadsBySource(mnu.name)">
            {{mnu.name}}( {{mnu.value}} )
            </a>
        </div>
    </div>


    <table class="table table-sm" style="padding-right: 20px!important;margin-top: 40px;" id="myTable">
        <tr class="thead-dark">
            <th>#</th>
            <th>On Date</th>
            <th>Source</th>
            <th>Student Name</th>
            <th>Mobile No</th>
            <th>E-Mail</th>
            <th>Address</th>
            <th>Source</th>
            <th>Status</th>
            <th>Reason</th>

        </tr>
        <tr *ngFor="let item of pagedItems;let counter=index;">
            <td>{{counter+1}}</td>
            <td>{{item.actionDate }}</td>
            <td>{{item.sourceCode}}</td>
            <td>{{item.name}}</td>
            <td>{{item.mobileNo}}</td>
            <td>{{item.email}}</td>
            <td>{{item.address}}</td>
            <td>{{item.leadSource}}</td>
            <td>{{item.leadAccepted}}</td>

            <td>{{item.reason}}</td>

        </tr>
    </table>
    <div style="position: fixed;bottom: 40px;right: 20px;">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
            <li [ngClass]="{disabled:pager.currentPage === 1}">
                <a (click)="setPage(1)">First</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === 1}">
                <a (click)="setPage(pager.currentPage - 1)">Prev</a>
            </li>
            <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a (click)="setPage(page)">{{page}}</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a (click)="setPage(pager.currentPage + 1)">Next</a>
            </li>
            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a (click)="setPage(pager.totalPages)">Last</a>
            </li>
        </ul>
    </div>
</div>

<!-- FILTER FORM-->
<div id="myFilterForm" class="form-popup-popup" style="z-index: 999;">
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Lead Filter</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeFilterForm()">X</button>
    </div>
    <div class="card-body" style="height: 170px!important;overflow-y: scroll;">

        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <input matInput type="date" name="fromDate" [(ngModel)]="leadFilter.fromDate" placeholder="From Date" [min]="fromDate">
            </mat-form-field>
            <mat-form-field class="col-sm-6">
                <input matInput type="date" name="toDate" [(ngModel)]="leadFilter.toDate" placeholder="To Date" [min]="fromDate">
            </mat-form-field>
        </div>
        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="Lead Source" [(ngModel)]="leadFilter.leadSource">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listSource" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-sm-6">

                <mat-select class="field-full-width" placeholder="Lead Campaign" [(ngModel)]="leadFilter.leadCampaign">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listLeadChannel" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group row" style="margin-bottom: -5px;">
            <mat-form-field class="col-sm-6">
                <mat-select class="field-full-width" placeholder="State" [(ngModel)]="leadFilter.state">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listState" [value]="stat">{{stat}}</mat-option>
                </mat-select>

            </mat-form-field>
            <mat-form-field class="col-sm-6">

                <mat-select class="field-full-width" placeholder="City" [(ngModel)]="leadFilter.city">
                    <mat-option value="All">All</mat-option>
                    <mat-option *ngFor="let stat of listCity" [value]="stat">{{stat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="filterLeads();closeFilterForm();">Apply</button>
    </div>
</div>