

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/notification.service';
import { SaveAsExcelfileService } from 'src/app/shared/common-services/saveAsExcelFileService';
import { TblLead } from 'src/app/shared/models/leads';
import { TblLeadSources } from 'src/app/shared/models/leadsources';
import { TblUser } from 'src/app/shared/models/user';
import { UserIdAndName } from 'src/app/shared/models/useridandname';
import { LeadService } from 'src/app/shared/services/lead.services';
import { LeadCampaignService } from 'src/app/shared/services/leadcampaign.services';
import { LeadChannelService } from 'src/app/shared/services/LeadChannelService';
import { LeadMediumService } from 'src/app/shared/services/leadMedium.service';
import { LeadSourceService } from 'src/app/shared/services/leadsource.services';
import { LeadStatusService } from 'src/app/shared/services/leadStatus.services';
import { LeadSubStatusService } from 'src/app/shared/services/leadSubStatusService';
import { UserServices } from 'src/app/shared/services/userservices';
import * as XLSX from 'xlsx';
import { LocalStorageService } from '../utility/localStorageService';
@Component({
  selector: 'app-my-uploads',
  templateUrl: './my-uploads.component.html',
  styleUrls: ['./my-uploads.component.css']
})
export class MyUploadsComponent implements OnInit {
  mobileNosExist: string = ""
  emailIdExist: string = ""
  leadsExists: TblLead[];
  tblLeads: TblLead[]

  tblUsersActive: UserIdAndName[]
  tblLeadSources: TblLeadSources[];
  arrayBuffer: any;
  file: File;
  data: any = [{
    leadDate: '',
    studentName: '',
    mobileNo: '',
    alternateNo: '',
    emailId: '',
    address: '',
    cityName: '',
    stateName: '',
    channelName: '',
    source: '',
    leadCampaignName: '',
    status: '',
    subStatus: '',
    leadMedium: ''
  }];
  constructor(
    private notifyService: NotificationService,
    private excelService: SaveAsExcelfileService,

    private userService: UserServices,
    private _leadservices: LeadService,

    private leadStatus: LeadStatusService,
    private leadSubStatus: LeadSubStatusService,
    private leadCampaign: LeadCampaignService,
    private leadSource: LeadSourceService,
    private leadMedium: LeadMediumService,
    private leadChannel: LeadChannelService,
    private localStorage: LocalStorageService
    
  ) { }

  ngOnInit(): void {
  }

  AssignAssociate() {
    if (this.tblLeads == undefined) {
      this.notifyService.showError("Please upload the file first", "Error")
    }
    else {

      this.leadSource.getLeadSourceDetailsByLeadSource(this.tblLeads[0].source)
        .subscribe(
          resp => {
            this.tblLeadSources = resp;
            this.userService.getUsersActive()
              .subscribe(
                resp => {
                  this.tblUsersActive = resp;
                  let noOfLeads = this.tblLeads.length;
                  let cnt = 0;
                 
                    while (cnt < noOfLeads) {
                     
                          if (cnt < noOfLeads)
                            {
                              this.tblLeads[cnt].assignToCounsellor = this.localStorage.get("userName")
                              this.tblLeads[cnt].userRefNo = this.localStorage.get("refNo")
                            }
                          cnt++;
                      
                      
                    }
                  
                },
                (error: HttpErrorResponse) => {

                });
          },
          (error: HttpErrorResponse) => {
          });
    }
  }
  Update() {
    this._leadservices.saveAllLeads(this.tblLeads).subscribe(resp => {
      if (resp.status == "Success") {
        this.notifyService.showSuccess("Updated Successfully!", "Success");
      }
      else {
        this.notifyService.showError(resp.message, "Error")
      }
    });
    window.location.reload()
  }
  incomingfile(event: any) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.tblLeads = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      for (i = 0; i < this.tblLeads.length; i++) {
        if (i > 0) {
          if (this.tblLeads[i].source != this.tblLeads[i - 1].source) {
            this.notifyService.showError("Please upload data from one source at a time", "Error")
            this.tblLeads = null
            return;
          }
        }
      }

      this.leadSource.getLeadsMatchingWithMobileNos().subscribe(resp => {
        if (resp.length > 0) {
          this.leadsExists = resp
          this.mobileNosExist = ""
          this.emailIdExist=""
          for (let i = 0; i < this.leadsExists.length; i++) {
            for (let j = 0; j < this.tblLeads.length; j++) {
              if (this.leadsExists[i].mobileNo == this.tblLeads[j].mobileNo) {
                if (this.mobileNosExist == "") {
                  this.mobileNosExist = this.leadsExists[i].mobileNo
                }
                else {
                  this.mobileNosExist += ", " + this.leadsExists[i].mobileNo
                }
              }
              if (this.leadsExists[i].emailId == this.tblLeads[j].emailId) {
                if (this.emailIdExist == "") {
                  this.emailIdExist = this.leadsExists[i].emailId
                }
                else {
                  this.emailIdExist += ", " + this.leadsExists[i].emailId
                }
              }
            }
          }
          if (this.emailIdExist != "") {
            this.tblLeads = null
            this.notifyService.showError("These email ids are exist : " + this.emailIdExist,"Error")
            return
          }
          if (this.mobileNosExist != "") {
            this.tblLeads = null
            this.notifyService.showError("These email ids are exist : " + this.emailIdExist,"Error")
            return
          }
        }
      });
      //Status Check
      this.leadStatus.getAllLeadStatus().subscribe(res => {
        if (res.length > 0) {
          for (i = 0; i < this.tblLeads.length; i++) {
            //Check Lead Status Exist Or Not
            if (res.filter(data => data.statusName === this.tblLeads[i].status).length == 0) {
              
              this.notifyService.showError("Status Not Matching : " + this.tblLeads[i].status,"Error")
              this.tblLeads = null
              return
            }
          }
        }
        else {
          this.notifyService.showError("Please set/confugre the lead status first","Error")
          return
        }

      });
      //Sub Status Check
      this.leadSubStatus.getAllLeadSubStatus().subscribe(res => {
        if (res.length > 0) {
          for (i = 0; i < this.tblLeads.length; i++) {
            //Check Lead Sub-Status Exist Or Not
            if (res.filter(data => data.subStatusName === this.tblLeads[i].subStatus).length == 0) {
              
              this.notifyService.showError("Sub-Status Not Matching : " + this.tblLeads[i].subStatus,"Error")
              this.tblLeads = null
              return
            }
          }
        }
        else {
          this.notifyService.showError("Please set/confugre the lead sub-status first","Error")
          return
        }

      });
      //Campaign Check
      this.leadCampaign.getAllLeadCampaign().subscribe(res => {
        if (res.length > 0) {
          console.log(JSON.stringify(res))
          for (i = 0; i < this.tblLeads.length; i++) {
            //Check Lead Campaign Exist Or Not
            // if (res.filter(data => data.campaignName === this.tblLeads[i].leadCampaignName).length == 0) {
            //   this.notifyService.showError("Lead Campaign Not Matching : " + this.tblLeads[i].leadCampaignName,"Error")
            //   this.tblLeads = null
            //   return
            // }
          }
        }
        else {
          this.notifyService.showError("Please set/confugre the lead sub-status first","Error")
          return
        }
      });
      //Source Check
      this.leadSource.getAllLeadSource().subscribe(res => {
        if (res.length > 0) {
          // console.log(JSON.stringify(res))
          for (i = 0; i < this.tblLeads.length; i++) {
            //Check Lead Campaign Exist Or Not
            alert(this.tblLeads[i].source)
            if (res.filter(data => data.sourceName === this.tblLeads[i].source).length == 0) {
              this.notifyService.showError("Lead Source Not Matching : " + this.tblLeads[i].source,"Error")
              this.tblLeads = null
              return
            }
          }
        }
        else {
          this.notifyService.showError("Please set/confugre the lead source first","Error")
          return
        }
      });
      //Medium Check
      this.leadMedium.getAllLeadMedium().subscribe(res => {
        if (res.length > 0) {
          console.log(JSON.stringify(res))
          for (i = 0; i < this.tblLeads.length; i++) {
            //Check Lead Campaign Exist Or Not
            alert(this.tblLeads[i].leadMedium)
            if (res.filter(data => data.leadMediumName === this.tblLeads[i].leadMedium).length == 0) {
              this.notifyService.showError("Lead Medium Not Matching : " + this.tblLeads[i].leadMedium,"Error")
              this.tblLeads = null
              return
            }
          }
        }
        else {
          this.notifyService.showError("Please set/confugre the lead source first","Error")
          return
        }
      });

      //Channel Check
      this.leadChannel.getAllLeadChannel().subscribe(res => {
        if (res.length > 0) {
          console.log(JSON.stringify(res))
          for (i = 0; i < this.tblLeads.length; i++) {
            //Check Lead Campaign Exist Or Not
            // if (res.filter(data => data.channelName === this.tblLeads[i].channelName).length == 0) {
            //   this.notifyService.showError("Lead Channel Name Not Matching : " + this.tblLeads[i].channelName,"Error")
            //   this.tblLeads = null
            //   return
            // }
          }
        }
        else {
          this.notifyService.showError("Please set/confugre the lead channel first","Error")
          return
        }
      });
     

    }
    fileReader.readAsArrayBuffer(this.file);
  }
  exportLeadFormat() {
    this.excelService.exportAsExcelFile(this.data, 'leads');
  }


}
