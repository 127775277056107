<div class="container-fluid" id="container-wrapper" style="background-color: white;" *ngIf="userType=='Admin'">

    <table style=" width: 100%;border: 0;right: 0px;border-bottom: 2px solid grey; ">
        <tr>
            <td style="width: calc(100%-50px) ">
                <h1 class="h3 mb-0 text-gray-800 ">Restore Database</h1>
            </td>

        </tr>
    </table>
    <div style="margin: auto; width: 610px; top: 140px; border-radius: 0px; border: 1px solid rgb(31, 32, 31); padding: 20px; text-align: left; margin-top: 10px;margin-bottom: 10px; " class="row ">
        <form [formGroup]="restoreForm" style="width: 100%;">
            <h4 style="text-align: center;">Enter Password & Choose the Latest Backup</h4>
            <input class="inputs" type="password" name="pswd" placeholder="Enter Restore Password" id="passWord" formControlName="passWord">

            <input type="file" class="inputs" (change)="onFileSelected($event) " style="padding-top: 5px;" />
            <textarea id="resp" cols="105" rows="12" readonly style="margin-top: 10px;"></textarea>

        </form>
    </div>
</div>