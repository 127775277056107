import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLeadCampaign } from "../models/leadcampaign";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";

@Injectable({
  providedIn: "root",
})
export class LeadCampaignService {
  deleteLeadCampaignById(refNo: string) : Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Campaign")
    const endPoint = `${this.serviceRoot}/deleteLeadCampaignById/${refNo}`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { }

  getAllLeadCampaign(): Observable<any[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Lead Campaign")
    const endPoint = `${this.serviceRoot}/getAllLeadCampaign`;
    return this.http.get<any[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }

   getLeadCampaignById(id:number): Observable<TblLeadCampaign> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Campaign By Id")
    const endPoint = `${this.serviceRoot}/getAllLeadCampaignById/${id}`;
    return this.http.get<TblLeadCampaign>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateLeadCampaign(data:TblLeadCampaign):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Campaign")
    const endPoint = `${this.serviceRoot}/saveLeadCampaign`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

}
