<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">My Settings</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <div class="form-group row" style="display: none;">
            <mat-form-field>
                <input matInput type="text" name="userName" [(ngModel)]="user.userName" placeholder="Name">
            </mat-form-field>
        </div>
        <div class="form-group row" style="display: none;">
            <mat-form-field>
                <input matInput type="text" name="emailId" [(ngModel)]="user.emailId" placeholder="E-Mail">
            </mat-form-field>
        </div>
        <div class="form-group row" style="display: none;">
            <mat-form-field>
                <input matInput type="text" name="mobileNo" [(ngModel)]="user.mobileNo" placeholder="Mobile No">
            </mat-form-field>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <input matInput type="text" name="showMeUpcomingEventsOf" [(ngModel)]="user.showMeUpcomingEventsOf" placeholder="Upcoming Events For Days">
                </mat-form-field>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <input matInput type="date" name="showMeLeadsFrom" [(ngModel)]="user.showMeLeadsFromDate" placeholder="Leads From Date">
                </mat-form-field>
            </div>
        </div>


        <div class="form-group row" *ngIf="showPassword">

            <div class="col-sm-12">
                <mat-form-field>
                    <input matInput type="password" name="emailPassword" [(ngModel)]="user.emailPassword" placeholder="Password">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="updateSettings()">Update My Setting</button>
    </div>
</div>