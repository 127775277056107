import { Component, OnInit } from '@angular/core';
import { FileSystemService } from './FileSystemService';
import { LocalStorageService } from '../utility/localStorageService';
import { CommonService } from '../shared/services/common.services';
import { WebSocketService } from '../WebSocketService';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.css']
})
export class BackupComponent implements OnInit {

  response=""
  userType:string
  ngOnInit(): void {
    this.userType=this.localStorage.get("userType")
    this.loadNotifyService()
  }
  constructor(
    private fileHandlerService: FileSystemService,
    private localStorage:LocalStorageService,
    private commonService:CommonService,
    private webSocketService:WebSocketService,
    private datePipe: DatePipe
  ) { }

  startBackup() {
    this.response="";
    this.commonService.startBackup().subscribe(resp => {
      console.log(resp)
      let currentDateTime =this.datePipe.transform((new Date), 'MMddyyyyhmmss');
      const url = window.URL.createObjectURL(resp);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'backup'+currentDateTime+".sql";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      this.response=this.response+  this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm:ss') +"  Download completed";
      document.getElementById('resp').textContent = this.response;
    });
   
  }

  loadNotifyService()
    {
      let stompClient = this.webSocketService.connect(); 
      let id="b9876598765"
      stompClient.onConnect = (frame) => {
        console.log("Connected: " + frame);
        stompClient.subscribe(`/topic/${id}/notification`, (message) => {
            console.log(message.body)
            this.response=this.response+  this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm:ss') +"  " +message.body+"\n";
            document.getElementById('resp').textContent = this.response;
        });
      };
  
      stompClient.onStompError = (frame) => {
        console.error("STOMP Error:", frame);
      };
  
      stompClient.activate(); 
    }
}
