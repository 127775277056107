<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Change Lead : {{studentName}}</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <div class="form-group row">
            <label class="col-sm-12" for="inputEmail3">Assign Lead To</label>
            <div class="col-sm-12">
                <select [(ngModel)]="assignLeadTo.toCounsellorId" name="toCounsellorId" class="form-control" (change)="onCounsellorChange($event)">
                    <option *ngFor="let stat of userIdAndName" [ngValue]="stat.refNo" selected="selected">{{stat.userName}}</option>
                  </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-12" for="inputEmail3">Reason Of Change</label>
            <div class="col-sm-12">
                <input type="text" class="form-control" name="reasonOfChange" [(ngModel)]="assignLeadTo.reasonOfChange">
            </div>
        </div>

    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="updateLead();modalRef.hide()">Update Lead</button>
    </div>
</div>