import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { DialogData } from '../../models/dialogData';
import { UserServices } from '../../services/userservices';
import { NotificationService } from 'src/app/notification.service';
import { LeadSourceService } from '../../services/leadsource.services';
import { LeadStatusService } from '../../services/leadStatus.services';
import { LeadSubStatusService } from '../../services/leadSubStatusService';
import { LeadCampaignService } from '../../services/leadcampaign.services';
import { LeadMediumService } from '../../services/leadMedium.service';
import { CoursesService } from '../../services/courses.service';
import { LeadChannelService } from '../../services/LeadChannelService';
import { LeadConditionsService } from '../../services/leadconditionservice';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  dialogData: DialogData
  module: string
  refNo: string
  mode: string
  constructor(public modalRef: BsModalRef,
    private localStorage: LocalStorageService,
    private router: Router
    , private _userservices: UserServices,
    private notifyService: NotificationService,
    private _leadSourceService: LeadSourceService,
    private _leadStatusService: LeadStatusService,
    private _leadSubStatusService: LeadSubStatusService,
    private _leadCampaignervice: LeadCampaignService,
    private _leadMediumervice: LeadMediumService,
    private _Courseservice: CoursesService,
    private _leadChannelService: LeadChannelService,
    private leadConditionService:LeadConditionsService
  ) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogData = this.modalRef.content;
      this.mode = this.modalRef.content.mode
      this.refNo = this.modalRef.content.refNo
      this.module = this.modalRef.content.module
    }, 500);
  }
  confirm() {

    if (this.module == "Logout") {
      this.localStorage.set("userName", null)
      this.localStorage.set("refNo", null)
      this.localStorage.set("logout", "true")
      window.location.href=window.location.origin+"/login"
      //this.router.navigateByUrl("login")
    }
    if(this.module=="Lead Condition")
    {
      this.leadConditionService.deleteLeadCondition(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }
    if (this.module == "Lead Source") {
      this._leadSourceService.deleteLeadSource(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }
    if (this.module == "User") {
      this._userservices.deleteUser(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }
    if (this.module == "Lead Status") {

      this._leadStatusService.deleteLeadStatus(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }
    if (this.module == "Lead Sub-Status") {

      this._leadSubStatusService.deleteLeadSubStatusById(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }

    if (this.module == "Lead Campaign") {

      this._leadCampaignervice.deleteLeadCampaignById(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }
    if (this.module == "Lead Medium") {

      this._leadMediumervice.deleteLeadMediumById(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }
    if (this.module == "Course") {

      this._Courseservice.deleteCourseById(this.refNo).subscribe(resp => {
        if (resp != null) {
          this.notifyService.showSuccess("Deleted Successfully!", "Success");
          window.location.reload()
        }
        else {
          this.notifyService.showError(resp, "Error")
        }
      });
    }
    if(this.module=="Lead Channel")
      {
        
        this._leadChannelService.deleteLeadChannelById(this.refNo).subscribe(resp=>{
          if(resp!=null){
            this.notifyService.showSuccess("Deleted Successfully!", "Success");
            window.location.reload()
          }
          else{
            this.notifyService.showError(resp, "Error")
          }
        });
      }
  }

}
