export class TblAssignLeadTo
{
    refNo:number
    onDate:string
    studentId:number
    fromCounsellorId:number
    toCounsellorId:number
    byUserId:number
    reasonOfChange:string
    counsellorName:string
}